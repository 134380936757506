import { useCallback, useEffect, useState } from "react";
import useCanvas from "./useCanvas";
import { transformRobot2Pixels } from '../Utils';
import { drawRectangleOnCanvas, canvasDrawOnLine, writeAisleOnCanvas, clearCanvas, drawRobotPose } from '../utils/canvasFunctions';

export default function useLayout(bucketMapLayout, robotLayout) {
    const [storeMapCanvasRef, storeMapDraw] = useCanvas();
    const [layers, setLayers] = useState({});
    const [, setOriginalLayout] = useState(null);
    const [useClientAisles,] = useState(false);

    useEffect(() => {
        if (storeMapCanvasRef?.current && bucketMapLayout?.map_img) {
            storeMapCanvasRef.current.width = bucketMapLayout.width;
            storeMapCanvasRef.current.height = bucketMapLayout.height;
        }
    }, [bucketMapLayout, storeMapCanvasRef]);

    const parseCoord = useCallback((coords) => {
        const origin = robotLayout?.metadata?.origin;
        const resolution = robotLayout?.metadata?.resolution;
        const originalHeight = bucketMapLayout?.height
        const upperLeft = transformRobot2Pixels(
            coords[0].x,
            coords[0].y,
            0,
            origin,
            resolution,
            originalHeight
        );
        const lowerRight = transformRobot2Pixels(
            coords[1].x,
            coords[1].y,
            0,
            origin,
            resolution,
            originalHeight
        );
        const px = upperLeft[0];
        const py = upperLeft[1];
        const width = (lowerRight[0] - px);
        const height = (lowerRight[1] - py);
        return [px, py, width, height];
    }, [robotLayout, bucketMapLayout]);

    useEffect(() => {
        if (!robotLayout) return;
        setOriginalLayout(robotLayout);
        const layers = Object.keys(robotLayout?.layout || {}).reduce((acc, layer) => {
            acc[layer] = {
                'name': layer,
                'visible': true,
                'lock': false,
                'layers': Object.keys(robotLayout?.layout[layer] || {}).reduce((acc, childLayer) => {
                    acc[childLayer] = {
                        'name': childLayer,
                        'visible': true
                    };
                    return acc;
                }, {})
            };
            return acc;
        }, {});
        setLayers(layers);
    }, [robotLayout]);

    const parseDataToDraw = useCallback((data) => {
        const [px, py, width, height] = parseCoord(data.bbox);
        const colorFill = data.color ? `${data.color}55` : data.excluded ? 'rgba(170,170,170,0.23)' : 'rgba(255, 164, 0, 0.15)'
        const strokeStyle = data.color ? data.color : "#212529EE"
        return { px, py, width, height, colorFill, strokeStyle }
    }, [parseCoord]);

    const handleDrawLayers = useCallback(() => {
        if (!layers || !robotLayout) return;
        const activeLayers = Object.keys(layers)?.filter(layer => layers[layer]?.visible);
        activeLayers?.forEach(layer => {
            const layerData = robotLayout?.layout[layer];
            const layerItems = Object.keys(layerData)?.filter(item => layers[layer]?.layers[item]?.visible);
            layerItems?.forEach(item => {
                const itemData = layerData?.[item];
                if (itemData?.bbox) {
                    const { type } = itemData
                    let drawerFunction = null;
                    let args = null;
                    const { px, py, width, height, colorFill, strokeStyle } = parseDataToDraw(itemData)
                    switch (type) {
                        case 'rectangle':
                            drawerFunction = drawRectangleOnCanvas;
                            args = [px, py, width, height, colorFill, strokeStyle];
                            break;
                        default:
                            return;
                    }
                    if (!drawerFunction) return;
                    storeMapDraw(drawerFunction, args);
                    const aisleName = useClientAisles ? itemData?.client_name || item : item;
                    storeMapDraw(writeAisleOnCanvas, [aisleName, [px, py], [px + width, py + height], true]);
                }
            });
        });
    }, [layers, robotLayout, parseDataToDraw, storeMapDraw, useClientAisles]);

    const handleDrawLines = useCallback(() => {
        if (!layers || !robotLayout) return;
        const activeLayers = Object.keys(layers)?.filter(layer => layers[layer]?.visible);
        activeLayers?.forEach(layer => {
            const layerData = robotLayout?.layout[layer];
            const layerItems = Object.keys(layerData)?.filter(item => layers[layer]?.layers[item]?.visible);
            layerItems?.forEach(item => {
                const itemData = layerData?.[item];
                const linesItems = Object.keys(itemData)?.filter(key => itemData[key]?.type === 'line')
                linesItems?.forEach(lineItem => {
                    const lineData = itemData[lineItem];
                    const { map_points } = lineData
                    const [px, py, width, height] = parseCoord(map_points)
                    storeMapDraw(canvasDrawOnLine, [
                        {
                            pxInit: {
                                x: px,
                                y: py
                            }, pxEnd: {
                                x: px + width,
                                y: py + height
                            }
                        },
                        '#00887c',
                        4
                    ])
                })
            });
        });
    }, [robotLayout, layers, parseCoord, storeMapDraw]);

    const handleDrawPoses = useCallback(() => {
        if (!layers?.poses) return;
        if (!layers?.poses?.visible) return;
        const poses = layers?.poses;
        const info = {
            origin: robotLayout?.metadata?.origin,
            resolution: robotLayout?.metadata?.resolution,
            height: bucketMapLayout?.height,
        }
        Object.keys(poses?.layers)?.filter(pose => poses?.layers[pose]?.visible).forEach(pose => {
            const { x: xRobot, y: yRobot, ang: angRobot } = robotLayout?.layout?.poses[pose];
            storeMapDraw(drawRobotPose, [[xRobot, yRobot, angRobot], info, false, '', 'pin', false]);

        });
    }, [robotLayout, bucketMapLayout, storeMapDraw, layers]);

    const refreshLayout = useCallback(() => {
        if (!storeMapCanvasRef?.current || !robotLayout || !bucketMapLayout) return;
        const { width, height } = bucketMapLayout;
        storeMapDraw(clearCanvas, [width, height]);
        handleDrawLines();
        handleDrawLayers();
        handleDrawPoses();
    }, [storeMapCanvasRef, robotLayout, bucketMapLayout, storeMapDraw, handleDrawLines, handleDrawLayers, handleDrawPoses]);


    useEffect(() => {
        if (!robotLayout || !bucketMapLayout || !layers) return;
        refreshLayout();
    }, [robotLayout, bucketMapLayout, storeMapCanvasRef, refreshLayout, layers, useClientAisles]);

    const toggleParentLayer = useCallback((layer) => {
        setLayers(prev => {
            const newLayers = { ...prev };
            Object.keys(newLayers[layer].layers).forEach(childLayer => {
                newLayers[layer].layers[childLayer].visible = !newLayers[layer].visible;
            });
            newLayers[layer].visible = !newLayers[layer].visible;
            return newLayers;
        });
    }, []);

    const toggleChildLayer = useCallback((layer, childLayer) => {
        setLayers(prev => {
            const newLayers = { ...prev };
            if (!newLayers[layer].visible && !newLayers[layer].layers[childLayer].visible) {
                newLayers[layer].visible = true;
            }
            newLayers[layer].layers[childLayer].visible = !newLayers[layer].layers[childLayer].visible;
            if (Object.keys(newLayers[layer].layers).every(childLayer => !newLayers[layer].layers[childLayer].visible)) {
                newLayers[layer].visible = false;
            }
            return newLayers;
        });
    }, []);


    return {
        storeMapCanvasRef,
        layers,
        toggleParentLayer,
        toggleChildLayer
    }
}