import { useState } from 'react';
import { ListItemButton, ListItemText, Box, IconButton, List, Collapse } from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

function LayerChildItem({ layer, item, toggleChildLayer }) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <ListItemButton sx={{ pl: 8 }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <ListItemText primary={item} />
            <Box sx={{ display: 'flex', gap: 1, visibility: isHovered || !layer?.layers[item]?.visible ? 'visible' : 'hidden' }}>
                <IconButton size='small' onClick={() => toggleChildLayer(layer?.name, item)}>
                    <IconComponent iconName={layer?.layers[item]?.visible ? 'eye-outline' : 'eye-off-outline'} />
                </IconButton>
            </Box>
        </ListItemButton>
    );
}

function LayerItem({ layer, toggles }) {
    const [open, setOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    return (
        <>
            <ListItemButton key={layer?.name} dense onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <IconButton size='small' onClick={(e) => {
                    e.stopPropagation();
                    setOpen(prev => !prev);
                }} sx={{ visibility: Object.keys(layer?.layers || {}).length > 0 ? 'visible' : 'hidden', mr: 1 }}>
                    <IconComponent iconName={open ? 'chevron-up-outline' : 'chevron-down-outline'} />
                </IconButton>
                <ListItemText primary={layer?.name} />
                <Box sx={{ display: 'flex', gap: 1, visibility: isHovered || !layer?.visible ? 'visible' : 'hidden' }}>
                    <IconButton size='small' onClick={(e) => {
                        e.stopPropagation();
                        toggles?.parent(layer?.name);
                    }}>
                        <IconComponent iconName={layer?.visible ? 'eye-outline' : 'eye-off-outline'} />
                    </IconButton>
                </Box>
            </ListItemButton>
            {layer?.layers && <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {Object.keys(layer?.layers).map(item => (
                        <LayerChildItem key={item} layer={layer} item={item} toggleChildLayer={toggles?.child} />
                    ))}
                </List>
            </Collapse>}
        </>
    );
}


export default function LayersSidebar({ layers, toggles }) {
    return (
        <List>
            {Object.keys(layers).map(layer => (
                <LayerItem key={layer} layer={layers[layer]} toggles={toggles} />
            ))}
        </List>
    )
}