import { FormatToSentenceCase } from 'core/utils/parsers';
import React, { useEffect, useState } from 'react'

import { Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import { MultiSelector, isEmptyOrUndefined } from '@zippeditoolsjs/blocks';
import { DataGrid } from '@zippeditoolsjs/table';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import { changesColor } from '../Utils';
import NotFound from '../notFound/NotFound';
import AddDialog from './AddDialog';
import ConfirmDialog from './ConfirmDialog';
import EditDialog from './EditDialog';
import Versions from './Versions';

const columns = (props) => {
  const {
    t,
    isSingleRobotSelected,
    setOpenInfoDialog,
    setSelectedParameter,
    robotByUUID,
    setOpenEditDialog,
    changes,
    isLatestVersionSelected,
    selectedRobots,
    parameters,
    handleDeleteChanges,
    deletedParameters,
    canUpdate,
  } = props;

  // * Parameter column
  let columns = [
    {
      field: 'code',
      headerName: t('overseer_app.parameters.Parameter', 'Parameter'),
      flex: 1,
      renderCell: (params) => {
        const hasEdits = Object.values(changes.edit).some(robotChanges => {
          return Object.keys(robotChanges).some(paramName => paramName === params.value);
        });
        const hasAdds = Object.values(changes.add).some(robotChanges => {
          return Object.keys(robotChanges).some(paramName => paramName === params.value);
        });
        const hasDeletes = Object.values(changes.delete).some(robotChanges => {
          return Object.keys(robotChanges).some(paramName => paramName === params.value);
        });
        return (
          <>
            {hasAdds ?
              <Typography variant='body2' sx={{ color: changesColor('add') }}>{params.value}</Typography>
              :
              hasEdits ?
                <Typography variant='body2' sx={{ color: changesColor('edit') }}>{params.value}</Typography>
                :
                hasDeletes ?
                  <Typography variant='body2' sx={{ color: changesColor('delete') }}>{params.value}</Typography>
                  :
                  params.value
            }
            <IconButton onClick={() => { setOpenInfoDialog(true); setSelectedParameter(params.row) }}>
              <IconComponent
                iconName={'information-circle-outline'}
                style={{ fontSize: "20px" }}
              />
            </IconButton>
          </>
        )
      }
    }
  ]

  // * Value column
  if (isSingleRobotSelected) {
    // "Value" column for a single robot
    columns.push({
      field: 'value',
      headerName: t('overseer_app.parameters.Value', 'Value'),
      flex: 1,
      renderCell: (params) => {
        let currentValue = params.value
        let change = null;

        if (!isEmptyOrUndefined(changes.edit[selectedRobots[0]?.robot_uuid], 'object') && params.row.code in changes.edit[selectedRobots[0]?.robot_uuid]) {
          change = changes.edit[selectedRobots[0]?.robot_uuid]?.[params.row.code]
        } else if (!isEmptyOrUndefined(changes.add[selectedRobots[0]?.robot_uuid], 'object') && params.row.code in changes.add[selectedRobots[0]?.robot_uuid]) {
          change = changes.add[selectedRobots[0]?.robot_uuid]?.[params.row.code]
        }

        // Replace the table value if there are changes
        if (!isEmptyOrUndefined(change, 'id') || !isEmptyOrUndefined(change, 'array') || !isEmptyOrUndefined(change, 'object')) {
          currentValue = change
        }

        // Returning when data_type = secret
        if (params.row.data_type === 'secret') return '**********'

        // Returning when data_type = list
        if (params.row.data_type === 'list') {
          const joinedValues = currentValue.join(', ')
          const stackedValues = (
            <div style={{ whiteSpace: 'pre-line' }}>{currentValue.join('\n')}</div>
          )
          return (
            <Tooltip title={stackedValues}>
              <Typography variant='body2'>{joinedValues}</Typography>
            </Tooltip>
          )
        }

        return String(currentValue)
      }
    })
  } else {
    // "Value" column for multiple robots
    columns.push({
      field: 'values',
      headerName: t('overseer_app.parameters.Value', 'Value'),
      flex: 1,
      renderCell: (params) => {
        let currentValue = params.value
        let change = []

        // If the current param has any changes, add it to the "change" list
        params.value.forEach(robot => {
          if (!isEmptyOrUndefined(changes.edit[robot?.robot_uuid], 'object') && params.row.code in changes.edit[robot?.robot_uuid]) {
            change.push({
              ...robot,
              "value": changes.edit[robot?.robot_uuid][params.row.code]
            })
          } else if (!isEmptyOrUndefined(changes.add[robot?.robot_uuid], 'object') && params.row.code in changes.add[robot?.robot_uuid]) {
            change.push({
              ...robot,
              "value": changes.add[robot?.robot_uuid][params.row.code]
            })
          } else {
            change.push(robot)
          }
        })

        // Replace the table value if there are changes
        if (!isEmptyOrUndefined(change, 'array')) {
          currentValue = change
        }

        // Returning when data_type = secret
        if (currentValue[0].data_type === 'secret') return '**********'

        // Check if the values are unique or are all the same
        const uniqueValues = new Set(currentValue.map(item => item.value));
        const hasMultipleValues = uniqueValues.size > 1;

        // If it has different values, show them in a tooltip, otherwise return as normal
        if (hasMultipleValues) {
          const paramsList = currentValue.map(dict => (
            <Stack key={dict.robot_uuid} direction='row'>
              <Typography variant='body2' sx={{ fontWeight: 'bold' }}>{robotByUUID[dict.robot_uuid].robot_code}:&nbsp;</Typography>
              {dict.data_type === 'list' ?
                // Returning when data_type = list
                <Stack>
                  {dict.value.map(item => (
                    <Typography key={item} variant='body2'>{item}</Typography>
                  ))}
                </Stack>
                :
                <Typography variant='body2'>{String(dict.value)}</Typography>
              }
            </Stack>
          ))
          return (
            <Tooltip title={paramsList}>
              <Typography variant='body2' sx={{ textDecoration: 'underline dotted' }}>{t('overseer_app.parameters.Multiple_values', 'Multiple values')}</Typography>
            </Tooltip>
          )
        }

        // Returning when data_type = list
        if (currentValue[0].data_type === 'list') {
          return currentValue[0].value.join(', ')
        }

        return String(currentValue[0].value)
      }
    })
  }

  // * Categories column
  columns.push({
    field: 'category',
    headerName: t('overseer_app.parameters.Category', 'Category'),
    flex: 0.5,
  })

  // * Actions column
  // If the latest version is NOT selected, don't add the action buttons
  if (isSingleRobotSelected && !isLatestVersionSelected) return columns;

  columns.push({
    field: 'actions',
    headerName: t('overseer_app.parameters.Actions', 'Actions'),
    flex: 0.5,
    renderCell: (params) => {
      const currentParameter = parameters.filter(parameter => parameter.code === params.row.code)?.[0]
      const isEditable = currentParameter?.editable;

      let selectedParam = {}

      if (isSingleRobotSelected) {
        selectedParam = {
          ...params.row,
          // Format in the same way as groupedRobotParameters
          values: [{
            "data_type": params.row.data_type,
            "robot_uuid": selectedRobots[0]?.robot_uuid,
            "value": params.row.value
          }]
        }
      } else {
        selectedParam = {
          ...params.row,
          values: [...params.row.values]
        }
      }
      return (
        <>
          <Tooltip title={isEditable && !deletedParameters.includes(params.row.code) ? t('overseer_app.parameters.Edit', 'Edit') : t('overseer_app.parameters.Not_editable', 'Not editable')}>
            <span>
              <IconButton disabled={!isEditable || deletedParameters.includes(params.row.code) || !canUpdate} onClick={() => { setOpenEditDialog(true); setSelectedParameter(selectedParam) }}>
                <IconComponent
                  iconName={'pencil'}
                  style={{ fontSize: "20px" }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={isEditable && !deletedParameters.includes(params.row.code) ? t('overseer_app.parameters.Delete', 'Delete') : t('overseer_app.parameters.Not_removable', 'Not removable')}>
            <span>
              <IconButton disabled={!isEditable || deletedParameters.includes(params.row.code) || !canUpdate} onClick={() => { handleDeleteChanges(selectedParam) }}>
                <IconComponent
                  iconName={'trash'}
                  style={{ fontSize: "20px", color: isEditable && !deletedParameters.includes(params.row.code) ? 'red' : 'lightCoral' }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )
    }
  })

  return columns;
}

const InformationDialog = (props) => {
  const { t, openInfoDialog, setOpenInfoDialog, parameter } = props;
  const { code, parameter_id, ...restParameterInfo } = parameter

  return (
    <Dialog fullWidth maxWidth='xs' onClose={() => setOpenInfoDialog(false)} open={openInfoDialog}>
      <DialogTitle>{t('overseer_app.parameters.Parameter', 'Parameter')}: {code}</DialogTitle>
      <DialogContent dividers>
        {Object.keys(restParameterInfo).map(key => {
          if (key === 'category_id') return null; // Don't display the category_id

          // TODO: delete when the list data_type doesn't exist anymore
          // Parse the default value if it's a list
          let returnValue = String(restParameterInfo[key])
          if (restParameterInfo['data_type'] === 'list' && key === 'default_value' && !isEmptyOrUndefined(restParameterInfo?.default_value, 'id')) {
            // Parse the string list of values 
            const parseList = JSON.parse(restParameterInfo[key])

            // Join the parsed list elements with commas
            returnValue = parseList.join(', ')
          }
          return (
            <Typography key={key}><strong>{FormatToSentenceCase(key)}: </strong>
              {isEmptyOrUndefined(restParameterInfo[key], 'id') ?
                <>{t('overseer_app.parameters.No_value_assigned', 'No value assigned.')}</>
                :
                returnValue
              }
            </Typography>
          )
        })
        }
      </DialogContent>
    </Dialog>
  )
}

export default function RobotParameters(props) {
  const {
    t,
    debouncedSelectedRobots,
    getChains,
    chains,
    isLoadingChains,
    getRobotsBaldur,
    robots,
    isLoadingRobots,
    getRobotParameters,
    robotParameters,
    isLoadingRobotParameters,
    getGroupedRobotParameters,
    groupedRobotParameters,
    isLoadingGroupedRobotParameters,
    getParameters,
    parameters,
    selectedRobots,
    setSelectedRobots,
    postRobotParameters,
    isLoadingRobotParamsPost,
    robotParamsPostResponse,
    setSnackAlert,
    getRobotStatuses,
    robotStatuses,
    isLoadingRobotStatuses,
    canUpdate,
  } = props;
  // States
  const [selectedChains, setSelectedChains] = useState([]); // For the chains selector
  const [selectedRobotStatus, setSelectedRobotStatus] = useState([]); // For the robot status selector
  const [isSingleRobotSelected, setIsSingleRobotSelected] = useState(false); // Bool: true if only 1 robot is selected in the robot selector
  const [isLatestVersionSelected, setIsLatestVersionSelected] = useState(true); // * This assumes robotParameters is ordered by version DESC
  const [filteredRobotParams, setFilteredRobotParams] = useState(robotParameters); // Used when filtering with the version selector
  const [selectedVersion, setSelectedVersion] = useState({}) // Current version selected in the list of versions
  const [tableRobotParameters, setTableRobotParameters] = useState([]) // Used for the DataGrid rows
  const [robotByUUID, setRobotByUUID] = useState({}); // Object with the robot_uuid as key and the robot info as value, like the robot_code, store, etc.
  const [changes, setChanges] = useState({ add: {}, edit: {}, delete: {} }) // Object with all the additions, edits and deletions to the params
  const [selectedParameter, setSelectedParameter] = useState({}); // Selected parameter (row of the DataGrid) when editing or deleting
  const [parameter, setParameter] = useState({}); // "parameters" is filtered with selectedParameter to get the current parameter info
  const [oldParameters, setOldParameters] = useState({}); // Keeps track of the parameters not updated. It's an object with robot_uuid as key
  // Dialog states
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  // Array with the deleted parameters codes
  const deletedParameters = Object.keys(Object.values(changes.delete)[0] ? Object.values(changes.delete)[0] : {})

  // Lifecycle methods

  // Get the chains and parameters
  useEffect(() => {
    getChains();
    getParameters();
    getRobotStatuses();
  }, [getChains, getParameters, getRobotStatuses])

  // Default robot status: is_deployed = True
  useEffect(() => {
    if (!isEmptyOrUndefined(robotStatuses, 'array')) {
      const filterStatus = robotStatuses.filter(status => status.is_deployed)
      setSelectedRobotStatus(filterStatus)
    }
  }, [robotStatuses])

  // Get the robots
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedChains, 'array')) {
      const chains = selectedChains.map(chain => chain.name);
      const robotStatus = selectedRobotStatus.map(status => status.status);
      getRobotsBaldur({ chains, robot_status: robotStatus });
    }

    setSelectedRobots([]);
  }, [selectedChains, getRobotsBaldur, setSelectedRobots, selectedRobotStatus])

  // Get the robot parameters or grouped robot parameters by parameter, set isSingleRobotSelected and reset "changes"
  useEffect(() => {
    getRobotParams();
  }, [debouncedSelectedRobots])

  // Construct a lookup object with the robot UUID as key and an object with the robot's info as value
  useEffect(() => {
    if (!isEmptyOrUndefined(robots, 'array')) {
      let robotObj = {}
      robots.forEach(robot => {
        const { robot_uuid, ...rest } = robot
        robotObj[robot_uuid] = { ...rest }
      })
      setRobotByUUID(robotObj)
    }
  }, [robots])


  // Set latest version, tableRobotParameters and OldParameters
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedRobots, 'array')) {
      if (!isEmptyOrUndefined(filteredRobotParams, 'array') && isSingleRobotSelected) {
        // 1 robot: set the latest version as selected when it first loads
        setSelectedVersion(filteredRobotParams[0])
        setTableRobotParameters(filteredRobotParams[0].parameters)

        let oldParams = {};
        filteredRobotParams[0].parameters.forEach(robot => {
          oldParams = {
            ...oldParams,
            [robot.code]: robot.value
          }
        })
        setOldParameters({ [selectedRobots[0].robot_uuid]: oldParams })
      } else if (!isEmptyOrUndefined(groupedRobotParameters, 'array')) {
        // Multiple robots
        setTableRobotParameters(groupedRobotParameters)

        let oldParams = {};
        groupedRobotParameters.forEach(param => {
          param.values.forEach(robot => {
            oldParams[robot.robot_uuid] = {
              ...oldParams[robot.robot_uuid],
              [param.code]: robot.value
            }
          })
        })
        setOldParameters(oldParams)
      }
    }
  }, [filteredRobotParams, debouncedSelectedRobots, groupedRobotParameters, selectedRobots, isSingleRobotSelected])

  useEffect(() => {
    if (!isEmptyOrUndefined(selectedParameter, 'object')) {
      const filterParam = parameters.filter(parameter => parameter.code === selectedParameter.code)
      setParameter(filterParam[0])
    }
  }, [selectedParameter])

  // POST response
  useEffect(() => {
    if (robotParamsPostResponse?.message === 'Success') {
      getRobotParams();
      setOpenConfirmDialog(false);
      setSnackAlert({
        open: true,
        message: t('overseer_app.parameters.save_successful', 'Parameters saved successfully'),
        severity: 'success',
      });
    }
  }, [t, robotParamsPostResponse, setSnackAlert])

  // Methods

  const getRobotParams = () => {
    if (!isEmptyOrUndefined(debouncedSelectedRobots, 'array')) {
      const robotUUIDs = debouncedSelectedRobots.map(robot => robot.robot_uuid)
      if (robotUUIDs.length === 1) {
        // Used fot the versions list
        getRobotParameters(robotUUIDs, false)
        // Used for the table and the modals
        getGroupedRobotParameters(robotUUIDs)
        setIsSingleRobotSelected(true)
      } else {
        getGroupedRobotParameters(robotUUIDs)
        setIsSingleRobotSelected(false)
      }
    }
    setChanges({ add: {}, edit: {}, delete: {} });
  }

  const ChangesCounts = () => {
    const adds = Object.keys(changes.add).reduce((acc, robot) => acc + Object.keys(changes.add[robot]).length, 0);
    const edits = Object.keys(changes.edit).reduce((acc, robot) => acc + Object.keys(changes.edit[robot]).length, 0);
    const deletes = Object.keys(changes.delete).reduce((acc, robot) => acc + Object.keys(changes.delete[robot]).length, 0);

    return (
      <Stack direction='row' justifyContent='center'>
        <Typography variant='caption' sx={{ color: changesColor('add') }}>{adds} {t('overseer_app.parameters.add', 'add')},&nbsp;</Typography>
        <Typography variant='caption' sx={{ color: changesColor('edit') }}>{edits} {t('overseer_app.parameters.edit', 'edit')},&nbsp;</Typography>
        <Typography variant='caption' sx={{ color: changesColor('delete') }}>{deletes} {t('overseer_app.parameters.delete', 'delete')}</Typography>
      </Stack>
    )
  }

  const getDataType = (dataType) => {
    if (dataType === 'int' || dataType === 'float') return 'number'
    return ''
  }

  const setDataType = (value, dataType) => {
    if (value) {
      if (dataType === 'int') {
        return parseInt(value)
      } else if (dataType === 'float') {
        return parseFloat(value)
      } else if (dataType === 'bool') {
        const castValue = value.toLowerCase()
        // If the boolean string is completed (i.e. the user finished typing), return as boolean type
        // Else return the string in lowercase
        if (castValue === 'true' || castValue === 'false') {
          return castValue === 'true'
        }
        return castValue;
      } else if (dataType === 'list') {
        return value.replace(/\s/g, '').split(',')
      } else {
        return String(value)
      }
    }
    return value
  }

  const handleDeleteChanges = (selectedParam) => {
    let deleteObj = structuredClone(changes.delete);
    let newAdds = structuredClone(changes.add);
    let newEdits = structuredClone(changes.edit);
    let newValues = []
    const addedRobotUuids = []

    // Get the robot_uuids that have the selected parameter added
    Object.entries(changes.add).forEach(([robot_uuid, params]) => {
      const parameters = Object.keys(params)
      if (parameters.includes(selectedParam.code)) {
        addedRobotUuids.push(robot_uuid)
      }
    })

    // If the parameter was added for that robot, remove it from changes.add and don't keep it in the table
    // Else keep it in the table and add it to changes.delete
    selectedParam?.values?.forEach(robot => {
      if (addedRobotUuids.includes(robot.robot_uuid)) {
        delete newAdds[robot.robot_uuid]?.[selectedParam?.code]

        // If the robot_uuid doesn't have any parameters left, delete the object
        if (isEmptyOrUndefined(newAdds[robot.robot_uuid], 'object')) {
          delete newAdds[robot.robot_uuid]
        }
      } else {
        newValues.push(robot)

        // Add it to the delete object
        deleteObj[robot.robot_uuid] = {
          ...changes.delete[robot.robot_uuid],
          [selectedParam.code]: robot.value
        }
      }
    })

    // Remove the parameter from changes.edit
    Object.entries(changes.edit).forEach(([robot_uuid, params]) => {
      const parameters = Object.keys(params)
      if (parameters.includes(selectedParam.code)) {
        delete newEdits[robot_uuid]?.[selectedParam?.code]

        // If the robot_uuid doesn't have any parameters left, delete the object
        if (isEmptyOrUndefined(newEdits[robot_uuid], 'object')) {
          delete newEdits[robot_uuid]
        }
      }
    })

    // If newValues is empty, the parameter is completely new, so filter it from the table
    // Else, change the "values" for the current param with newValues
    if (isEmptyOrUndefined(newValues, 'array')) {
      const newTableParams = tableRobotParameters.filter(param => param.code !== selectedParam.code);
      setTableRobotParameters(newTableParams);
    } else {
      const restTable = [];
      let currentTableParams = {};

      tableRobotParameters.forEach(param => {
        if (param.code === selectedParam.code) {
          currentTableParams = param;
        } else {
          restTable.push(param);
        }
      })

      currentTableParams["values"] = newValues

      setTableRobotParameters([currentTableParams, ...restTable])
    }

    setChanges({
      add: newAdds,
      edit: newEdits,
      delete: deleteObj
    })
  }

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} xs={12} md={10} size={{ xs: 12, md: 10 }}>
          {/* Chains selector */}
          <Grid xs={4} sm={4} md={3} size={{ xs: 4, sm: 4, md: 3 }}>
            <MultiSelector
              options={chains}
              value={selectedChains}
              setValue={setSelectedChains}
              label={t('overseer_app.parameters.Chains', 'Chains')}
              name='name'
              loading={isLoadingChains}
              limitTags={1}
              textFieldVariant={"outlined"}
            />
          </Grid>
          {/* Robots status selector */}
          <Grid xs={4} sm={4} md={3} size={{ xs: 4, sm: 4, md: 3 }}>
            <MultiSelector
              options={robotStatuses}
              value={selectedRobotStatus}
              setValue={setSelectedRobotStatus}
              label={t('overseer_app.parameters.Robot_status', 'Robot status')}
              name='display_name'
              loading={isLoadingRobotStatuses}
              limitTags={1}
              textFieldVariant={"outlined"}
            />
          </Grid>
          {/* Robots selector */}
          <Grid xs={4} sm={4} md={3} size={{ xs: 4, sm: 4, md: 3 }}>
            <MultiSelector
              options={robots}
              value={selectedRobots}
              setValue={setSelectedRobots}
              label={t('overseer_app.parameters.Robots', 'Robots')}
              name='robot_code'
              loading={isLoadingRobots}
              limitTags={1}
              textFieldVariant={"outlined"}
            />
          </Grid>
        </Grid>
        {(!isEmptyOrUndefined(changes.add, 'object') || !isEmptyOrUndefined(changes.edit, 'object') || !isEmptyOrUndefined(changes.delete, 'object')) &&
          <Stack>
            <Button variant='contained' onClick={() => setOpenConfirmDialog(true)}>
              <Typography variant='body'>{t('overseer_app.parameters.Save_changes', 'Save Changes')}</Typography>
            </Button>
            <ChangesCounts />
          </Stack>
        }
      </Grid>
      {isEmptyOrUndefined(debouncedSelectedRobots, 'array') || isLoadingGroupedRobotParameters || isLoadingRobotParameters ?
        <NotFound
          sx={{ pt: 3 }}
          title={t('overseer_app.http_messages.filter_to_view', 'Filter to View Specific Data')}
          content={t('overseer_app.parameters.Select_chain_robot', 'Select a chain and robot to display detailed parameters.')}
        />
        :
        <Grid container spacing={2} mt={1} sx={{ minHeight: 'inherit' }}>
          {isSingleRobotSelected &&
            // Versions
            <Grid xs={12} md={4} size={{ xs: 12, md: 4 }} sx={{ minHeight: 'inherit' }}>
              <Grid container sx={{ minHeight: '100%' }}>
                <Versions
                  t={t}
                  robotParameters={robotParameters}
                  filteredRobotParams={filteredRobotParams}
                  setFilteredRobotParams={setFilteredRobotParams}
                  selectedVersion={selectedVersion}
                  setSelectedVersion={setSelectedVersion}
                  setTableRobotParameters={setTableRobotParameters}
                  setIsLatestVersionSelected={setIsLatestVersionSelected}
                />
              </Grid>
            </Grid>
          }
          {/* Parameters */}
          <Grid xs={12} md={isSingleRobotSelected ? 8 : 12} size={{ xs: 12, md: isSingleRobotSelected ? 8 : 12 }} sx={{ minHeight: 'inherit' }}>
            <Grid container sx={{ minHeight: '100%' }}>
              <Stack component={Paper} sx={{ p: 2, width: '100%' }}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h5'>{t('overseer_app.parameters.Parameters', 'Parameters')}</Typography>
                  {((isSingleRobotSelected && isLatestVersionSelected) || !isSingleRobotSelected) &&
                    <Tooltip title={t('overseer_app.parameters.Add', 'Add')}>
                      <IconButton disabled={!canUpdate} onClick={() => setOpenAddDialog(true)}>
                        <IconComponent
                          iconName={'add'}
                          style={{ fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  }
                </Stack>

                {/* Table */}
                <DataGrid
                  sx={{ border: 'none' }}
                  rows={tableRobotParameters}
                  columns={columns({
                    t,
                    isSingleRobotSelected,
                    setOpenInfoDialog,
                    setSelectedParameter,
                    robotByUUID,
                    setOpenEditDialog,
                    changes,
                    isLatestVersionSelected,
                    selectedRobots,
                    parameters,
                    handleDeleteChanges,
                    deletedParameters,
                    canUpdate,
                  })}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 5, page: 0 },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  getRowId={(row) => row.id}
                />
              </Stack>
            </Grid>
          </Grid>
          {/* Parameter info dialog */}
          <InformationDialog
            t={t}
            openInfoDialog={openInfoDialog}
            setOpenInfoDialog={setOpenInfoDialog}
            parameter={parameter}
          />
          {/* Edit dialog */}
          <EditDialog
            t={t}
            openEditDialog={openEditDialog}
            setOpenEditDialog={setOpenEditDialog}
            parameter={parameter}
            selectedParameter={selectedParameter}
            robotByUUID={robotByUUID}
            isSingleRobotSelected={isSingleRobotSelected}
            changes={changes}
            setChanges={setChanges}
            selectedRobots={selectedRobots}
            oldParameters={oldParameters}
            setOldParameters={setOldParameters}
            getDataType={getDataType}
            setDataType={setDataType}
          />
          {/* Save Changes dialog */}
          <ConfirmDialog
            t={t}
            openConfirmDialog={openConfirmDialog}
            setOpenConfirmDialog={setOpenConfirmDialog}
            changes={changes}
            robotByUUID={robotByUUID}
            parameters={parameters}
            postRobotParameters={postRobotParameters}
            isLoadingRobotParamsPost={isLoadingRobotParamsPost}
            selectedRobots={selectedRobots}
            oldParameters={oldParameters}
            deletedParameters={deletedParameters}
          />
          {/* Add dialog */}
          <AddDialog
            t={t}
            openAddDialog={openAddDialog}
            setOpenAddDialog={setOpenAddDialog}
            parameters={parameters}
            groupedRobotParameters={groupedRobotParameters}
            selectedRobots={selectedRobots}
            changes={changes}
            setChanges={setChanges}
            getDataType={getDataType}
            setDataType={setDataType}
            oldParameters={oldParameters}
            setOldParameters={setOldParameters}
            isSingleRobotSelected={isSingleRobotSelected}
            robotByUUID={robotByUUID}
            deletedParameters={deletedParameters}
            tableRobotParameters={tableRobotParameters}
            setTableRobotParameters={setTableRobotParameters}
          />
        </Grid>
      }
    </>
  )
}