import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SchedulerViewContext } from '../../context/SchedulerView';
import FilterMultiSelector from '../tools/FilterMultiSelector';
import NotAllowedView from '../tools/NotAllowed';
import TableSkeleton from '../tools/TableSkeleton';
import { getWeekOfMonth } from '../tools/utils';
import { checkPermision } from '../Utils';
import { handleGetUserUtcOffset } from '../utils/commons';
import SchedulerTable from './SchedulerTable';
import { getMonthBounds } from './Utils';

const DEBOUNCE_DELAY = 500;
function Scheduler(props) {
  const {
    //List with data
    chainsList,
    robotsListBaldur,
    sessionsList,
    newSessionsResponse,
    navOptions,
    updateSessionResponse,
    deleteSessionsResponse,
    sessionsState,
    //Booleans
    isLoadingChains,
    isLoadingRobotListBaldur,
    isLoadingSessions,
    isLoadingNewSessions,
    isLoadingSessionUpdate,
    isLoadingDeleteSessions,
    isLoadingRobotLayout,
    isLoadingBucketMapLayout,
    //Methods
    getChains,
    getRobotsBaldur,
    getSessionsScheduler,
    postSessionUpdates,
    deleteSessions,
    isLoadingRobotSessions,
    getSessionsState,
    isLoadingSessionHistory,
    sessionHistory,
    getSessionHistory,
  } = props;
  const { t } = useTranslation();

  const viewId = 'Scheduler';
  const [currentDate, setCurrentDate] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(getWeekOfMonth(new Date()) - 1);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);


  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));

  const [selectedRobots, setSelectedRobots] = useState([]);

  const [dataRobots, setDataRobots] = useState([]);
  const [isLocalTime, setIsLocalTime] = useState(false);
  const [canViewCancelledSessions, setCanViewCancelledSessions] = useState(true);

  const debounceTimeout = useRef(null);

  const {
    selectedChain,
    setSelectedChain,
    userHoursDiff,
    setUserHoursDiff,
  } = useContext(SchedulerViewContext);

  useEffect(() => {
    const userLocalTime = handleGetUserUtcOffset();
    setUserHoursDiff(userLocalTime);
  }, []);

  useEffect(() => {
    if (sessionsList) {
      setDataRobots(sessionsList);
    }
  }, [sessionsList]);

  useEffect(() => {
    if (chainsList && chainsList.length === 0) {
      getChains();
    }
    const userLocalTime = handleGetUserUtcOffset();
    setUserHoursDiff(userLocalTime);
  }, [chainsList, getChains]);

  useEffect(() => {
    if (selectedRobots.length > 0) {
      const delayRequest = () => {
        let updatedDate;
        let nextMonthFormattedDate;
        if (selectedDate == null) {
          const {
            startDate: from,
            endDate: to,
          } = getMonthBounds(currentYear, currentMonth)
          updatedDate = from;
          nextMonthFormattedDate = to;
          setSelectedDate(updatedDate);
        } else {
          const {
            startDate: from,
            endDate: to
          } = getMonthBounds(selectedDate.$y, selectedDate.$M);
          updatedDate = from;
          nextMonthFormattedDate = to;
        }
        handleGetSessionTable(updatedDate, nextMonthFormattedDate);
      };

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        delayRequest();
      }, DEBOUNCE_DELAY);
      return () => clearTimeout(debounceTimeout.current);
    }
  }, [selectedRobots]);

  useEffect(() => {
    const {
      startDate: updatedDate,
      endDate: nextMonthFormattedDate
    } = getMonthBounds(selectedDate.$y, selectedDate.$M);
    setFrom(updatedDate);
    setTo(nextMonthFormattedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (selectedChain && selectedChain.length > 0) {
      setSelectedRobots([]);
      getRobotsBaldur({ chains: selectedChain });
    }
  }, [selectedChain, getRobotsBaldur]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  const tick = () => {
    var date = new Date();
    var dateString = date.toLocaleDateString('en-GB');
    const dateParts = dateString.split('/');
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);
    const formattedDate = new Date(year, month - 1, day).toLocaleDateString(
      'en-GB'
    );
    setCurrentDay(day);
    setCurrentMonth(month);
    setCurrentYear(year)
    setCurrentDate(formattedDate);
  };

  const handleChangeUseTime = ({ target: { value } }) => {
    setIsLocalTime(value === 'true');
  };

  const handleGetSessionTable = (from, to) => {
    const robotsData = selectedRobots;
    getSessionsScheduler({ robotsData, from, to, user_utc_offset: userHoursDiff });
    getSessionsState({ robotsData, from, to });
  };

  const handleGetSessionsScheduler = () => {
    handleGetSessionTable(from, to);
  };

  const handleDateChange = (date) => {
    if (date.$y) {
      const year = date.$y ? date.$y : date.$d.getFullYear();
      const month = date.$M || date.$M === 0 ? date.$M : date.$d.getMonth();
      const {
        startDate: from,
        endDate: to
      } = getMonthBounds(year, month)

      setFrom(from);
      setTo(to);
      setSelectedDate(date);
      setCurrentWeek(0);
      handleGetSessionTable(from, to);
    }
  };

  const handlePreviousWeek = () => {
    setCurrentWeek((prevWeek) => prevWeek - 1);
  };

  const handleNextWeek = () => {
    setCurrentWeek((prevWeek) => prevWeek + 1);
  };

  const handleNextMonth = () => {
    const nextMonth = selectedDate.add(1, 'month');
    const {
      startDate: from,
      endDate: to
    } = getMonthBounds(nextMonth.$y, nextMonth.$M);
    setFrom(from);
    setTo(to);
    setSelectedDate(nextMonth);
    setCurrentWeek(0);
    handleGetSessionTable(from, to);
  };

  const handlePreviousMonth = () => {
    const previousMonth = selectedDate.subtract(1, 'month');
    const {
      startDate: from,
      endDate: to
    } = getMonthBounds(previousMonth.$y, previousMonth.$M);
    setFrom(from);
    setTo(to);
    setSelectedDate(previousMonth);
    setCurrentWeek(0);
    handleGetSessionTable(from, to);
  }

  return (
    <Grid container>
      {navOptions.length === 0 ? (
        <LinearProgress
          sx={{ width: '100%', mx: 'auto', top: '-5px' }}
          color="secondary"
        />
      ) : (
        <>
          {checkPermision(navOptions, viewId) ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    {/* Chains */}
                    <Grid item xs={4}>
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        options={
                          chainsList
                            ? chainsList.map((chain) => chain.name)
                            : chainsList
                        }
                        onChange={(e, value) => setSelectedChain(value)}
                        renderInput={(params) => (
                          <TextField {...params} label="Chains" />
                        )}
                      />
                      {isLoadingChains && (
                        <LinearProgress
                          sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                          color="secondary"
                        />
                      )}
                    </Grid>
                    {/* Robots */}
                    <Grid item xs={4}>
                      <FilterMultiSelector
                        options={robotsListBaldur.map(storeRobot => ({
                          ...storeRobot,
                          store_robot_code: t('overseer_app.scheduler.store_floor_code', `${storeRobot.store} Floor ${storeRobot.floor}`, { store: storeRobot.store, floor: storeRobot.floor }),
                        }))}
                        inputSelected={selectedRobots}
                        setInputSelectedOptions={setSelectedRobots}
                        inputLabel={'Store - Floor'}
                        isLoading={isLoadingRobotListBaldur}
                        objectName={'store_robot_code'}
                        objectId={'robot_uuid'}
                      />
                    </Grid>
                    {/* Date */}
                    <Grid item xs={4} sx={{ pt: '0!important' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']} sx={{ pt: 2 }}>
                          <DatePicker
                            minDate={dayjs().subtract(1, 'year')}
                            maxDate={dayjs().add(1, 'year')}
                            label={'"month" and "year"'}
                            views={['month', 'year']}
                            value={selectedDate}
                            onChange={handleDateChange}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
                {/* Local Time */}
                <Grid
                  item
                  xs
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'end'}
                >
                  <Stack direction="row" spacing={1} alignItems="center" sx={{ mr: 3 }}>
                    <FormControl component="fieldset" variant="standard">
                      <FormLabel component="legend">Cancelled Sessions</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch checked={canViewCancelledSessions} onChange={({ target: { checked } }) => setCanViewCancelledSessions(checked)} name="On" />
                        }
                        sx={{ mt: '3px' }}
                        label={
                          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            {canViewCancelledSessions ?
                              <>
                                <IconComponent iconName='eye' style={{ fontSize: "20px", color: "#CCC", marginRight: '7px' }} />
                                <Typography>On</Typography>
                              </>
                              :
                              <>
                                <IconComponent iconName='eye-off' style={{ fontSize: "20px", color: "#CCC", marginRight: '7px' }} />
                                Off
                              </>}
                          </Typography>
                        }
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="row" spacing={1} alignItems="center">
                    <FormControl>
                      <FormLabel id="row-radio-buttons-group-label">Time Format</FormLabel>
                      <RadioGroup row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={isLocalTime}
                        onChange={handleChangeUseTime}
                      >
                        <FormControlLabel value={false} control={<Radio />} label={
                          <Tooltip title={"Display times in store local time for each robot. All schedule change must be done in store local time."}>
                            <Box sx={{ display: 'flex' }}>
                              <IconComponent iconName='storefront' style={{ fontSize: "20px", color: "#CCC" }} />
                              <Typography sx={{ pl: 1 }}>Store</Typography>
                            </Box>
                          </Tooltip>} />
                        <FormControlLabel value={true} control={<Radio />} label={
                          <Tooltip title={"Display times in your browser’s local time. However, this means the schedule will be in view-only mode."}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <IconComponent iconName='eye' style={{ fontSize: "20px", color: "#CCC" }} />
                              <Typography sx={{ pl: 1 }}>Local</Typography>
                            </Box>
                          </Tooltip>} />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider orientation="horizontal" fullWidth />
                </Grid>
                <Grid item xs={12} md={12} sx={{ width: '100' }}>
                  {isLoadingSessions ? (
                    <TableSkeleton />
                  ) : (
                    <SchedulerTable
                      //dates
                      currentDay={currentDay}
                      currentMonth={currentMonth}
                      currentWeek={currentWeek}
                      currentYear={currentYear}
                      selectedDate={selectedDate}
                      //list
                      dataRobots={dataRobots}
                      selectedRobots={selectedRobots}
                      navOptions={navOptions}
                      sessionsState={sessionsState}
                      //methods
                      postSessionUpdates={postSessionUpdates}
                      handleGetSessionsScheduler={handleGetSessionsScheduler}
                      deleteSessions={deleteSessions}
                      handleNextWeek={handleNextWeek}
                      handlePreviousWeek={handlePreviousWeek}
                      handleNextMonth={handleNextMonth}
                      handlePreviousMonth={handlePreviousMonth}
                      //objects responses
                      updateSessionResponse={updateSessionResponse}
                      newSessionsResponse={newSessionsResponse}
                      deleteSessionsResponse={deleteSessionsResponse}
                      //loadings
                      isLoadingSessionsPost={
                        isLoadingNewSessions || isLoadingSessionUpdate
                      }
                      isLoadingDeleteSessions={isLoadingDeleteSessions}
                      //viewId
                      viewId={viewId}
                      //boolean
                      isLocalTime={isLocalTime}
                      isLoadingRobotSessions={isLoadingRobotSessions}
                      isLoadingLayout={isLoadingBucketMapLayout || isLoadingRobotLayout}
                      canViewCancelledSessions={canViewCancelledSessions}
                      // reload
                      reload={handleGetSessionsScheduler}
                      // session history
                      sessionHistory={sessionHistory}
                      getSessionHistory={getSessionHistory}
                      isLoadingSessionHistory={isLoadingSessionHistory}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <NotAllowedView />
          )}
        </>
      )
      }
    </Grid >
  );
}

export default Scheduler;
