import { connect } from "react-redux";
import LayoutsView from "../../../components/layoutsView/LayoutsView";
import { getBucketMapLayout, getAvailableStores, getAvailableFloors, getRobotLayout } from "core/slices/layouts";

import {
    getLocalBucketMapLayout,
    isLoadingBucketMapLayout,
    isLoadingAvailableStores,
    getLocalAvailableStores,
    getLocalAvailableFloors,
    isLoadingAvailableFloors,
    getLocalRobotLayout,
    isLoadingRobotLayout,
} from 'core/selectors';

export default connect((state) => ({
    bucketMapLayout: getLocalBucketMapLayout(state),
    isLoadingBucketMapLayout: isLoadingBucketMapLayout(state),
    availableStores: getLocalAvailableStores(state),
    isLoadingAvailableStores: isLoadingAvailableStores(state),
    availableFloors: getLocalAvailableFloors(state),
    isLoadingAvailableFloors: isLoadingAvailableFloors(state),
    robotLayout: getLocalRobotLayout(state),
    isLoadingRobotLayout: isLoadingRobotLayout(state),
}), {
    getBucketMapLayout,
    getAvailableStores,
    getAvailableFloors,
    getRobotLayout,
})(LayoutsView);