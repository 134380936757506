import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Card, CardContent, Autocomplete, TextField, LinearProgress, Typography, Box, CircularProgress, Tabs, Tab } from '@mui/material';
import useLayout from '../tools/useLayout';
import { a11yProps } from '../Utils';
import LayersSidebar from './LayersSidebar';
import { useTranslation } from 'react-i18next';

function FallbackInformation({ children, showLoading = false }) {
    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
            {showLoading && <CircularProgress size={32} color="secondary" />}
            <Typography variant="caption">{children}</Typography>
        </Box>
    );
}


export default function LayoutsView({
    bucketMapLayout,
    isLoadingBucketMapLayout,
    getBucketMapLayout,
    getAvailableStores,
    availableStores,
    isLoadingAvailableStores,
    getAvailableFloors,
    availableFloors,
    isLoadingAvailableFloors,
    isLoadingRobotLayout,
    getRobotLayout,
    robotLayout,
    ...rest
}) {
    const [selectedStore, setSelectedStore] = useState(null);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);

    const { storeMapCanvasRef, layers, toggleParentLayer, toggleChildLayer } = useLayout(bucketMapLayout, robotLayout);

    const showStoreMap = useMemo(() => selectedStore && selectedFloor, [selectedStore, selectedFloor]);

    const { t } = useTranslation();

    useEffect(() => {
        getAvailableStores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedStore) {
            getAvailableFloors({
                store: selectedStore.value,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore]);

    useEffect(() => {
        if (selectedStore && availableFloors?.length === 1) {
            setSelectedFloor({ label: "" + availableFloors[0], value: "" + availableFloors[0] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableFloors]);

    useEffect(() => {
        if (selectedFloor && selectedStore) {
            getBucketMapLayout({
                store: selectedStore.value,
                floor: selectedFloor.value,
            });
            getRobotLayout({
                store: selectedStore.value,
                floor: selectedFloor.value,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFloor]);

    return (
        <Grid container spacing={2}>
            <Grid container item xs={12} sm={8} spacing={2}>
                <Grid item xs={6} sm={4}>
                    <Autocomplete
                        fullWidth
                        defaultValue={null}
                        value={selectedStore}
                        options={availableStores?.stores.map(store => ({ label: store, value: store })) || []}
                        loading={isLoadingAvailableStores}
                        disabled={isLoadingAvailableStores || isLoadingBucketMapLayout}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(event, newValue) => {
                            setSelectedStore(newValue);
                            setSelectedFloor(null);
                        }}
                        renderInput={(params) => <TextField {...params} label={t('overseer_app.layouts.select_store', 'Select store')} />}
                        noOptionsText={t('overseer_app.layouts.no_stores_found', 'No stores found')}
                    />
                    <LinearProgress
                        sx={{ width: '100%', mx: 'auto', top: '-5px', visibility: isLoadingAvailableStores ? 'visible' : 'hidden' }}
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <Autocomplete
                        fullWidth
                        defaultValue={null}
                        options={availableFloors?.map(floor => ({ label: "" + floor, value: "" + floor })) || []}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        loading={isLoadingAvailableFloors}
                        disabled={isLoadingAvailableFloors || !selectedStore || isLoadingBucketMapLayout}
                        onChange={(event, newValue) => setSelectedFloor(newValue)}
                        value={selectedFloor}
                        renderInput={(params) => <TextField {...params} label={t('overseer_app.layouts.select_floor', 'Select floor')} />}
                        noOptionsText={t('overseer_app.layouts.no_floors_found', 'No floors found')}
                    />
                    <LinearProgress
                        sx={{ width: '100%', mx: 'auto', top: '-5px', visibility: isLoadingAvailableFloors ? 'visible' : 'hidden' }}
                        color="secondary"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                {/* TODO: Add annotations tools */}
            </Grid>
            <Grid item xs={9}>
                <Card sx={{ height: '80vh', width: '100%' }}>
                    <CardContent sx={{ height: '100%', width: '100%', position: 'relative', overflow: 'auto', borderRadius: 'inherit', padding: '0', margin: '0' }}>
                        {isLoadingBucketMapLayout || isLoadingRobotLayout ?
                            <>
                                <LinearProgress sx={{ width: '100%', top: '0', left: '0', position: 'absolute' }} color="secondary" />
                                <FallbackInformation showLoading>{t('overseer_app.layouts.loading_store_map', 'Loading store map...')}</FallbackInformation>
                            </>
                            :
                            showStoreMap ?
                                bucketMapLayout.map_img ?
                                    <>
                                        <img src={'data:image/jpeg;base64,' + bucketMapLayout.map_img} alt="Bucket Map Layout" style={{
                                            position: 'absolute',
                                            top: '0',
                                            zIndex: '1'
                                        }} />
                                        <canvas ref={storeMapCanvasRef} id="map" style={{ zIndex: '2', position: 'absolute', top: '0' }} />
                                    </>
                                    :
                                    <FallbackInformation>{t('overseer_app.layouts.error_getting_store_map', 'Error getting store map')}</FallbackInformation>
                                :
                                <FallbackInformation>{t('overseer_app.layouts.need_to_select_store', 'Select a store and floor to view the map')}</FallbackInformation>
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card sx={{ height: '80vh', position: 'relative', p: 0, pb: 2 }}>
                    {(isLoadingBucketMapLayout || isLoadingRobotLayout) && <LinearProgress sx={{ width: '100%', top: '0', left: '0', position: 'absolute' }} color="secondary" />}
                    <Tabs value={tabIndex} sx={{ borderBottom: 1, borderColor: 'divider' }} onChange={(event, newValue) => setTabIndex(newValue)} variant="scrollable" scrollButtons="auto">
                        <Tab wrapped label={t('overseer_app.layouts.layers', 'Layers')} {...a11yProps(0, { textTransform: 'initial' })} />
                        {/* <Tab wrapped label="Versions" {...a11yProps(1, { textTransform: 'initial' })} /> */}
                    </Tabs>
                    <CardContent sx={{ maxHeight: '100%', overflow: 'auto', height: '100%', p: 0 }}>
                        {isLoadingBucketMapLayout || isLoadingRobotLayout ?
                            <FallbackInformation showLoading>{t('overseer_app.layouts.loading_info', 'Loading information...')}</FallbackInformation>
                            :
                            showStoreMap ?
                                <LayersSidebar layers={layers} toggles={{
                                    parent: toggleParentLayer,
                                    child: toggleChildLayer
                                }} />
                                :
                                <FallbackInformation>{t('overseer_app.layouts.need_to_select_store', 'Select a store and floor to view the map')}</FallbackInformation>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    );
}
