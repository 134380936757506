import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { LinearProgress, Paper, Stack, Tab, Tabs } from '@mui/material'
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';

import { DEBOUNCE_WAIT_TIME } from '../Utils';
import TabPanel from '../tools/TabPanelv2';
import { useDebounce } from '../utils/debounce';
import RobotParameters from './RobotParameters';

export default function Parameters(props) {
  const {
    getChains,
    chains,
    isLoadingChains,
    getRobotsBaldur,
    robots,
    isLoadingRobots,
    getRobotParameters,
    robotParameters,
    isLoadingRobotParameters,
    getGroupedRobotParameters,
    groupedRobotParameters,
    isLoadingGroupedRobotParameters,
    getParameters,
    parameters,
    postRobotParameters,
    isLoadingRobotParamsPost,
    robotParamsPostResponse,
    setSnackAlert,
    getRobotStatuses,
    robotStatuses,
    isLoadingRobotStatuses,
    checkFeaturePermission,
    featurePermission
  } = props;
  const { t } = useTranslation();
  // States
  const [tab, setTab] = useState(0);
  // Robot Parameters States
  const [selectedRobots, setSelectedRobots] = useState([]);
  const [canUpdate, setCanUpdate] = useState(false);
  // Other constants
  const debouncedSelectedRobots = useDebounce(selectedRobots, DEBOUNCE_WAIT_TIME, [])
  const MODIFY_ROBOT_PARAMETERS_PERMISSION = 'modify_robot_parameters';
  // Lifecycles

  useEffect(() => {
    checkFeaturePermission(MODIFY_ROBOT_PARAMETERS_PERMISSION);
  }, []);

  // Check if the user has the permission to access the parameters
  useEffect(() => {
    if(MODIFY_ROBOT_PARAMETERS_PERMISSION in featurePermission) {
      setCanUpdate(!!featurePermission[MODIFY_ROBOT_PARAMETERS_PERMISSION])
    }
  }, [featurePermission]);
  
  // Methods

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <Stack component={Paper} sx={{ minHeight: 'calc(100vh - 15em)' }}>
      {!isEmptyOrUndefined(debouncedSelectedRobots, 'array') && (isLoadingGroupedRobotParameters || isLoadingRobotParameters) &&
        <LinearProgress
          sx={{ borderRadius: '10px 10px 0 0' }}
          color="secondary"
        />
      }
      {/* Tabs */}
      <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
        <Tab label={t('overseer_app.parameters.Robot_parameters', 'Robot Parameters')} />
        <Tab label={t('overseer_app.parameters.Parameters', 'Parameters')} disabled />
      </Tabs>
      {/* Robot Parameters Tab Panel */}
      <TabPanel component={Stack} value={tab} index={0} sx={{ p: 2, minHeight: 'inherit' }}>
        <RobotParameters
          t={t}
          debouncedSelectedRobots={debouncedSelectedRobots}
          getChains={getChains}
          chains={chains}
          isLoadingChains={isLoadingChains}
          getRobotsBaldur={getRobotsBaldur}
          robots={robots}
          isLoadingRobots={isLoadingRobots}
          getRobotParameters={getRobotParameters}
          robotParameters={robotParameters}
          isLoadingRobotParameters={isLoadingRobotParameters}
          getGroupedRobotParameters={getGroupedRobotParameters}
          groupedRobotParameters={groupedRobotParameters}
          isLoadingGroupedRobotParameters={isLoadingGroupedRobotParameters}
          getParameters={getParameters}
          parameters={parameters}
          selectedRobots={selectedRobots}
          setSelectedRobots={setSelectedRobots}
          postRobotParameters={postRobotParameters}
          isLoadingRobotParamsPost={isLoadingRobotParamsPost}
          robotParamsPostResponse={robotParamsPostResponse}
          setSnackAlert={setSnackAlert}
          getRobotStatuses={getRobotStatuses}
          robotStatuses={robotStatuses}
          isLoadingRobotStatuses={isLoadingRobotStatuses}
          canUpdate={canUpdate}
        />
      </TabPanel>
      {/* Parameters Tab Panel */}
      <TabPanel value={tab} index={1}>
        Tab panel for the Parameters
      </TabPanel>
    </Stack>
  )
}
