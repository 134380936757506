import React, { useEffect, useState } from 'react';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from '@vis.gl/react-google-maps';
import {
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Switch,
  Tooltip,
  Skeleton,
  Divider,
  Autocomplete
} from '@mui/material';
import { Add, LocationOn } from '@mui/icons-material';
import StoreIcon from '@mui/icons-material/Store';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import { useTranslation } from 'react-i18next';
import StoreFormDialog from './StoreFormDialog';
import StoreConfirmDialog from './StoreConfirmDialog';
import { capitalizeFirstLetter } from '../tools/utils';

const { REACT_APP_GOOGLE_MAP_ID } = process.env;

export default function StoreConfig(props) {
  const {
    getStoreConfigs,
    isLoadingStoreConfigs,
    storeConfigs,
    getChains,
    chainsList,
    postStoreConfig,
    newStoreResponse,
    patchStoreConfig,
    storeUpdateResponse,
    deleteStoreConfig,
    deleteStoreResponse,
    getCountries,
    countriesList,
    getMapsKey,
    gmapsApiKey,
    featurePermission,
    checkFeaturePermission,
    setSnackAlert
  } = props;

  const { t } = useTranslation();

  const [chain, setChain] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [editStore, setEditStore] = useState(null);
  const [storeAction, setStoreAction] = useState(null);
  const [showProductive, setShowProductive] = useState(true);
  const [markerColor, setMarkerColor] = useState(null);
  const [canUpdate, setCanUpdate] = useState(false);
  const [newStore, setNewStore] = useState({
    store_code: '',
    store_name: '',
    store_type: '',
    coordinates: { lat: -33.45, lng: -70.65 },
  });
  const [currentStore, setCurrentStore] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(null);

  const UPDATE_STORE_CONFIG_PERMISSION = 'update_store_config';

  useEffect(() => {
    getChains();
    getStoreConfigs();
    getCountries();
    getMapsKey();
    checkFeaturePermission(UPDATE_STORE_CONFIG_PERMISSION);
  }, []);

  useEffect(() => {
    if(UPDATE_STORE_CONFIG_PERMISSION in  featurePermission) {
      setCanUpdate(!!featurePermission[UPDATE_STORE_CONFIG_PERMISSION])
    }
  }, [featurePermission]);

  useEffect(() => {
    const colors = {};
    chainsList.forEach((chain) => {
      if (chain.properties)
        colors[chain.name] = chain.properties.map_marker_colors;
    });
    setMarkerColor(colors);
  }, [chainsList]);

  useEffect(() => {
    getStoreConfigs();
  }, [newStoreResponse, storeUpdateResponse, deleteStoreResponse]);

  const handleChainChange = (event, newChain) => {
    setChain(newChain);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleCreateStore = () => {
    setStoreAction('create');
    setOpenDialog(true);
  };

  const handleOpenConfirm = (storeCode, action) => {
    setSelectedStore(storeCode);
    setConfirmAction(action);
    setOpenConfirmDialog(true);
  };
  const handleCloseConfirm = () => setOpenConfirmDialog(false);

  const handlePlaceSelect = (place) => {
    if (place && place.geometry) {
      setNewStore({
        ...newStore,
        address: place.formatted_address,
        coordinates: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      });
    }
  };

  const handleSaveStore = (storeConfig, action, storeCode = null) => {
    if (action === 'create') postStoreConfig(storeConfig);
    else if (action === 'edit') patchStoreConfig(storeCode, storeConfig);
  };

  const handleConfirmStore = () => {
    if (confirmAction === 'edit') {
    } else if (confirmAction === 'delete') {
      deleteStoreConfig(selectedStore);
      setOpenConfirmDialog(false);
    }
  };

  const handleEditStore = (storeConfig) => {
    setStoreAction('edit');
    setEditStore(storeConfig);
    setOpenDialog(true);
  };

  const renderSkeleton = () => (
    <ListItem alignItems="flex-start" style={{ marginBottom: '16px' }}>
      <ListItemText
        primary={<Skeleton width="60%" />}
        secondary={
          <>
            <Skeleton width="40%" />
            <Skeleton width="50%" />
            <Skeleton width="30%" />
          </>
        }
      />
      <IconButton>
        <Skeleton variant="circular" width={24} height={24} />
      </IconButton>
      <IconButton>
        <Skeleton variant="circular" width={24} height={24} />
      </IconButton>
    </ListItem>
  );

  const filteredStores = storeConfigs.filter((store) => {
    const matchesChain = chain ? store.chain_name === chain : true;
    const matchesSearch = `${store.store_name} ${store.store_code}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesProductive = showProductive
      ? store.store_type === 'production'
      : store.store_type !== 'production';
    return matchesChain && matchesSearch && matchesProductive;
  });
  
  const onStoreClick = (element) => {
    setCurrentStore(element.coordinates)
    setCurrentZoom(20)
  }

  const onCameraChanged = (element) => {
    setCurrentStore(null)
    setCurrentZoom(null)
  }

  return (
    <Grid container spacing={2} style={{ height: '500px' }}>
      <Grid item xs={4}>
        <Paper
          style={{
            height: '88vh',
            overflow: 'auto',
            padding: '1rem',
          }}
          elevation={2}
        >
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item sm={12} md={8}>
              <Typography variant="h4" marginBottom={1}>
                Store Locator
              </Typography>
            </Grid>
            <Grid item sm={12} md={4}>
              <Box display="flex" alignItems="center">
                <Switch
                  checked={showProductive}
                  onChange={() => setShowProductive(!showProductive)}
                  color="primary"
                />
                <Typography variant="body2" align="center">
                  {t('Productive')}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              <Autocomplete
                fullWidth
                value={chain}
                options={chainsList.map((option) => option.name)}
                onChange={handleChainChange}
                renderInput={(params) => <TextField {...params} label="Chains" />}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  const backgroundColor = markerColor[option] ? markerColor[option].background : '#EA4435'
                  return (
                    <Box
                      key={key}
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...optionProps}
                    >
                      <Box
                        component="span"
                        sx={{
                          width: 14,
                          height: 14,
                          flexShrink: 0,
                          borderRadius: '3px',
                          mr: 1,
                          mt: '2px',
                        }}
                        style={{ backgroundColor: backgroundColor }}
                      />
                      {option}
                    </Box>
                  );
                }}
          
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search stores"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title={t('overseer_app.general.add_store', 'Add new store')}
              >
                <IconButton>
                  <Add color="primary" onClick={handleCreateStore} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <List>
            {isLoadingStoreConfigs
              ? Array.from({ length: 5 }).map((_, index) => (
                  <React.Fragment key={index}>
                    {renderSkeleton()}
                  </React.Fragment>
                ))
              : filteredStores.map((item) => (
                  <React.Fragment>
                    <ListItem
                      key={item.store_code}
                      alignItems="flex-start"
                      style={{ marginBottom: '0px', paddingInline: 0 }}
                      onClick={() => onStoreClick(item)}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'bold' }}
                          >
                            {`${item.store_code} - ${item.store_name}`}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" color="textSecondary">
                              <FlagIcon fontSize="small" sx={{color: markerColor[item.chain_name] ? markerColor[item.chain_name].background : '#EA4435'}}/> 
                              {item.chain_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <LocationOn fontSize="small" /> {item.address}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <StoreIcon fontSize="small" />{' '}
                              {capitalizeFirstLetter(item.store_type)}
                            </Typography>
                          </>
                        }
                      />
                      <IconButton disabled={!canUpdate}>
                        <EditIcon
                          color="primary"
                          onClick={() => handleEditStore(item)}
                        />
                      </IconButton>
                      <IconButton disabled={!canUpdate}>
                        <DeleteIcon
                          color="primary"
                          onClick={() =>
                            handleOpenConfirm(item.store_code, 'delete')
                          }
                        />
                      </IconButton>
                    </ListItem>
                    <Divider/>
                  </React.Fragment>
                ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper
          style={{ height: '88vh', overflow: 'auto', padding: '0.5rem' }}
          elevation={2}
        >
          {gmapsApiKey && (
            <APIProvider apiKey={gmapsApiKey?.maps_key}>
              <Map
                mapId={gmapsApiKey?.maps_id}
                mapContainerStyle={{ height: '100%', width: '100%' }}
                defaultCenter={{ lat: -33.45, lng: -70.65 }}
                defaultZoom={5}
                onCameraChanged={onCameraChanged}
                center={currentStore}
                zoom={currentZoom}
              >
                {filteredStores.map(
                  (item) =>
                    item.coordinates && (
                      <AdvancedMarker
                        position={{
                          lat: item.coordinates?.lat,
                          lng: item.coordinates?.lng,
                        }}
                        title={item.store_code}
                      >
                        <Pin
                          background={
                            markerColor[item['chain_name']]?.background
                          }
                          borderColor={
                            markerColor[item['chain_name']]?.borderColor
                          }
                          glyphColor={
                            markerColor[item['chain_name']]?.glyphColor
                          }
                        />
                      </AdvancedMarker>
                    )
                )}
                {hoveredMarker && (
                  <InfoWindow
                    pixelOffset={[0, -50]}
                    position={{
                      lat: filteredStores.find(
                        (store) => store.store_code === hoveredMarker
                      )?.coordinates?.lat,
                      lng: filteredStores.find(
                        (store) => store.store_code === hoveredMarker
                      )?.coordinates?.lng,
                    }}
                    onCloseClick={() => setHoveredMarker(null)}
                  >
                    <div>
                      <Typography variant="body2">
                        <strong>
                          {
                            filteredStores.find(
                              (store) => store.store_code === hoveredMarker
                            )?.store_name
                          }
                        </strong>
                      </Typography>
                      <Typography variant="caption">
                        Code: {hoveredMarker}
                      </Typography>
                    </div>
                  </InfoWindow>
                )}
              </Map>
              <StoreFormDialog
                open={openDialog}
                onClose={handleCloseDialog}
                apiKey={gmapsApiKey}
                onPlaceSelect={handlePlaceSelect}
                chainsList={chainsList}
                onSave={handleSaveStore}
                countries={countriesList}
                storeInfo={editStore}
                action={storeAction}
                setSnackAlert={setSnackAlert}
              />
            </APIProvider>
          )}
        </Paper>
      </Grid>
      <StoreConfirmDialog
        open={openConfirmDialog}
        action={confirmAction}
        handleClose={handleCloseConfirm}
        storeCode={selectedStore}
        handleConfirm={handleConfirmStore}
      />
    </Grid>
  );
}
