import React, { useEffect, useState } from 'react'
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from 'react-window';

import { Box, InputAdornment, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import { ZIPPEDI_ICON_COLOR } from '../Utils';

const renderRow = (props) => {
  const { index, style, data } = props;
  const { t, filteredRobotParams, selectedVersion, setSelectedVersion, setTableRobotParameters, robotParameters, setIsLatestVersionSelected } = data
  const robotParameter = filteredRobotParams[index]

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton
        divider
        selected={robotParameter.robot_parameter_id === selectedVersion.robot_parameter_id}
        onClick={() => {
          setSelectedVersion(robotParameter);
          setTableRobotParameters(robotParameter.parameters);
          setIsLatestVersionSelected(robotParameter.version === robotParameters[0].version) // * This assumes robotParameters is ordered by version DESC
        }}
      >
        <ListItemIcon>
          {robotParameter.robot_ack ?
            <Tooltip title={t('Gerenal.active', 'Active')}>
              <Box sx={{ height: '70%', width: '70%', ml: -1 }}>
                <IconComponent
                  iconName={'validation-r2'}
                />
              </Box>
            </Tooltip>
            : index === 0 ?
              // * This assumes robotParameters is ordered by version DESC
              <Tooltip title={t('overseer_app.parameters.Waiting_ack', 'Waiting ACK')}>
                <Box sx={{ height: '70%', width: '70%', ml: -0.6 }}>
                  <IconComponent
                    iconName={'robot-scheduling-r2'}
                  />
                </Box>
              </Tooltip>
              :
              <Tooltip title={t('overseer_app.parameters.Archived', 'Archived')}>
                <Box>
                  <IconComponent
                    iconName={'file-tray-full'}
                    style={{ fontSize: "25px", color: ZIPPEDI_ICON_COLOR }}
                  />
                </Box>
              </Tooltip>
          }
        </ListItemIcon>
        <Stack>
          <ListItemText primary={`${t('overseer_app.parameters.Version', 'Version')} ${robotParameter.version}`} sx={{ margin: 0 }} />
          <ListItemText secondary={robotParameter.author} sx={{ margin: 0 }} />
          <ListItemText secondary={robotParameter.created_at} sx={{ margin: 0 }} />
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}

export default function Versions(props) {
  const {
    t,
    robotParameters,
    filteredRobotParams,
    setFilteredRobotParams,
    selectedVersion,
    setSelectedVersion,
    setTableRobotParameters,
    setIsLatestVersionSelected,
  } = props;
  const [searchValue, setSearchValue] = useState('');

  // Lifecycle methods

  useEffect(() => {
    if (!isEmptyOrUndefined(searchValue, 'id')) {
      const searchParams = () => {
        const filteredParams = filteredRobotParams.filter(param => {
          return (
            param.author.toLowerCase().includes(searchValue.toLowerCase()) ||
            param.created_at.includes(searchValue.toLowerCase()) ||
            String(param.version).includes(searchValue.toLowerCase())
          );
        })
        setFilteredRobotParams(filteredParams)
      }
      searchParams();
    } else {
      setFilteredRobotParams(robotParameters);
    }
  }, [searchValue])


  // Methods

  return (
    <Stack component={Paper} spacing={2} sx={{ p: 2, width: '100%' }}>
      <Typography variant='h5'>{t('overseer_app.parameters.Versions', 'Versions')}</Typography>
      <TextField
        id="searchValue-bar"
        label={t('overseer_app.general.Search', 'Search')}
        variant="filled"
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconComponent
                iconName={'search'}
                style={{ fontSize: "20px" }}
              />
            </InputAdornment>
          ),
        }}
      />
      {isEmptyOrUndefined(filteredRobotParams, 'array') &&
        <Typography color='GrayText' align='center'>{t('overseer_app.http_messages.no_results_found', 'No results found.')}</Typography>
      }
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            className="List"
            height={height - 120}
            width={width}
            itemCount={filteredRobotParams.length}
            itemSize={80}
            overscanCount={5}
            itemData={{ t, filteredRobotParams, selectedVersion, setSelectedVersion, setTableRobotParameters, robotParameters, setIsLatestVersionSelected }}
          >
            {renderRow}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Stack>
  )
}
