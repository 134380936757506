import React, { useEffect, useState } from 'react'

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Link, Stack, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';

import { secretManagerURL } from '../Utils';

export default function EditDialog(props) {
  const {
    t,
    openEditDialog,
    setOpenEditDialog,
    parameter,
    selectedParameter,
    robotByUUID,
    isSingleRobotSelected,
    changes,
    setChanges,
    selectedRobots,
    oldParameters,
    setOldParameters,
    getDataType,
    setDataType,
  } = props;
  const theme = useTheme();
  const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"))
  const [input, setInput] = useState({}); // Each key-value pair is robot_uuid: obj with the value and change type. E.g.: {"8c26bf65-73be-4a5e-ab1d-1bea1e135cdb": {value: 43.2, changeType: 'edit'}, "fd9e29bd-fad5-4ab3-bb29-c944d19eab51": {value: 50, changeType: 'add'}}
  const [checkedSwitch, setCheckedSwitch] = useState(isSingleRobotSelected ? false : true);
  const [currentRobots, setCurrentRobots] = useState([]); // List of robots: robots in selectedParameter.values + robots in changes.add
  // States for displaying the "All Robots" inputs empty when it first loads or when the checkedSwitch changes
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFirstLoadProjectID, setIsFirstLoadProjectID] = useState(true);
  const [isFirstLoadSecretID, setIsFirstLoadSecretID] = useState(true);
  const [isFirstLoadVersionID, setIsFirstLoadVersionID] = useState(true);

  // TODO: delete when the list data_type doesn't exist anymore
  // Parse the default value if it's a list
  let defaultValue = String(parameter?.default_value)
  if (parameter['data_type'] === 'list' && !isEmptyOrUndefined(parameter?.default_value, 'id')) {
    // Parse the string list of values 
    const parseList = JSON.parse(parameter?.default_value)

    // Join the parsed list elements with commas
    defaultValue = parseList.join(', ')
  }

  // Lifecycle methods

  // If when there are existing changes for the selected parameter use them, otherwise use the value in selectedParameter (the original value)
  useEffect(() => {
    if (openEditDialog) {
      let initialValues = {}
      const currentValueType = parameter.data_type === 'secret' ? 'object' : 'id';
      const currentRobotsList = [];

      selectedRobots.forEach(robot => {
        let existingChange = {};

        if (!isEmptyOrUndefined(changes.add[robot.robot_uuid], 'object') && !isEmptyOrUndefined(changes.add[robot.robot_uuid][selectedParameter?.code], currentValueType)) {
          // Changes in changes.add
          existingChange = { changeType: 'add', value: changes.add[robot.robot_uuid][selectedParameter?.code] }
          currentRobotsList.push({
            ...robot,
            value: t('overseer_app.parameters.No_value_assigned', 'No value assigned.')
          });
        } else if (!isEmptyOrUndefined(changes.edit[robot.robot_uuid], 'object') && !isEmptyOrUndefined(changes.edit[robot.robot_uuid][selectedParameter?.code], currentValueType)) {
          // Changes in changes.edit
          existingChange = { changeType: 'edit', value: changes.edit[robot.robot_uuid][selectedParameter?.code] }
          selectedParameter.values.some(paramRobot => {
            if (paramRobot.robot_uuid === robot.robot_uuid) {
              currentRobotsList.push({
                ...robot,
                value: paramRobot.value
              });
              return true;
            }
            return false;
          })
        } else {
          // Set original value
          selectedParameter.values.some(paramRobot => {
            if (paramRobot.robot_uuid === robot.robot_uuid) {
              existingChange = { changeType: null, value: paramRobot.value }
              currentRobotsList.push({
                ...robot,
                value: paramRobot.value
              });
              return true;
            }
            return false;
          })
        }

        initialValues[robot.robot_uuid] = existingChange
      })

      setInput(initialValues);
      setCurrentRobots(currentRobotsList);
    }
  }, [openEditDialog]);

  // Methods

  const handleInputChange = (value, robot_uuid) => {
    setInput((prev) => ({
      ...prev,
      [robot_uuid]: {
        ...prev[robot_uuid],
        value: value
      }
    }));
  };

  const handleSaveChanges = () => {
    let oldParams = structuredClone(oldParameters);
    let oldEdits = structuredClone(changes.edit);
    let newEdits = {};
    let newAdds = {};
    let values = [];

    currentRobots.forEach((robot) => {
      let robotEditPrevState = oldEdits[robot.robot_uuid];
      let oldRobotParams = oldParams[robot.robot_uuid];
      const newParamValue = input[robot.robot_uuid]?.value;
      const changeType = input[robot.robot_uuid]?.changeType;

      // If the updated parameter was added, there's no need to compare it to the original value (because there's none) or remove it from the unedited parameters 
      if (changeType === 'add') {
        newAdds[robot.robot_uuid] = {
          ...changes.add[robot.robot_uuid],
          [selectedParameter?.code]: newParamValue,
        };
        values.push({
          "id": robot.id,
          "value": newParamValue,
          "robot_uuid": robot.robot_uuid,
          "data_type": parameter.data_type,
          "version": null,
          "category": parameter.category
        })
        return;
      }

      // Cast to String to be able to compare lists easily
      const areValuesEqual = parameter.data_type === 'secret' ? JSON.stringify(newParamValue) === JSON.stringify(robot.value) : String(newParamValue) === String(robot.value);

      // If the new value is different from the old value, update the parameter
      if (!areValuesEqual) {
        newEdits[robot.robot_uuid] = {
          ...robotEditPrevState,
          [selectedParameter?.code]: newParamValue,
        };
        // Because the param is changed, remove it from oldParameters 
        if (Object.keys(oldRobotParams).length > 1) {
          delete oldRobotParams[selectedParameter?.code]
        } else {
          delete oldRobotParams[robot.robot_uuid]
        }
        // If the new value is the same as the old value, remove the parameter from the update
      } else if (robotEditPrevState && selectedParameter?.code in robotEditPrevState) {
        // Add the value to oldParameters when it's no longer edited
        oldParams[robot.robot_uuid] = {
          ...oldRobotParams,
          [selectedParameter?.code]: newParamValue,
        };
        // If there's more the one edited parameter for the robot, delete the parameter,
        // else delete the robot object from changes.edit
        if (Object.keys(robotEditPrevState).length > 1) {
          delete robotEditPrevState[selectedParameter?.code]
        } else {
          delete oldEdits[robot.robot_uuid]
        }
      }
    });

    setChanges(prevState => ({
      ...prevState,
      add: {
        ...changes.add,
        ...newAdds
      },
      edit: {
        ...oldEdits,
        ...newEdits
      }
    }))

    setOldParameters(oldParams)

    // Reset the local variables and close the dialog
    handleClose();
  };

  const handleClose = () => {
    setOpenEditDialog(false);
    setInput({});
  }

  return (
    <Dialog
      fullWidth
      maxWidth={isSingleRobotSelected ? 'sm' : 'md'}
      open={openEditDialog}
      onClose={handleClose}
    >
      {/* Dialog title */}
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          {t('overseer_app.parameters.Parameter', 'Parameter')}: {selectedParameter?.code}
          {!isSingleRobotSelected &&
            <FormControlLabel
              control={
                <Switch color="primary"
                  checked={checkedSwitch}
                  onChange={(event) => {
                    setCheckedSwitch(event.target.checked);
                    setIsFirstLoad(true);
                    setIsFirstLoadProjectID(true);
                    setIsFirstLoadSecretID(true);
                    setIsFirstLoadVersionID(true);
                  }}
                />
              }
              label={t('overseer_app.parameters.Edit_All_Robots', 'Edit All Robots')}
              labelPlacement="end"
            />
          }
        </Stack>
      </DialogTitle>
      {/* Dialog content */}
      <DialogContent dividers>
        <Stack spacing={2}>
          {/* Alert: edit warning */}
          {!isSingleRobotSelected && <Alert severity='warning'>{t('overseer_app.parameters.editing_warning', 'Only robots with an assigned value for this parameter will be shown and updated.')}</Alert>}
          {/* Alert: info */}
          <Alert severity='info'>
            {t('overseer_app.parameters.Data_type', 'Data type')}: {String(parameter.data_type)}.
            {(parameter.data_type === 'list' || parameter.data_type === 'str') && <>&nbsp;{t('overseer_app.parameters.dollar_sign_warning', 'Never use a dollar sign ($).')}</>}
            {parameter.data_type === 'list' && <>&nbsp;{t('overseer_app.parameters.list_format_warning', 'Please separate values with commas.')}</>}
          </Alert>
          {/* Default value */}
          <Typography><strong>{t('overseer_app.parameters.Defualt_value', 'Default value')}: </strong>
            {isEmptyOrUndefined(parameter?.default_value, 'id') ?
              <>{t('overseer_app.parameters.No_value_assigned', 'No value assigned.')}</>
              :
              defaultValue
            }
          </Typography>
          <Grid container spacing={{ xs: 5, sm: 0 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent='space-between'>
            {/* All robots */}
            {!isSingleRobotSelected &&
              <Grid xs={4} sm={3} md={5} size={{ xs: 4, sm: 3, md: 5 }}>
                <Stack spacing={1}>
                  <Typography sx={{ fontWeight: 'bold' }}>{t('overseer_app.parameters.All_Robots', 'All Robots')}</Typography>
                  {parameter.data_type === 'secret' ?
                    // Data type: secret
                    <Stack spacing={1}>
                      <TextField
                        disabled={!checkedSwitch}
                        label='project_id'
                        value={isFirstLoadProjectID ? '' : checkedSwitch ? input[selectedRobots[0].robot_uuid]?.value?.project_id || '' : ''}
                        onChange={(event) => {
                          selectedRobots.forEach(robot => {
                            handleInputChange({ ...input[robot.robot_uuid].value, 'project_id': event.target.value }, robot.robot_uuid)
                          })
                          setIsFirstLoadProjectID(false);
                        }}
                      />
                      <TextField
                        disabled={!checkedSwitch}
                        label='secret_id'
                        value={isFirstLoadSecretID ? '' : checkedSwitch ? input[selectedRobots[0].robot_uuid]?.value?.secret_id || '' : ''}
                        onChange={(event) => {
                          selectedRobots.forEach(robot => {
                            handleInputChange({ ...input[robot.robot_uuid].value, 'secret_id': event.target.value }, robot.robot_uuid)
                          })
                          setIsFirstLoadSecretID(false);
                        }}
                      />
                      <TextField
                        disabled={!checkedSwitch}
                        label='version_id'
                        value={isFirstLoadVersionID ? '' : checkedSwitch ? input[selectedRobots[0].robot_uuid]?.value?.version_id || '' : ''}
                        onChange={(event) => {
                          selectedRobots.forEach(robot => {
                            handleInputChange({ ...input[robot.robot_uuid].value, 'version_id': event.target.value }, robot.robot_uuid)
                          })
                          setIsFirstLoadVersionID(false);
                        }}
                      />
                    </Stack>
                    :
                    // Data type: normal
                    <TextField
                      disabled={!checkedSwitch}
                      key='all-robots-input'
                      type={getDataType(parameter.data_type)}
                      label={t('overseer_app.parameters.New_value', 'New value')}
                      value={isFirstLoad ? '' : checkedSwitch && !(input[selectedRobots[0].robot_uuid]?.value === '' || input[selectedRobots[0].robot_uuid]?.value === undefined) ? (selectedParameter?.data_type === 'bool' ? String(input[selectedRobots[0].robot_uuid]?.value) : input[selectedRobots[0].robot_uuid]?.value) : ''}
                      // TODO: when there's real data validation, change this error and helperText validations
                      error={checkedSwitch && !isEmptyOrUndefined(input[selectedRobots[0].robot_uuid]?.value, 'id') && selectedParameter?.data_type === 'bool' && input[selectedRobots[0].robot_uuid]?.value !== true && input[selectedRobots[0].robot_uuid]?.value !== false}
                      helperText={checkedSwitch && !isEmptyOrUndefined(input[selectedRobots[0].robot_uuid]?.value, 'id') && selectedParameter?.data_type === 'bool' && input[selectedRobots[0].robot_uuid]?.value !== true && input[selectedRobots[0].robot_uuid]?.value !== false ? t('overseer_app.parameters.Incorrect_bool', 'Incorrect boolean value') : ''}
                      onChange={(event) => {
                        selectedRobots.forEach(robot => {
                          handleInputChange(setDataType(event.target.value, parameter.data_type), robot.robot_uuid)
                        })
                        setIsFirstLoad(false);
                      }}
                    />}
                </Stack>
              </Grid>}
            {/* Divider */}
            {!isSingleRobotSelected && <Divider flexItem orientation={isSmDownBreakpoint ? "horizontal" : "vertical"} sx={isSmDownBreakpoint ? { width: '92%', margin: 'auto' } : {}} />}
            {/* Single & individual robots */}
            <Grid flexGrow={1} xs={4} sm={isSingleRobotSelected ? 8 : 4} md={isSingleRobotSelected ? 12 : 6} size={isSingleRobotSelected ? { xs: 4, sm: 8, md: 12 } : { xs: 4, sm: 4, md: 6 }}>
              <Stack spacing={1}>
                {!isSingleRobotSelected && <Typography sx={{ fontWeight: 'bold' }}>{t('overseer_app.parameters.Individual_Robots', 'Individual Robots')}</Typography>}
                {currentRobots?.map(robot => {
                  // Data type: secret
                  if (parameter.data_type === 'secret') {
                    return (
                      <Stack key={robot.robot_uuid} spacing={1}>
                        <Typography sx={{ fontWeight: 'bold' }}>{robotByUUID[robot.robot_uuid]?.robot_code}</Typography>
                        <Typography>
                          {t('overseer_app.parameters.secret_manager_link_part_1', 'Edit the referenced content in the ')}
                          <Link target="_blank" href={secretManagerURL(input[robot.robot_uuid]?.value?.project_id, input[robot.robot_uuid]?.value?.secret_id)}>
                            {t('overseer_app.parameters.secret_manager_link_part_2', 'Secret Manager UI.')}
                          </Link>
                        </Typography>
                        <TextField
                          disabled={checkedSwitch}
                          label='project_id'
                          value={input[robot.robot_uuid]?.value?.project_id || ''}
                          onChange={(event) => handleInputChange({ ...input[robot.robot_uuid].value, 'project_id': event.target.value }, robot.robot_uuid)}
                          helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value?.project_id}`}
                        />
                        <TextField
                          disabled={checkedSwitch}
                          label='secret_id'
                          value={input[robot.robot_uuid]?.value?.secret_id || ''}
                          onChange={(event) => handleInputChange({ ...input[robot.robot_uuid].value, 'secret_id': event.target.value }, robot.robot_uuid)}
                          helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value?.secret_id}`}
                        />
                        <TextField
                          disabled={checkedSwitch}
                          label='version_id'
                          value={input[robot.robot_uuid]?.value?.version_id || ''}
                          onChange={(event) => handleInputChange({ ...input[robot.robot_uuid].value, 'version_id': event.target.value }, robot.robot_uuid)}
                          helperText={`${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value?.version_id}`}
                        />
                      </Stack>
                    )
                  } else {
                    // Data type: normal
                    return (
                      <TextField
                        disabled={checkedSwitch}
                        key={robot.robot_uuid}
                        type={getDataType(parameter.data_type)}
                        label={robotByUUID[robot.robot_uuid]?.robot_code}
                        value={input[robot.robot_uuid]?.value === '' || input[robot.robot_uuid]?.value === undefined ? '' : (selectedParameter?.data_type === 'bool' ? String(input[robot.robot_uuid]?.value) : input[robot.robot_uuid]?.value)}
                        // TODO: when there's real data validation, change this error and helperText validations
                        error={!isEmptyOrUndefined(input[robot.robot_uuid]?.value, 'id') && selectedParameter?.data_type === 'bool' && input[robot.robot_uuid]?.value !== true && input[robot.robot_uuid]?.value !== false}
                        helperText={!isEmptyOrUndefined(input[robot.robot_uuid]?.value, 'id') && selectedParameter?.data_type === 'bool' && input[robot.robot_uuid]?.value !== true && input[robot.robot_uuid]?.value !== false ? <>{`${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value}`}<br />{t('overseer_app.parameters.Incorrect_bool', 'Incorrect boolean value')}</> : `${t('overseer_app.parameters.Old_value', 'Old value')}: ${robot.value}`}
                        onChange={(event) => handleInputChange(setDataType(event.target.value, parameter.data_type), robot.robot_uuid)}
                      />
                    )
                  }
                }
                )}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      {/* Dialog actions */}
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {t('overseer_app.general.Cancel', 'Cancel')}
        </Button>
        <Button onClick={handleSaveChanges}>{t('overseer_app.general.Save', 'Save')}</Button>
      </DialogActions>
    </Dialog >
  )
}
