import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { a11yProps } from '../Utils';
import AlertBox from '../tools/AlertBox';
import TabPanel from '../tools/TabPanel';
import ServicingRow from './Components/ServicingRow';

function ServicingHistoryWidget(props) {
  const {
    getServicingHistory,
    servicingHistory,
    loadingServicingHistory,
    store,
    robot,
    getChangeMotives,
    changeMotives,
    loadingChangeMotives,
    patchChangeReasonsResponse,
    handleOpenDialog,
  } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [openSnackbar, setOpenSnackBar] = useState(true);
  const { t } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    getServicingHistory({
      robot_id: `${store}-${robot}`,
    });
    getChangeMotives();
  }, [store, robot, patchChangeReasonsResponse]);

  return loadingServicingHistory || loadingChangeMotives ? (
    <Box
      variant="rectangular"
      minHeight={250}
      height={'100%'}
      sx={{
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
      <Typography>Loading Servicing History...</Typography>
    </Box>
  ) : (
    <Card
      sx={{
        minHeight: 250,
        height: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={t(
              'overseer_app.widget.servicing_history',
              'Servicing History'
            )}
            {...a11yProps(0, { textTransform: 'initial' })}
          />
        </Tabs>
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          onClick={handleOpenDialog}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid container>
          <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 200 }}>
            {servicingHistory.length > 0 ? (
              servicingHistory.map((component, idx) => (
                <ServicingRow
                  component={component}
                  key={idx}
                  changeMotives={changeMotives}
                />
              ))
            ) : (
              <AlertBox
                severity="info"
                content={t(
                  'overseer_app.widget.no_servicing_history',
                  'No Servicing History available'
                )}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
    </Card>
  );
}

export default ServicingHistoryWidget;
