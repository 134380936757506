import { 
  getChainsList, 
  getRobotListBaldur, 
  groupedRobotParameters, 
  isLoadingChains, 
  isLoadingGroupedRobotParameters, 
  isLoadingRobotListBaldur, 
  isLoadingRobotParameters, 
  isLoadingRobotParamsPost, 
  parameters, 
  robotParameters, 
  robotParamsPostResponse, 
  robotStatuses, 
  getFeaturePermission 
} from 'core/selectors';
import { getChains } from 'core/slices/backend';
import { getGroupedRobotParameters, getParameters, getRobotParameters, postRobotParameters } from 'core/slices/baldur/parameters';
import { getRobotsBaldur } from 'core/slices/fleetControl';
import { getRobotStatuses } from 'core/slices/robotStatus';
import { setSnackAlert } from 'core/slices/snackAlert';
import { checkFeaturePermission } from 'core/slices/backend';
import { connect } from 'react-redux';

import ParametersHome from "../../../components/parameters/ParametersHome";

export default connect(
  (state) => ({
    chains: getChainsList(state),
    isLoadingChains: isLoadingChains(state),
    robots: getRobotListBaldur(state),
    isLoadingRobots: isLoadingRobotListBaldur(state),
    robotParameters: robotParameters(state),
    isLoadingRobotParameters: isLoadingRobotParameters(state),
    groupedRobotParameters: groupedRobotParameters(state),
    isLoadingGroupedRobotParameters: isLoadingGroupedRobotParameters(state),
    parameters: parameters(state),
    robotParamsPostResponse: robotParamsPostResponse(state),
    isLoadingRobotParamsPost: isLoadingRobotParamsPost(state),
    robotStatuses: robotStatuses(state),
    featurePermission: getFeaturePermission(state),
  }),
  {
    getChains,
    getRobotsBaldur,
    getRobotParameters,
    getGroupedRobotParameters,
    getParameters,
    postRobotParameters,
    setSnackAlert,
    getRobotStatuses,
    checkFeaturePermission
  }
)(ParametersHome);
