import PropTypes from 'prop-types';
import React from 'react';

import { Stack } from '@mui/material';

function TabPanelv2(props) {
  const { children, value, index, component: Component = Stack, sx = { p: 2 }, ...otherProps } = props;
  return (
    <>
      {value === index && (
        <Component sx={sx} {...otherProps}>
          {children}
        </Component>
      )}
    </>
  );
}

TabPanelv2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanelv2;
