import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NavigationIcon from '@mui/icons-material/Navigation';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import Battery20Icon from '@mui/icons-material/Battery20';
import Battery50Icon from '@mui/icons-material/Battery50';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import ExploreIcon from '@mui/icons-material/Explore';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import MyLocationIcon from '@mui/icons-material/MyLocation';

import { Box, Tooltip, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RobotViewContext } from '../../context/RobotView';


const COLORS = {
  success: '#0E7F19',
  grey: 'grey',
  black: 'black',
};

const StatusBox = ({ icon, title, value, description }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mr: 2, ml: 2.5 }}>
    <Box>
      {description ? <Tooltip title={description}>{icon}</Tooltip> : icon}
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', gap: 0 }}>
      <Typography sx={{ fontSize: 15, fontWeight: 700, color: COLORS.black }}>
        {title}
      </Typography>
      {value && (
        <Typography variant="caption" sx={{ fontSize: 12, fontWeight: 700, color: COLORS.grey }}>
          {value}
        </Typography>
      )}
    </Box>
  </Box>
);

const BatteryStatusIcon = ({ batteryStatus, chargeStatus }) => {
  if (batteryStatus === 1) {
    return <BatteryChargingFullIcon fontSize="medium" sx={{ color: COLORS.success }} />;
  }
  if (batteryStatus === 2) {
    return <BatteryAlertIcon fontSize="medium" color="error" />;
  }

  const BatteryIcon = chargeStatus <= 0.2 ? Battery20Icon :
    chargeStatus > 0.8 ? BatteryFullIcon : Battery50Icon;

  const color = chargeStatus <= 0.2 ? "error" :
    chargeStatus > 0.8 ? COLORS.success : "warning";

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0 }}>
      <BatteryIcon fontSize="medium" sx={{ color }} />
      {chargeStatus && (
        <Typography sx={{ fontSize: 12, fontWeight: chargeStatus > 0.8 ? 600 : 700, color: COLORS.grey }}>
          {`(${chargeStatus * 100}%)`}
        </Typography>
      )}
    </Box>
  );
};

const RobotStatusWidget = ({ mqttNavInfo, isHeaderInfo = false, isMapInfo = false }) => {
  const { t } = useTranslation();

  const [rosStatus, setRosStatus] = useState('');
  const [doorState, setDoorState] = useState(null);
  const [autoPause, setAutoPause] = useState(null);
  const [locConfidence, setLocConfidence] = useState(null);
  const [navState, setNavState] = useState(null);
  const [relocStatus, setRelocStatus] = useState(null);
  const [currentAisle, setCurrentAisle] = useState(null);
  const [lastAisle, setLastAisle] = useState(null);
  const [batteryStatus, setBatteryStatus] = useState(null);
  const [batteryVoltage, setBatteryVoltage] = useState(null);
  const [rawRobotState, setRawRobotState] = useState(null);
  const [statusData, setStatusData] = useState([]);

  const {
    rawRobotStatus,
    connectionStatusMsg,
  } = useContext(RobotViewContext);


  // Hooks
  useEffect(() => {
    let newStatusData = [];

    if (isHeaderInfo) {
      newStatusData = newStatusData.concat([
        { name: 'ROS', key: 'ros_status' },
        { name: 'Door', key: 'door_state' },
        { name: 'Battery', key: 'battery_status' },
        { name: 'Internal', key: 'state' }
      ])
    }
    if (isMapInfo) {
      newStatusData = newStatusData.concat([
        { name: 'Navigation', key: 'nav_state' },
        { name: 'Confidence', key: 'loc_conf' },
        { name: 'Relocalization', key: 'reloc_status' },
        { name: 'Current Aisle', key: 'current_aisle' },
        { name: 'Last Aisle', key: 'last_aisle' }
      ])
    }

    setStatusData(newStatusData);

  }, [isHeaderInfo, isMapInfo]);

  useEffect(() => {
    if ('door_state' in mqttNavInfo) setDoorState(mqttNavInfo.door_state);
    if (mqttNavInfo.loc_conf) setLocConfidence(`${mqttNavInfo.loc_conf * 100}%`);
    if (mqttNavInfo?.nav_criteria) setNavState(mqttNavInfo.nav_criteria);
  }, [mqttNavInfo]);

  useEffect(() => {
    if (!rawRobotStatus) return;

    const updateRobotStatus = () => {
      setAutoPause(rawRobotStatus.robot_paused_auto_alert);
      setRelocStatus(rawRobotStatus.visual_localization_system?.status);
      setCurrentAisle(rawRobotStatus.current_aisle === '0' ? '-' : rawRobotStatus.current_aisle);

      if (rawRobotStatus.aisles_to_process?.length > 0) {
        setLastAisle(rawRobotStatus.aisles_to_process.slice(-1)[0]);
      } else if (rawRobotStatus.aisles_processed?.length > 0) {
        setLastAisle(rawRobotStatus.aisles_processed.slice(-1)[0]);
      } else {
        setLastAisle('-');
      }

      setBatteryStatus(rawRobotStatus.battery_status);
      setBatteryVoltage(rawRobotStatus.battery);
      setRawRobotState(rawRobotStatus.state);
    };

    updateRobotStatus();
  }, [rawRobotStatus]);

  useEffect(() => {
    if (connectionStatusMsg.program_status) {
      setRosStatus(connectionStatusMsg.program_status);
    }
  }, [connectionStatusMsg]);


  // Handlers
  const getRosStatusInfo = (status) => {
    const statusMap = {
      ready: {
        icon: <CheckCircleIcon fontSize="medium" sx={{ color: COLORS.success }} />,
        description: t('overseer_app.widget.software_ready', 'Software ready and session planned')
      },
      off: {
        icon: <CancelIcon color="error" fontSize="medium" />,
        description: t('overseer_app.widget.software_off', 'Software off')
      },
      turning_on: {
        icon: <AutorenewIcon fontSize="medium" sx={{ color: COLORS.success }} />,
        description: t('overseer_app.widget.turning_on', 'Turning on software')
      },
      turning_off: {
        icon: <AutorenewIcon color="error" fontSize="medium" />,
        description: t('overseer_app.widget.turning_off', 'Turning off software')
      }
    };

    return statusMap[status] || {
      icon: <QuestionMarkIcon fontSize="medium" />,
      description: t('overseer_app.widget.unknown', 'Unknown')
    };
  };

  const getDoorStateIcon = (state) => {
    const stateMap = {
      0: <CheckCircleIcon fontSize="medium" sx={{ color: COLORS.success }} />,
      1: <ArrowUpwardIcon fontSize="medium" sx={{ color: COLORS.success }} />,
      2: <ArrowDownwardIcon fontSize="medium" sx={{ color: COLORS.success }} />,
      3: <CancelIcon color="error" fontSize="medium" />
    };

    return stateMap[state] || <CancelIcon color="warning" fontSize="medium" />;
  };

  const getStatusIcon = (key) => {
    const iconMap = {
      reloc_status: <ExploreIcon fontSize="medium" color="info" />,
      last_aisle: <ModeOfTravelIcon fontSize="medium" color="info" />,
      current_aisle: <LocationOnIcon fontSize="medium" color="info" />,
      state: <InfoIcon fontSize="medium" color="info" />,
      nav_state: <NavigationIcon fontSize="medium" color="info" />,
      loc_conf: <MyLocationIcon fontSize="medium" color="info" />
    };

    return iconMap[key];
  };

  const getNavigationInfo = (item) => {
    let icon, title, value, description;
    const valuesMap = {
      ros_status: rosStatus,
      door_state: doorState,
      auto_pause: autoPause,
      loc_conf: locConfidence,
      nav_state: navState,
      reloc_status: relocStatus,
      current_aisle: currentAisle,
      last_aisle: lastAisle,
      state: rawRobotState
    }

    if (item.key === 'ros_status') {
      const rosInfo = getRosStatusInfo(rosStatus);
      title = 'ROS';
      icon = rosInfo.icon;
      description = rosInfo.description;
    } else if (item.key === 'door_state') {
      title = 'Door';
      icon = getDoorStateIcon(doorState);
    } else if (item.key === 'battery_status') {
      title = t(`overseer_app.widget.${item.key}`, item.name);
      value = batteryVoltage && `${batteryVoltage} V`;
      icon = <BatteryStatusIcon batteryStatus={batteryStatus} chargeStatus={rawRobotStatus?.charge_state} />;
    } else {
      title = t(`overseer_app.widget.${item.key}`, item.name);
      value = item.key && valuesMap[item.key] ?
        valuesMap[item.key].toString() +
        (item.key === 'nav_state' && !valuesMap['auto_pause'] ? ` (${t('overseer_app.widget.auto_pause')})` : '')
        : null;
      icon = getStatusIcon(item.key);
    }

    return { icon, title, value, description };
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        maxWidth: '-webkit-fill-available',
        overflowX: 'auto',
        alignItems: 'center',
        maxHeight: '3.5em',
        my: 1
      }}
    >
      {statusData.map((item, index) => {
        const statusInfo = getNavigationInfo(item);
        return (
          <Box key={`${item.name}-${index}`} sx={{ height: 'fit-content' }}>
            <StatusBox {...statusInfo} />
          </Box>
        );
      })}
    </Box>
  );
};

export default RobotStatusWidget;
