import {
  handleAvailable,
  handleRequest,
  handleUnavailable,
} from '../reducerHandlers';
import {
  ROBOT_STATUS_AVAILABLE,
  ROBOT_STATUS_REQUEST,
  ROBOT_STATUS_UNAVAILABLE,
  SNACK_SET
} from './constants';
import { createReducer } from './utils';

const defaultState = {
  robotsOverview: null,
  loadingRobotsOverview: false,
  downtimeMotives: null,
  loadingDowntimeMotives: false,
  robotStatuses: [],
  isLoadingRobotStatuses: false,
};

export const reducer = createReducer(defaultState, {
  [ROBOT_STATUS_REQUEST]: handleRequest,
  [ROBOT_STATUS_AVAILABLE]: handleAvailable,
  [ROBOT_STATUS_UNAVAILABLE]: handleUnavailable,
});

export function getRobotsOverviewData() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingRobotsOverview';
    const keyState = 'robotsOverview';
    dispatch({ type: ROBOT_STATUS_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getRobotsOverview();
      const overviewData = response ? response : [];
      dispatch({
        type: ROBOT_STATUS_AVAILABLE,
        payload: { keyState, loadingName, data: overviewData },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_STATUS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getDowntimeMotivesData() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingDowntimeMotives';
    const keyState = 'downtimeMotives';
    dispatch({ type: ROBOT_STATUS_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getDowntimeMotives();
      const overviewData = response ? response : [];
      dispatch({
        type: ROBOT_STATUS_AVAILABLE,
        payload: { keyState, loadingName, data: overviewData },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_STATUS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getRobotStatesData() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingRobotStates';
    const keyState = 'robotStates';
    dispatch({ type: ROBOT_STATUS_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getRobotStateData();
      const statesData = response ? response : [];
      dispatch({
        type: ROBOT_STATUS_AVAILABLE,
        payload: { keyState, loadingName, data: statesData },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_STATUS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getRobotDailyCountData(
  states = ['not-operational', 'under-maintenance']
) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingRobotDailyCount';
    const keyState = 'robotDailyCount';
    dispatch({ type: ROBOT_STATUS_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getRobotDailyData(
        JSON.stringify(states)
      );
      const countData = response ? response : [];
      dispatch({
        type: ROBOT_STATUS_AVAILABLE,
        payload: { keyState, loadingName, data: countData },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_STATUS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getRobotStatuses() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'robotStatuses'
    const loadingName = 'isLoadingRobotStatuses'
    dispatch({ type: ROBOT_STATUS_REQUEST, payload: { loadingName: loadingName } });
    try {
      let response = await dataSource.getRobotStatuses();
      dispatch({
        type: ROBOT_STATUS_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: ROBOT_STATUS_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the robot statuses.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
