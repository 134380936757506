export function isLoadingSession({ session: { loading } }) {
  return loading;
}

export function userInfo({ session: { userInfo } }) {
  return userInfo;
}

export function hasSession({ session: { loggedIn } }) {
  return loggedIn;
}

export function processFlow({ pipelineMonitoring: { processFlow } }) {
  return processFlow;
}

export function jobsCompleted({ pipelineMonitoring: { jobsCompleted } }) {
  return jobsCompleted;
}

export function processesData({ pipelineMonitoring: { processesData } }) {
  return processesData;
}

export function totalAisles({ pipelineMonitoring: { totalAisles } }) {
  return totalAisles;
}

export function navigationData({ pipelineMonitoring: { navigationData } }) {
  return navigationData;
}

export function loadingNavigationData({
  pipelineMonitoring: { loadingNavigationData },
}) {
  return loadingNavigationData;
}

export function objectNames({ pipelineMonitoring: { objectNames } }) {
  return objectNames;
}

export function batchesReceived({ pipelineMonitoring: { batchesReceived } }) {
  return batchesReceived;
}

export function loadingMonitoringData({
  pipelineMonitoring: {
    loadingJobsCompleted,
    loadingProcessFlow,
    loadingProcessesData,
    loadingObjectNames,
    loadingAllMetadata,
  },
}) {
  return (
    loadingJobsCompleted ||
    loadingProcessFlow ||
    loadingProcessesData ||
    loadingObjectNames ||
    loadingAllMetadata
  );
}

export function storeData({ pipelineMonitoring: { storeData } }) {
  return storeData;
}

export function getSignedBlob({ pipelineMonitoring: { signedBlob } }) {
  return signedBlob;
}

export function isLoadingSignedBlob({
  pipelineMonitoring: { loadingSignedBlob },
}) {
  return loadingSignedBlob;
}

export function getSessionProgressTableData({
  pipelineMonitoring: { sessionProgressTable },
}) {
  return sessionProgressTable;
}

export function getIsSessionProgressTableLoading({
  pipelineMonitoring: { isSessionProgressTableLoading },
}) {
  return isSessionProgressTableLoading;
}

export function getPPNodesInfo({ pipelineMonitoring: { ppNodes } }) {
  return ppNodes;
}

export function isLoadingPPNodesInfo({
  pipelineMonitoring: { isPPNodesLoading },
}) {
  return isPPNodesLoading;
}

export function detailedUserInfo({ session: { detailedUserInfo } }) {
  return detailedUserInfo;
}

export function serviceStatistics({
  serviceMonitoring: { serviceStatistics },
}) {
  return serviceStatistics;
}

export function loadingServiceStatistics({
  serviceMonitoring: { loadingServiceStatistics },
}) {
  return loadingServiceStatistics;
}

export function navigationLoading({ navigation: { loading } }) {
  return loading;
}

export function navigationOptions({ navigation: { options } }) {
  return options;
}

export function revisions({ metrics: { revisions } }) {
  return revisions;
}

export function loadingRevisions({ metrics: { loadingRevisions } }) {
  return loadingRevisions;
}

export function requestCounts({ metrics: { requestCounts } }) {
  return requestCounts;
}

export function loadingRequestCounts({ metrics: { loadingRequestCounts } }) {
  return loadingRequestCounts;
}

export function containerInstances({ metrics: { containerInstances } }) {
  return containerInstances;
}

export function loadingContainerInstances({
  metrics: { loadingContainerInstances },
}) {
  return loadingContainerInstances;
}

export function requestLatencies({ metrics: { requestLatencies } }) {
  return requestLatencies;
}

export function loadingRequestLatencies({
  metrics: { loadingRequestLatencies },
}) {
  return loadingRequestLatencies;
}

export function cpuUtilization({ metrics: { cpuUtilization } }) {
  return cpuUtilization;
}

export function loadingCpuUtilization({ metrics: { loadingCpuUtilization } }) {
  return loadingCpuUtilization;
}

export function memoryUtilization({ metrics: { memoryUtilization } }) {
  return memoryUtilization;
}

export function loadingMemoryUtilization({
  metrics: { loadingMemoryUtilization },
}) {
  return loadingMemoryUtilization;
}

export function vertexResponseCount({ metrics: { vertexResponseCount } }) {
  return vertexResponseCount;
}

export function loadingVertexResponseCount({
  metrics: { loadingVertexResponseCount },
}) {
  return loadingVertexResponseCount;
}

export function vertexPredictionCount({ metrics: { vertexPredictionCount } }) {
  return vertexPredictionCount;
}

export function loadingVertexPredictionCount({
  metrics: { loadingVertexPredictionCount },
}) {
  return loadingVertexPredictionCount;
}

export function vertexReplicasCount({ metrics: { vertexReplicasCount } }) {
  return vertexReplicasCount;
}

export function loadingVertexReplicasCount({
  metrics: { loadingVertexReplicasCount },
}) {
  return loadingVertexReplicasCount;
}

export function vertexCpuUtilization({ metrics: { vertexCpuUtilization } }) {
  return vertexCpuUtilization;
}

export function loadingVertexCpuUtilization({
  metrics: { loadingVertexCpuUtilization },
}) {
  return loadingVertexCpuUtilization;
}

export function vertexLatencies({ metrics: { vertexLatencies } }) {
  return vertexLatencies;
}

export function loadingVertexLatencies({
  metrics: { loadingVertexLatencies },
}) {
  return loadingVertexLatencies;
}

export function tasksDepth({ metrics: { tasksDepth } }) {
  return tasksDepth;
}

export function loadingTasksDepth({ metrics: { loadingTasksDepth } }) {
  return loadingTasksDepth;
}

export function tasksCount({ metrics: { tasksCount } }) {
  return tasksCount;
}

export function loadingTasksCount({ metrics: { loadingTasksCount } }) {
  return loadingTasksCount;
}

export function sessionProgress({ pipelineMonitoring: { sessionProgress } }) {
  return sessionProgress;
}

export function loadingSessionProgress({
  pipelineMonitoring: { loadingSessionProgress },
}) {
  return loadingSessionProgress;
}

export function serviceMetadata({ serviceMetadata: { serviceMetadata } }) {
  return serviceMetadata;
}

export function loadingServiceMetadata({
  serviceMetadata: { loadingServiceMetadata },
}) {
  return loadingServiceMetadata;
}

export function tasksMetadata({ serviceMetadata: { tasksMetadata } }) {
  return tasksMetadata;
}

export function loadingTasksMetadata({
  serviceMetadata: { loadingTasksMetadata },
}) {
  return loadingTasksMetadata;
}

export function tasksProgress({ pipelineMonitoring: { tasksProgress } }) {
  return tasksProgress;
}

export function loadingTasksProgress({
  pipelineMonitoring: { loadingTasksProgress },
}) {
  return loadingTasksProgress;
}

export function serviceLogs({ serviceMonitoring: { serviceLogs } }) {
  return serviceLogs;
}

export function loadingServiceLogs({
  serviceMonitoring: { loadingServiceLogs },
}) {
  return loadingServiceLogs;
}

export function alerts({ serviceMonitoring: { alerts } }) {
  return alerts;
}

export function loadingAlerts({ serviceMonitoring: { loadingAlerts } }) {
  return loadingAlerts;
}

export function allAlertPolicies({ alertPolicies: { allAlertPolicies } }) {
  return allAlertPolicies;
}

export function loadingAllAlertPolicies({
  alertPolicies: { loadingAllAlertPolicies },
}) {
  return loadingAllAlertPolicies;
}

export function sessionData({ pipelineMonitoring: { sessionData } }) {
  return sessionData;
}

export function loadingSessionData({
  pipelineMonitoring: { loadingSessionData },
}) {
  return loadingSessionData;
}

batchesByAisle;

export function batchesByAisle({ pipelineMonitoring: { batchesByAisle } }) {
  return batchesByAisle;
}

export function loadingBatchesByAisle({
  pipelineMonitoring: { loadingBatchesByAisle },
}) {
  return loadingBatchesByAisle;
}

// Backend
export function getChainsList({ backend: { chainsList } }) {
  return chainsList;
}

export function isLoadingChains({ backend: { isLoadingChains } }) {
  return isLoadingChains;
}

export function getRobotsList({ backend: { robotsList } }) {
  return robotsList;
}

export function isLoadingRobots({ backend: { isLoadingRobots } }) {
  return isLoadingRobots;
}

export function isLoadingBrokerUrl({ backend: { isLoadingBrokerUrl } }) {
  return isLoadingBrokerUrl;
}

export function getBrokerUrl({ backend: { brokerUrl } }) {
  return brokerUrl;
}

export function getStoreInfo({ backend: { storeInfo } }) {
  return storeInfo;
}

export function getIsLoadingStoreInfo({ backend: { isLoadingStoreInfo } }) {
  return isLoadingStoreInfo;
}

export function navigationStatuses({ backend: { navigationStatuses } }) {
  return navigationStatuses;
}

export function isLoadingNavigationStatuses({
  backend: { isLoadingNavigationStatuses },
}) {
  return isLoadingNavigationStatuses;
}

export function getRobotListBaldur({ fleetControl: { robotListBaldur } }) {
  return robotListBaldur;
}

export function isLoadingRobotListBaldur({
  fleetControl: { isLoadingRobotListBaldur },
}) {
  return isLoadingRobotListBaldur;
}

export function getRobotSessionsList({ scheduler: { sessionsRobotList } }) {
  return sessionsRobotList;
}

export function batchLatency({ metrics: { batchLatency } }) {
  return batchLatency;
}

export function loadingBatchLatency({ metrics: { loadingBatchLatency } }) {
  return loadingBatchLatency;
}

export function jobsLatencies({ metrics: { jobsLatencies } }) {
  return jobsLatencies;
}

export function loadingJobsLatencies({ metrics: { loadingJobsLatencies } }) {
  return loadingJobsLatencies;
}

//scheduler
export function getDeleteSessionsResponse({
  scheduler: { deleteSessionsResponse },
}) {
  return deleteSessionsResponse;
}

export function isDeletingSessions({ scheduler: { isDeletingSessions } }) {
  return isDeletingSessions;
}

export function getNewSessionsResponse({
  fleetControl: { newSessionsResponse },
}) {
  return newSessionsResponse;
}

export function isLoadingNewSessions({ fleetControl: { loadingNewSessions } }) {
  return loadingNewSessions;
}

export function getSessionUpdateResponse({
  fleetControl: { updateSessionMessageResponse },
}) {
  return updateSessionMessageResponse;
}

export function isLoadingSessionUpdate({
  fleetControl: { loadingUpdateSessions },
}) {
  return loadingUpdateSessions;
}

// SnackBar
export function getSnackAlert({ snackAlert: { snack } }) {
  return snack;
}

// Widgets
// Actions
export function isLoadingActionHistory({
  actionWidget: { isActionHistoryLoading },
}) {
  return isActionHistoryLoading;
}

export function getActionHistory({ actionWidget: { actionsHistory } }) {
  return actionsHistory;
}

export function isStoreMapInfoLoading({
  actionWidget: { isStoreMapInfoLoading },
}) {
  return isStoreMapInfoLoading;
}

export function getStoreMapInfo({ actionWidget: { storeMapInfo } }) {
  return storeMapInfo;
}

// Alerts Widget
export function historicAlerts({ alertsWidget: { historicAlerts } }) {
  return historicAlerts;
}

export function loadingHistoricAlerts({
  alertsWidget: { loadingHistoricAlerts },
}) {
  return loadingHistoricAlerts;
}

export function activeAlerts({ alertsWidget: { activeAlerts } }) {
  return activeAlerts;
}

export function loadingActiveAlerts({ alertsWidget: { loadingActiveAlerts } }) {
  return loadingActiveAlerts;
}

export function hardwareComponents({
  hardwareComponentsWidget: { hardwareComponents },
}) {
  return hardwareComponents;
}

export function loadingHardwareComponents({
  hardwareComponentsWidget: { loadingHardwareComponents },
}) {
  return loadingHardwareComponents;
}

export function getRobotActionsList({ robotMonitoring: { robotActions } }) {
  return robotActions;
}

export function isLoadingRobotActions({
  robotMonitoring: { loadingRobotActions },
}) {
  return loadingRobotActions;
}

export function getNewUserActionResponse({
  robotMonitoring: { newUserActionsResponse },
}) {
  return newUserActionsResponse;
}

export function getRobotsTakenList({ robotMonitoring: { robotsTaken } }) {
  return robotsTaken;
}

export function getReasonOptions({ robotMonitoring: { actionReasons } }) {
  return actionReasons;
}

export function isLoadingActionReasons({
  robotMonitoring: { isLoadingActionReasons },
}) {
  return isLoadingActionReasons;
}

export function isLoadingRobotsTaken({
  robotMonitoring: { loadingRobotsTaken },
}) {
  return loadingRobotsTaken;
}

export function getTakeRobotResponse({
  robotMonitoring: { takeRobotResponse },
}) {
  return takeRobotResponse;
}

export function isLoadingTakeRobot({ robotMonitoring: { loadingTakeRobot } }) {
  return loadingTakeRobot;
}

export function getLeaveRobotResponse({
  robotMonitoring: { leaveRobotResponse },
}) {
  return leaveRobotResponse;
}

export function isLoadingLeaveRobot({
  robotMonitoring: { loadingLeaveRobot },
}) {
  return loadingLeaveRobot;
}

export function getRobotBlockingList({ robotMonitoring: { robotBlocking } }) {
  return robotBlocking;
}

export function isLoadingRobotBlocking({
  robotMonitoring: { loadingRobotBlocking },
}) {
  return loadingRobotBlocking;
}

export function defaultHardwareComponents({
  hardwareComponentsWidget: { defaultHardwareComponents },
}) {
  return defaultHardwareComponents;
}

export function loadingDefaultHardwareComponents({
  hardwareComponentsWidget: { loadingDefaultHardwareComponents },
}) {
  return loadingDefaultHardwareComponents;
}

export function servicingHistory({
  servicingHistoryWidget: { servicingHistory },
}) {
  return servicingHistory;
}

export function loadingServicingHistory({
  servicingHistoryWidget: { loadingServicingHistory },
}) {
  return loadingServicingHistory;
}

export function changeMotives({ servicingHistoryWidget: { changeMotives } }) {
  return changeMotives;
}

export function loadingChangeMotives({
  servicingHistoryWidget: { loadingChangeMotives },
}) {
  return loadingChangeMotives;
}

export function loadingPatchChangeReasons({
  servicingHistoryWidget: { loadingPatchChangeReasons },
}) {
  return loadingPatchChangeReasons;
}

export function patchChangeReasonsResponse({
  servicingHistoryWidget: { patchChangeReasonsResponse },
}) {
  return patchChangeReasonsResponse;
}

export function getStoredFootageBucket({ cameraWidgets: { footageBucket } }) {
  return footageBucket;
}

export function getStoredSpeedOptions({ cameraWidgets: { speedOptions } }) {
  return speedOptions;
}

export function getStoredResolutionOptions({
  cameraWidgets: { resolutionOptions },
}) {
  return resolutionOptions;
}

export function isSpeedOptionsLoading({
  cameraWidgets: { isSpeedOptionsLoading },
}) {
  return isSpeedOptionsLoading;
}

export function isResolutionOptionsLoading({
  cameraWidgets: { isResolutionOptionsLoading },
}) {
  return isResolutionOptionsLoading;
}

export function isFootageBucketLoading({
  cameraWidgets: { isFootageBucketLoading },
}) {
  return isFootageBucketLoading;
}

export function getNewServicingResponse({
  robotServicing: { newServicingResponse },
}) {
  return newServicingResponse;
}

export function isLoadingNewServicing({
  robotServicing: { loadingNewServicing },
}) {
  return loadingNewServicing;
}

export function getChangeMotivesList({ robotServicing: { changeMotives } }) {
  return changeMotives;
}

export function isLoadingChangeMotives({
  robotServicing: { loadingNewServicing },
}) {
  return loadingNewServicing;
}

export function getRobotComponentsList({
  robotServicing: { robotComponents },
}) {
  return robotComponents;
}

export function isLoadingRobotComponents({
  robotServicing: { loadingRobotComponents },
}) {
  return loadingRobotComponents;
}

// Group
export function getGroupData({ group: { groupData } }) {
  return groupData;
}

export function isLoadingGroupData({ group: { loading_groupData } }) {
  return loading_groupData;
}

// Alerts Manager
export function getAlertsCreated({ alerts: { alertsCreated } }) {
  return alertsCreated;
}

export function getAvailableAlerts({ alerts: { availableAlerts } }) {
  return availableAlerts;
}

export function getGroupAlerts({ alerts: { groupAlerts } }) {
  return groupAlerts;
}

export function isLoadingAlertsCreated({ alerts: { isLoadingAlertsCreated } }) {
  return isLoadingAlertsCreated;
}

export function isLoadingGroupAlerts({ alerts: { isLoadingGroupAlerts } }) {
  return isLoadingGroupAlerts;
}

export function getProductsList({ robotMonitoring: { productsList } }) {
  return productsList;
}

export function isLoadingProductsList({
  robotMonitoring: { loadingProductsByName },
}) {
  return loadingProductsByName;
}

export function getIsLoadingRobotStatusHistory({
  robotMonitoring: { isLoadingRobotStatusHistory },
}) {
  return isLoadingRobotStatusHistory;
}

export function getRobotStatusHistoryInfo({
  robotMonitoring: { robotStatusHistory },
}) {
  return robotStatusHistory;
}

// Cloud Logging Interface
export function getCLIData({ cli: { cliData } }) {
  return cliData;
}

export function isLoadingCLIData({ cli: { loading_cliData } }) {
  return loading_cliData;
}
// Chart Data Alerts

export function getChartDataSelector({ alerts: { chartData } }) {
  return chartData;
}

export function isLoadingChartDataSelector({ alerts: { isLoadingChartData } }) {
  return isLoadingChartData;
}

// Cloud Monitoring Interface

export function getCMIPolicy({ alerts: { cmiData } }) {
  return cmiData;
}

export function isLoadingCMIData({ alerts: { isLoadingCMIData } }) {
  return isLoadingCMIData;
}

// Robot Status Configuration
export function robotsStatus({ robotConfiguration: { robotsStatus } }) {
  return robotsStatus;
}

export function loadingRobotsStatus({
  robotConfiguration: { loadingRobotsStatus },
}) {
  return loadingRobotsStatus;
}

export function updatingRobotStatus({
  robotConfiguration: { updatingRobotStatus },
}) {
  return updatingRobotStatus;
}

export function workshiftInfo({ session: { workshiftInfo } }) {
  return workshiftInfo;
}

export function getWorkshiftResponse({ session: { workshiftResponse } }) {
  return workshiftResponse;
}

export function isloadingPostWorkshift({ session: { loadingPostWorkshift } }) {
  return loadingPostWorkshift;
}

export function isLoadingSessionsState({
  session: { isLoadingSessionsState },
}) {
  return isLoadingSessionsState;
}

export function getLocalSessionsState({ session: { sessionsState } }) {
  return sessionsState;
}

export function getEventsList({ events: { eventsList } }) {
  return eventsList;
}

export function isLoadingEventsList({ events: { isLoadingEvents } }) {
  return isLoadingEvents;
}

export function updatingEventStatus({ events: { eventStatus } }) {
  return eventStatus;
}

export function isLoadingUpdateEventStatus({
  events: { updatingEventStatus },
}) {
  return updatingEventStatus;
}

export function getActiveEventsList({ events: { activeEventsList } }) {
  return activeEventsList;
}

export function getEventsStatsList({ events: { eventsStats } }) {
  return eventsStats;
}

export function isLoadingEventsStats({ events: { isLoadingEventsStats } }) {
  return isLoadingEventsStats;
}

export function getClosedEventsList({ events: { closedEventsList } }) {
  return closedEventsList;
}

export function getFeaturePermission({ backend: { featurePermission } }) {
  return featurePermission;
}

export function getHeatmapSignal({ robotMonitoring: { mapSignal } }) {
  return mapSignal;
}

export function getHeatMapMappingAlerts({
  robotMonitoring: { mappingAlerts },
}) {
  return mappingAlerts;
}

export function getHeatMapMappingAlertsLoading({
  robotMonitoring: { isLoadingMappingAlerts },
}) {
  return isLoadingMappingAlerts;
}

export function getIsLoadingMapSignalInfo({
  robotMonitoring: { isLoadingMapSignal },
}) {
  return isLoadingMapSignal;
}

export function getTimelineTraceInfo({ timelineTrace: { timelineTrace } }) {
  return timelineTrace;
}

export function isLoadingTimelineTrace({
  timelineTrace: { isLoadingTimeline },
}) {
  return isLoadingTimeline;
}

// Dashboard Metrics
export function getSpecificMetricInfo({
  dashboardMetrics: { specificMetricData },
}) {
  return specificMetricData;
}

export function isLoadingMetricData({
  dashboardMetrics: { isLoadingMetricData },
}) {
  return isLoadingMetricData;
}

export function getMetricsOptionsList({
  dashboardMetrics: { metricOptionsList },
}) {
  return metricOptionsList;
}

export function isLoadingMetricsOptions({
  dashboardMetrics: { isLoadingMetricsOptions },
}) {
  return isLoadingMetricsOptions;
}

export function getRealTimeData({ dashboardMetrics: { realTimeData } }) {
  return realTimeData;
}

export function getMonitorMetricsData({
  dashboardMetrics: { monitorMetricsData },
}) {
  return monitorMetricsData;
}

export function isLoadingMonitorMetricsData({
  dashboardMetrics: { isLoadingMonitorMetricsData },
}) {
  return isLoadingMonitorMetricsData;
}

export function isLoadingNavigationStats({
  dashboardMetrics: { isLoadingNavigationStats },
}) {
  return isLoadingNavigationStats;
}

export function navigationStats({ dashboardMetrics: { navigationStats } }) {
  return navigationStats;
}

export function getNewClickUpTaskResponse({
  events: { newClickUpTaskResponse },
}) {
  return newClickUpTaskResponse;
}

export function isLoadingNewClickUpTask({ events: { loadingNewClickUpTask } }) {
  return loadingNewClickUpTask;
}

export function getClickupTeamsResponse({ events: { clickUpTeamsList } }) {
  return clickUpTeamsList;
}

export function getAvailableStatusData({
  robotMonitoring: { availableStatus },
}) {
  return availableStatus;
}

export function isLoadingAvailableStatusData({
  robotMonitoring: { isLoadingAvailableStatus },
}) {
  return isLoadingAvailableStatus;
}

export function getRobotStateData({ robotMonitoring: { robotState } }) {
  return robotState;
}

export function isLoadingRobotStateData({
  robotMonitoring: { isLoadingRobotState },
}) {
  return isLoadingRobotState;
}

export function getRobotStateUpdateResponse({
  robotMonitoring: { newRobotStateResponse },
}) {
  return newRobotStateResponse;
}

export function isLoadingRobotStateUpdate({
  robotMonitoring: { loadingNewRobotState },
}) {
  return loadingNewRobotState;
}

export function getRobotStateHistoryData({
  robotMonitoring: { robotStateHistory },
}) {
  return robotStateHistory;
}

export function isLoadingRobotStateHistoryData({
  robotMonitoring: { isLoadingRobotStateHistory },
}) {
  return isLoadingRobotStateHistory;
}

export function getAvailableRobotStateMotives({
  robotMonitoring: { robotStateMotives },
}) {
  return robotStateMotives;
}

export function isLoadingRobotStateMotives({
  robotMonitoring: { isLoadingRobotStateMotives },
}) {
  return isLoadingRobotStateMotives;
}

export function getActiveHardwareAlertsList({
  hardwareAlertsWidget: { activeHardwareAlerts },
}) {
  return activeHardwareAlerts;
}

export function isLoadingHardwareAlertsList({
  hardwareAlertsWidget: { isLoadingHardwareAlerts },
}) {
  return isLoadingHardwareAlerts;
}

export function getHistoricHardwareAlertsList({
  hardwareAlertsWidget: { historicHardwareAlerts },
}) {
  return historicHardwareAlerts;
}

export function isLoadingHistoricHardwareAlertsList({
  hardwareAlertsWidget: { isLoadingHistoricHardwareAlerts },
}) {
  return isLoadingHistoricHardwareAlerts;
}

// Layouts
export function getLocalRobotLayout({ layouts: { robotLayout } }) {
  return robotLayout;
}

export function isLoadingRobotLayout({ layouts: { isLoadingRobotLayout } }) {
  return isLoadingRobotLayout;
}

export function getLocalBucketMapLayout({ layouts: { bucketMapLayout } }) {
  return bucketMapLayout;
}

export function isLoadingBucketMapLayout({
  layouts: { isLoadingBucketMapLayout },
}) {
  return isLoadingBucketMapLayout;
}

// Baldur
export function getScheduleSessions({ baldurScheduler: { sessionsTable } }) {
  return sessionsTable;
}

export function isLoadingSessionHistory({
  baldurScheduler: { isLoadingSessionHistory },
}) {
  return isLoadingSessionHistory;
}

export function getSessionHistoryList({ baldurScheduler: { sessionHistory } }) {
  return sessionHistory;
}

export function getRobotsOverviewList({ robotStatus: { robotsOverview } }) {
  return robotsOverview;
}

export function isLoadingRobotsOverview({
  robotStatus: { loadingRobotsOverview },
}) {
  return loadingRobotsOverview;
}

export function getDowntimeMotivesList({ robotStatus: { downtimeMotives } }) {
  return downtimeMotives;
}

export function isLoadingDowntimeMotives({
  robotStatus: { loadingDowntimeMotives },
}) {
  return loadingDowntimeMotives;
}

export function getRobotStatesList({ robotStatus: { robotStates } }) {
  return robotStates;
}

export function isLoadingRobotStates({ robotStatus: { loadingRobotStates } }) {
  return loadingRobotStates;
}

export function getRobotDailyCountList({ robotStatus: { robotDailyCount } }) {
  return robotDailyCount;
}

export function isLoadingRobotDailyCount({
  robotStatus: { loadingRobotDailyCount },
}) {
  return loadingRobotDailyCount;
}

export function robotStatuses({ robotStatus: { robotStatuses } }) {
  return robotStatuses;
}

export function isLoadingRobotStatuses({ robotStatus: { isLoadingRobotStatuses } }) {
  return isLoadingRobotStatuses;
}

export function isLoadingRobotSessions({
  baldurScheduler: { isLoadingSessions },
}) {
  return isLoadingSessions;
}

export function getNewSessionResponse({
  baldurScheduler: { newSessionResponse },
}) {
  return newSessionResponse;
}

export function loadingNewSessionResponse({
  baldurScheduler: { loadingNewSessionResponse },
}) {
  return loadingNewSessionResponse;
}

export function loadingCancelSessionResponse({
  baldurScheduler: { loadingCancelSessionResponse },
}) {
  return loadingCancelSessionResponse;
}

export function getCancelMessage({ baldurScheduler: { cancelMessage } }) {
  return cancelMessage;
}

// Baldur - Parameters

export function parameters({ baldurParameters: { parameters } }) {
  return parameters;
}

export function isLoadingParameters({
  baldurParameters: { isLoadingParameters },
}) {
  return isLoadingParameters;
}

// Baldur - Robot Parameters

export function robotParameters({ baldurParameters: { robotParameters } }) {
  return robotParameters;
}

export function isLoadingRobotParameters({
  baldurParameters: { isLoadingRobotParameters },
}) {
  return isLoadingRobotParameters;
}

export function robotParamsPostResponse({
  baldurParameters: { robotParamsPostResponse },
}) {
  return robotParamsPostResponse;
}

export function isLoadingRobotParamsPost({
  baldurParameters: { isLoadingRobotParamsPost },
}) {
  return isLoadingRobotParamsPost;
}

export function groupedRobotParameters({
  baldurParameters: { groupedRobotParameters },
}) {
  return groupedRobotParameters;
}

export function isLoadingGroupedRobotParameters({
  baldurParameters: { isLoadingGroupedRobotParameters },
}) {
  return isLoadingGroupedRobotParameters;
}

// Caller
export function getCallerHistoryList({ robotMonitoring: { callerHistory } }) {
  return callerHistory;
}

export function loadingCallerHistory({
  robotMonitoring: { isLoadingCallerHistory },
}) {
  return isLoadingCallerHistory;
}

export function getNewCallerActionResponse({
  robotMonitoring: { postCallertActionResponse },
}) {
  return postCallertActionResponse;
}

export function loadingNewCallerActionResponse({
  robotMonitoring: { isLoadingPostCallerActionResponse },
}) {
  return isLoadingPostCallerActionResponse;
}

export function getStoreCountryResponse({ backend: { storeCountry } }) {
  return storeCountry;
}

export function loadingStoreCountry({ backend: { isLoadingStoreCountry } }) {
  return isLoadingStoreCountry;
}

// Store
export function storeContacts({ store: { storeContacts } }) {
  return storeContacts;
}

export function isLoadingStoreContacts({ store: { isLoadingStoreContacts } }) {
  return isLoadingStoreContacts;
}

export function putResponse({ store: { putResponse } }) {
  return putResponse;
}

export function isLoadingStoreContactsPut({
  store: { isLoadingStoreContactsPut },
}) {
  return isLoadingStoreContactsPut;
}

export function getCallMotivesResponse({ robotMonitoring: { callMotives } }) {
  return callMotives;
}

export function loadingCallMotives({
  robotMonitoring: { isLoadingCallMotives },
}) {
  return isLoadingCallMotives;
}

export function storesUtcOffset({ store: { storesUtcOffset } }) {
  return storesUtcOffset;
}

export function isLoadingStoresUtcOffset({
  store: { isLoadingStoresUtcOffset },
}) {
  return isLoadingStoresUtcOffset;
}

// Store configs
export function storeConfigs({ storeManagement: { storeConfigs } }) {
  return storeConfigs;
}

export function isLoadingStoreConfigs({
  storeManagement: { isLoadingStoreConfigs },
}) {
  return isLoadingStoreConfigs;
}

export function getNewStoreConfigResponse({
  storeManagement: { newStoreConfigResponse },
}) {
  return newStoreConfigResponse;
}

export function loadingNewStoreConfigResponse({
  storeManagement: { isLoadingNewStoreConfig },
}) {
  return isLoadingNewStoreConfig;
}

export function getStoreUpdateResponse({
  storeManagement: { storeConfigUpdateResponse },
}) {
  return storeConfigUpdateResponse;
}

export function isLoadingStoreUpdate({
  storeManagement: { updatingStoreConfig },
}) {
  return updatingStoreConfig;
}

export function getDeleteStoreResponse({
  storeManagement: { deleteStoreResponse },
}) {
  return deleteStoreResponse;
}

export function isDeletingStore({ storeManagement: { isDeletingStore } }) {
  return isDeletingStore;
}

export function countriesList({ storeManagement: { countries } }) {
  return countries;
}

export function isLoadingCountries({
  storeManagement: { isLoadingCountries },
}) {
  return isLoadingCountries;
}

export function isLoadingChainsColor({
  storeManagement: { isLoadingChainsColor },
}) {
  return isLoadingChainsColor;
}

export function gmapsApiKey({ storeManagement: { gmapsApiKey } }) {
  return gmapsApiKey;
}

// Links
export function linkReceived({ link: { linkReceived } }) {
  return linkReceived;
}

export function getLocalAvailableStores({ layouts: { availableStores } }) {
  return availableStores;
}

export function isLoadingAvailableStores({ layouts: { isLoadingAvailableStores } }) {
  return isLoadingAvailableStores;
}

export function getLocalAvailableFloors({ layouts: { availableStoreFloors } }) {
  return availableStoreFloors;
}

export function isLoadingAvailableFloors({ layouts: { isLoadingAvailableStoreFloors } }) {
  return isLoadingAvailableStoreFloors;
}
