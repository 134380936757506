import { Box, Card, Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DashboardViewContext } from '../../context/DashboardView';
import { a11yProps, checkPermision } from '../Utils';
import EventsTable from '../events/EventsTable';
import BarListChart from '../metrics/BarListChart';
import CategoryBarChart from '../metrics/CategoryBarChart';
import Counter from '../metrics/Counter';
import CustomBarChart from '../metrics/CustomBarChart';
import DowntimeChart from '../metrics/DowntimeChart';
import LinearChart from '../metrics/LinearChart';
import MetricSelector from '../metrics/MetricSelector';
import MonitorCharts from '../metrics/MonitorCharts';
import MonitorMetricSelector from '../metrics/MonitorMetricSelector';
import PieChart from '../metrics/PieChart';
import StatusTable from '../metrics/StatusTable';
import UserTable from '../metrics/UserTable';
import NotAllowedView from '../tools/NotAllowed';
import TabPanel from '../tools/TabPanel';
import { convertToUserTimezone, getUserTimezone } from '../tools/utils';
import MonitoringSummary from './MonitoringSummary';
import AlertsEventTable from '../events/AlertsEventTable';

export default function DashboardView(props) {
  const {
    chainsList,
    getChains,
    getMetricsOptions,
    metricsOptions,
    getSpecificMetric,
    specificMetricData,
    isLoadingMetricData,
    navOptions,
    realTimeData,
    getRealTimeMetrics,
    getEvents,
    getEventsStats,
    activeEventsList,
    closedEventsList,
    eventsStats,
    isLoadingEventsStats,
    checkFeaturePermission,
    featurePermissionResponse,
    monitorMetricsData,
    getMonitorMetrics,
    isLoadingMonitorMetricsData,
    getNavigationStats,
    isLoadingNavigationStats,
    navigationStats,
    getNavigationStatus,
    navigationStatuses,
    isLoadingNavigationStatuses,
    setSnackAlert,
    getRobotsOverviewData,
    robotsOverviewList,
    getDowntimeMotivesData,
    downtimeMotivesList,
    getRobotStatesData,
    robotStatesData,
    getRobotDailyCountData,
    robotCountData,
    isLoadingRobotDailyCount,
  } = props;

  const {
    selectedTimeRange,
    selectedMetric,
    selectedChain,
    setSelectedTimeRange,
    setSelectedMetric,
  } = useContext(DashboardViewContext);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const viewId = 'Metrics-Dashboard';

  const GET_ALL_EVENTS_FEATURE = 'get_all_events';
  const GET_PERSONAL_EVENTS_FEATURE = 'get_personal_events';
  const GET_ALL_METRICS = 'get_all_metrics';
  const GET_PERSONAL_METRICS = 'get_personal_metrics';
  const GET_ALL_TIER_EVENT = 'get_all_tier_event';

  const timeRanges = [
    { name: '1 day', value: 24 },
    { name: '3 days', value: 72 },
    { name: '5 days', value: 120 },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const [tabEventIndex, setTabEventIndex] = useState(0);
  const [mainCategory, setMainCategory] = useState(null);
  const [unitMeasurement, setUnitMeasurement] = useState('seconds');
  const [assignationCategories, setAssignationCategories] = useState([]);
  const [pieCategories, setPieCategories] = useState([]);
  const [linearData, setLinearData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [barListData, setBarListData] = useState([]);
  const [linearTitle, setLinearTitle] = useState(null);
  const [pieTitle, setPieTitle] = useState(null);
  const [barListTitle, setBarListTitle] = useState(null);
  const [activeEventsInfo, setActiveEventsInfo] = useState([]);
  const [closedEventsInfo, setClosedEventsInfo] = useState([]);
  const [selectedMonitor, setSelectedMonitor] = useState(null);
  const [selectedTimeRangeMonitor, setSelectedTimeRangeMonitor] =
    useState(null);
  const [selectedMetricMonitor, setSelectedMetricMonitor] = useState(null);
  const [onlyPersonalMetrics, setOnlyPersonalMetrics] = useState(false);
  // Monitoring Summary states
  const [date, setDate] = useState(null);
  const [navigationStatus, setNavigationStatus] = useState([
    { status: 'aborted', name: 'Aborted' },
    { status: 'cancelled', name: 'Cancelled' },
  ]);
  const [dailyCategories, setDailyCategories] = useState([
    'Under Maintenance',
    'Not Operational',
  ]);

  useEffect(() => {
    setSelectedTimeRange(timeRanges[0].value);
    setSelectedTimeRangeMonitor(timeRanges[0].value);
    checkFeaturePermission(GET_ALL_METRICS);
    checkFeaturePermission(GET_ALL_EVENTS_FEATURE);
    checkFeaturePermission(GET_PERSONAL_EVENTS_FEATURE);
    checkFeaturePermission(GET_PERSONAL_METRICS);
    checkFeaturePermission(GET_ALL_TIER_EVENT);
    getNavigationStatus('monitoring_summary');
    getRobotsOverviewData();
    getDowntimeMotivesData();
    getRobotStatesData();
    getRobotDailyCountData();
    getEventsStats();
  }, []);

  useEffect(() => {
    if (metricsOptions) setSelectedMetric(metricsOptions[0].name);
  }, [metricsOptions]);

  useEffect(() => {
    if (featurePermissionResponse[GET_ALL_METRICS]) {
      setTabIndex(0);
      getChains();
      getMetricsOptions();
      getRealTimeMetrics();
      if (!isLoadingMonitorMetricsData) getMonitorMetrics();
    } else if (featurePermissionResponse[GET_PERSONAL_METRICS]) {
      setOnlyPersonalMetrics(true);
      if (!isLoadingMonitorMetricsData) getMonitorMetrics(24, true);
      setTabIndex(1);
    }
    if (featurePermissionResponse[GET_ALL_EVENTS_FEATURE]) {
      getEventsStats();
      getRealTimeMetrics();
      getEvents(false, 12); // events closed 12 hours range
      const timer = setInterval(() => {
        getRealTimeMetrics();
        getEventsStats();
        getEvents(false, 12); // events closed 12 hours range
      }, 60000);
      return () => {
        clearInterval(timer);
      };
    } else if (featurePermissionResponse[GET_PERSONAL_EVENTS_FEATURE]) {
      getEvents(true, 0, true); // only events assigned to the user
      getEvents(false, 12, true); // closed events assigned to the user
      const timer = setInterval(() => {
        getEvents(true, 0, true); // only events assigned to the user
        getEvents(false, 12, true); // closed events assigned to the user
      }, 60000);
      return () => {
        clearInterval(timer);
      };
    } else if (featurePermissionResponse[GET_ALL_TIER_EVENT]) {
      getEvents(true, 0); // active events by tier
      getEvents(false, 12); // events closed 12 hours range
      const timer = setInterval(() => {
        getEvents(true, 0); // active events by tier
        getEvents(false, 12); // events closed 12 hours range
      }, 60000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [featurePermissionResponse]);

  useEffect(() => {
    const updatedData = updateToUserTimezone(activeEventsList, false);
    setActiveEventsInfo(updatedData);
  }, [activeEventsList]);

  useEffect(() => {
    const updatedData = updateToUserTimezone(closedEventsList, true);
    setClosedEventsInfo(updatedData);
  }, [closedEventsList]);

  useEffect(() => {
    if (selectedMetric !== null && selectedTimeRange !== null) {
      let payloadMetric = {
        metric: selectedMetric,
        time_range: selectedTimeRange,
        percentiles: JSON.stringify(['50th', '99th']),
      };
      if (selectedChain) payloadMetric['chain'] = selectedChain;
      getSpecificMetric(payloadMetric);
    }
  }, [selectedMetric, selectedTimeRange, selectedChain]);

  useEffect(() => {
    if (Object.keys(specificMetricData).length) {
      const avgTimeGeneral = specificMetricData['general']['data'];
      const avgTimeByChain = specificMetricData['by_chain']['data'];
      const avgTimeByRobot = specificMetricData['by_robot']['data'];
      setLinearTitle(specificMetricData['general']['detail']);
      setPieTitle(specificMetricData['by_chain']['detail']);
      setBarListTitle(specificMetricData['by_robot']['detail']);
      setMainCategory(specificMetricData['category']);
      setUnitMeasurement(specificMetricData['unit_of_measurement']);
      setAssignationCategories(
        Object.keys(avgTimeGeneral[0]).filter((k) => k !== 'start_time')
      );
      setPieCategories(avgTimeByChain.map((item) => item.chain));
      setLinearData(avgTimeGeneral);
      setPieData(avgTimeByChain);
      setBarListData(avgTimeByRobot);
    }
  }, [specificMetricData]);

  useEffect(() => {
    if (date && navigationStatus) {
      const day = date.getDate();
      const month = date.getMonth() + 1; // The month starts at 0
      const year = date.getFullYear();
      const formatedDate = year + '-' + month + '-' + day;
      if (navigationStatus.length > 0) {
        getNavigationStats(
          formatedDate,
          navigationStatus.map((item) => item.status)
        );
      }
    }
  }, [date, navigationStatus]);

  const checkPrimaryView = () => {
    if (navOptions) {
      const primaryView = navOptions.find((element) => element.is_primary_view);
      return primaryView?.path;
    }
  };

  const navStatusOptionFormatter = (options) => {
    if (options.length === 0) {
      setSnackAlert({
        open: true,
        message: t(
          'overseer_app.metrics.min_one_option',
          'At least one option must be selected'
        ),
        severity: 'info',
      });
    } else {
      setNavigationStatus(options);
    }
  };

  const valueFormatter = function (number) {
    return `${Intl.NumberFormat('us').format(number).toString()} ${
      unitMeasurement[0]
    }`;
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleTabEventsChange = (event, newValue) => {
    setTabEventIndex(newValue);
  };

  const getElapsedTime = (
    firstTimestamp,
    secondTimestamp = null,
    closed = false
  ) => {
    let elapsedTime;
    if (closed) {
      let firstAction = new Date(firstTimestamp);
      let suppressedTime = new Date(secondTimestamp);
      elapsedTime = suppressedTime - firstAction;
    } else {
      if (firstTimestamp) {
        let assignedAt = new Date(firstTimestamp);
        let currentTime = new Date();
        elapsedTime = currentTime - assignedAt;
      } else return 'Has not been assigned';
    }
    const minutes = Math.floor(elapsedTime / 1000 / 60);
    return `${minutes} minutes`;
  };

  const updateToUserTimezone = (robotEvents, closed) => {
    return robotEvents.map((event) => {
      const updatedAlerts = event.alerts
        ? event.alerts.map((alert) => {
            return {
              ...alert,
              created_at: convertToUserTimezone(
                alert.created_at,
                getUserTimezone()
              ),
              supressed_at: convertToUserTimezone(
                alert.supressed_at,
                getUserTimezone()
              ),
            };
          })
        : null;
      let suppress = convertToUserTimezone(
        event.suppressed_at,
        getUserTimezone()
      );
      let firstAction = convertToUserTimezone(
        event.first_action_at,
        getUserTimezone()
      );
      let assignedAt = convertToUserTimezone(
        event.assigned_at,
        getUserTimezone()
      );
      let firstTimestamp = assignedAt ? assignedAt : firstAction;
      return {
        ...event,
        alerts: updatedAlerts,
        created_at: convertToUserTimezone(event.created_at, getUserTimezone()),
        assigned_at: assignedAt,
        first_action_at: firstAction,
        suppressed_at: suppress,
        elapsed_time: getElapsedTime(firstTimestamp, suppress, closed),
      };
    });
  };

  return (
    <>
      {navOptions.length > 0 ? (
        <>
          {checkPermision(navOptions, viewId) ? (
            <>
              <Card sx={{ minHeight: 430, marginTop: '1rem' }}>
                {isLoadingNavigationStats && (
                  <LinearProgress
                    sx={{ width: '100%', mx: 'auto' }}
                    color="secondary"
                  />
                )}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={tabEventIndex}
                    onChange={handleTabEventsChange}
                    aria-label="basic tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      wrapped
                      label={t(
                        'overseer_app.metrics.fleet_status',
                        'Fleet status'
                      )}
                      {...a11yProps(0, { textTransform: 'initial' })}
                    />
                    <Tab
                      wrapped
                      label={t('overseer_app.metrics.historical', 'Historical')}
                      {...a11yProps(1, { textTransform: 'initial' })}
                    />
                    <Tab
                      wrapped
                      label={t(
                        'overseer_app.metrics.Monitoring_summary',
                        'Monitoring Summary'
                      )}
                      {...a11yProps(2, { textTransform: 'initial' })}
                    />
                    <Tab
                      wrapped
                      label={t('overseer_app.metrics.Downtime', 'Downtime')}
                      {...a11yProps(3, { textTransform: 'initial' })}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={tabEventIndex} index={0} sx={{ p: 2 }}>
                  {featurePermissionResponse[GET_ALL_EVENTS_FEATURE] && (
                    <Grid container spacing={1} alignItems={'center'}>
                      <Grid item xs={12}>
                        <AlertsEventTable
                          realTimeData={realTimeData}
                          eventsStats={eventsStats}
                          isLoadingEventsStats={isLoadingEventsStats}
                        />
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
                <TabPanel value={tabEventIndex} index={1} sx={{ p: 2 }}>
                  <Grid container spacing={1} alignItems={'center'}>
                    {featurePermissionResponse[GET_ALL_EVENTS_FEATURE] && (
                      <Grid item xs={2}>
                        <Counter data={realTimeData} />
                      </Grid>
                    )}
                    <Grid item xs={10}>
                      <EventsTable eventsInfo={closedEventsInfo} />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabEventIndex} index={2} sx={{ p: 2 }}>
                  <MonitoringSummary
                    navigationStats={navigationStats}
                    date={date}
                    setDate={setDate}
                    navigationStatus={navigationStatus}
                    navStatusOptionFormatter={navStatusOptionFormatter}
                    navigationStatuses={navigationStatuses}
                    isLoadingNavigationStatuses={isLoadingNavigationStatuses}
                  />
                </TabPanel>
                <TabPanel value={tabEventIndex} index={3} sx={{ p: 2 }}>
                  {isLoadingNavigationStats && (
                    <LinearProgress
                      sx={{ width: '100%', mx: 'auto' }}
                      color="secondary"
                    />
                  )}
                  <Grid container>
                    <CategoryBarChart
                      size={4}
                      title={'Status overview'}
                      categoryData={robotsOverviewList}
                    />
                    <StatusTable size={8} statusData={robotStatesData} />
                    <DowntimeChart
                      size={4}
                      title={'Historical most common downtime issues'}
                      downtimeData={downtimeMotivesList}
                      inputIndex={'motive'}
                      inputCategories={['count']}
                    />
                    <CustomBarChart
                      title={'Daily robot count'}
                      size={8}
                      chartData={
                        robotCountData?.map((item) => {
                          const newItem = {};
                          Object.keys(item).forEach((key) => {
                            if (key !== 'timestamp') {
                              const newKey = t(
                                `overseer_app.general.${key}`,
                                key
                              );
                              newItem[newKey] = item[key];
                            }
                          });
                          return { ...newItem };
                        }) ?? []
                      }
                      inputCategories={dailyCategories}
                      inputIndex={'timestamp'}
                    />
                  </Grid>
                </TabPanel>
              </Card>
              <Card sx={{ minHeight: 430, marginTop: '1rem' }}>
                {navOptions.length === 0 ? (
                  <LinearProgress
                    sx={{ width: '100%', mx: 'auto' }}
                    color="secondary"
                  />
                ) : (
                  <>
                    {checkPermision(navOptions, viewId) ? (
                      <>
                        {isLoadingMetricData && (
                          <LinearProgress
                            sx={{ width: '100%', mx: 'auto' }}
                            color="secondary"
                          />
                        )}
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs
                            value={tabIndex}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons="auto"
                          >
                            <Tab
                              wrapped
                              label={t(
                                'overseer_app.metrics.metrics',
                                'Metrics'
                              )}
                              {...a11yProps(0, { textTransform: 'initial' })}
                              disabled={
                                !featurePermissionResponse[GET_ALL_METRICS]
                              }
                            />
                            <Tab
                              wrapped
                              label={t(
                                'overseer_app.metrics.monitors',
                                'Monitors'
                              )}
                              {...a11yProps(1, { textTransform: 'initial' })}
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
                          <Grid container spacing={1} alignItems={'center'}>
                            <Grid
                              container
                              item
                              spacing={2}
                              xs={12}
                              sx={{ marginTop: '0.2rem' }}
                            >
                              <MetricSelector
                                metricsList={metricsOptions}
                                chainsList={chainsList}
                                timeRangeList={timeRanges}
                                size={6}
                              />
                              <LinearChart
                                size={12}
                                title={t(linearTitle)}
                                inputIndex={'start_time'}
                                inputCategories={assignationCategories}
                                lineData={linearData}
                                isLoading={isLoadingMetricData}
                                inputColors={['indigo', 'rose', 'teal']}
                                showLegend={true}
                                yAxisWidth={48}
                                textOrientation={'text-left'}
                                valueFormatter={valueFormatter}
                              />
                              <BarListChart
                                size={6}
                                title={barListTitle}
                                chartData={barListData}
                                valueFormatter={valueFormatter}
                              />
                              <PieChart
                                size={6}
                                chartData={pieData}
                                title={pieTitle}
                                category={mainCategory}
                                inputCategories={pieCategories}
                                inputIndex={'chain'}
                                inputColors={[
                                  'blue',
                                  'cyan',
                                  'indigo',
                                  'violet',
                                  'fuchsia',
                                ]}
                                valueFormatter={valueFormatter}
                                textOrientation={'text-left'}
                                variant={
                                  selectedMetric?.includes('count')
                                    ? 'donut'
                                    : 'pie'
                                }
                              />
                            </Grid>
                          </Grid>
                        </TabPanel>

                        <TabPanel value={tabIndex} index={1} sx={{ p: 2 }}>
                          <Grid container>
                            <Grid
                              container
                              item
                              spacing={2}
                              xs={12}
                              sx={{ marginTop: '0.2rem' }}
                            >
                              <MonitorMetricSelector
                                timeRangeList={timeRanges}
                                monitorsData={monitorMetricsData}
                                selectedTimeRange={selectedTimeRangeMonitor}
                                selectedMetric={selectedMetricMonitor}
                                setSelectedTimeRange={
                                  setSelectedTimeRangeMonitor
                                }
                                setSelectedMetric={setSelectedMetricMonitor}
                                setSelectedMonitor={setSelectedMonitor}
                                size={12}
                                getMonitorMetrics={getMonitorMetrics}
                                onlyPersonalMetrics={onlyPersonalMetrics}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <UserTable data={monitorMetricsData}></UserTable>
                            </Grid>
                            <Grid item xs={6}>
                              <MonitorCharts
                                monitorsData={monitorMetricsData}
                                timeRangeList={timeRanges}
                                isLoading={isLoadingMonitorMetricsData}
                                selectedMonitor={selectedMonitor}
                                selectedMetric={selectedMetricMonitor}
                              />
                            </Grid>
                          </Grid>
                        </TabPanel>
                      </>
                    ) : (
                      <NotAllowedView />
                    )}
                  </>
                )}
              </Card>
            </>
          ) : checkPrimaryView() ? (
            navigate(checkPrimaryView())
          ) : null}
        </>
      ) : (
        <LinearProgress sx={{ width: '100%', mx: 'auto' }} color="secondary" />
      )}
    </>
  );
}
