export const ZIPPEDI_ICON_COLOR = '#1A242D';

export const DEBOUNCE_WAIT_TIME = 700;

export const secretManagerURL = (project_id, secret_id) => {
  return `https://console.cloud.google.com/security/secret-manager/secret/${secret_id}/versions?project=${project_id}`;
};

export const changesColor = (changeType) => {
  const dict = {
    add: '#5D9C59',
    edit: '#0079FF',
    delete: '#da433b',
  };

  return dict[changeType];
};

export function checkPermision(navigationOptions, viewId) {
  let view = navigationOptions.find(
    (element) => element.description === viewId
  );
  return view;
}

export function translateIcon(icon) {
  const dict = {
    'fa-home': 'home',
    'fa-hand-paper': 'hand-right',
    'fa-lock': 'lock-closed',
    'fa-skull': 'skull',
    'fa-stop-circle': 'stop-circle',
    'fa-fire': 'flame',
    'fa-camera': 'camera',
    'fa-wifi': 'wifi',
    'fa-pause-circle': 'pause-circle',
    'fa-battery-empty': 'battery-dead',
    'fa-ban': 'ban',

    'fa-road': null,
    'fa-door-closed': null,
    'fa-camera-retro': null,
    'fa-camera-cctv': null,

    'fa-wifi-slash': null,
    'fa-charging-station': null,
    'fa-solid fa-camera-retro': null,

    'fa-compass-slash': null,
    'fa-skull-crossbones': null,
    'fa-door-open': null,
    'fa-arrows-alt': null,

    'fa-microchip': null,
    'fa-map-marked-alt': null,
  };

  return dict[icon];
}

export function colorIcon(severity) {
  const dict = {
    error: '#da433b',
    warn: '#F4B536',
    fatal: 'black',
    info: '#5D9C59',
    debug: '#9575DE',
    trace: '#0079FF',
  };

  return dict[severity];
}

export function a11yProps(index, { textTransform = 'uppercase' }) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { fontSize: '1rem', textTransform: textTransform },
  };
}

export const transformRobot2Pixels = (
  xRobot,
  yRobot,
  angleRobot,
  origin,
  resolution,
  height
) => {
  let ang = angleRobot + Math.PI / 2,
    x = (xRobot - origin[0]) / resolution,
    y = height - (yRobot - origin[1]) / resolution;
  return [x, y, ang];
};

export const transformPixels2Robot = (
  pixelX,
  pixelY,
  origin,
  resolution,
  height
) => {
  let x = resolution * pixelX + origin[0];
  let y = resolution * (height - pixelY) + origin[1];
  return [x, y];
};

export const LAYER_OPTIONS = [
  { id: 'robot', name: 'Robot' },
  { id: 'lidar', name: 'Lidar' },
  { id: 'navigation_path', name: 'Navigation Path' },
];

export const CONNECTIVITY_OPTIONS = [
  { id: '4G', name: '4G' },
  { id: 'wifi', name: 'Wifi' },
  { id: 'full', name: '4G + Wifi' },
];

export const MAPPING_ALERT_OPTIONS = [
  { id: 'mapping_alert', name: 'Mapping Alert' },
];

export const SINGLE_OPTIONS = [
  { title: 'Connectivity', items: CONNECTIVITY_OPTIONS },
  { title: 'Mapping', items: MAPPING_ALERT_OPTIONS },
];
