import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RobotViewContext } from '../../context/RobotView';
import LastRefresh from '../tools/LastRefresh';
import { toTitleCase } from '../tools/utils';
import RobotStatusModal from './Components/RobotStatusModal';
import RobotStatusWidget from './RobotStatusWidget';


export default function NavigationWidget(props) {
  const {
    title,
    mqttNavInfo,
    robot,
    store,
    robotMainStatus,
    robotDetailStatus,
    robotStatusHistory,
    getRobotStatusHistory,
    isLoadingRobotStatusHistory,
    activeAlerts,
    robotState,
  } = props;

  const { t } = useTranslation();

  const [connected, setConnected] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [lastStatusTime, setLastStatusTime] = useState(null);

  const {
    connectionStatusMsg,
    rawRobotStatus,
  } = useContext(RobotViewContext);

  // Hooks
  useEffect(() => {
    if (rawRobotStatus) {
      setLastStatusTime(new Date());
    }
  }, [rawRobotStatus]);

  useEffect(() => {
    if (connectionStatusMsg.mqtt_connection) {
      setConnected(connectionStatusMsg.mqtt_connection);
    }
  }, [connectionStatusMsg]);

  // Since the activeAlerts object is not big, this will do, but
  // caution is advised since mediumr objects could affect performance.
  useEffect(() => {
    let connectionAlert = activeAlerts.find(
      (alert) => alert?.alert_id === 'no-connection'
    );
    if (connectionAlert) setConnected(false);
    else setConnected(true);
  }, [JSON.stringify(activeAlerts)]);

  // Handlers
  const handleColorRobotState = (robotState) => {
    let style;
    switch (robotState) {
      case 'active':
        style = {
          backgroundColor: '#0E7F19',
          color: 'white',
          fontSize: '0.7rem',
        };
        break;

      case 'not-operational':
        style = {
          backgroundColor: '#fe2d38',
          color: 'white',
          fontSize: '0.7rem',
        };
        break;
      case 'under-maintenance':
        style = {
          backgroundColor: '#FFA500',
          color: 'white',
          fontSize: '0.7rem',
        };
        break;
      default:
        style = {
          backgroundColor: '#808080',
          color: 'white',
          fontSize: '0.7rem',
        };
        break;
    }
    return style;
  };

  // card with widget info
  return (
    <Card
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <RobotStatusModal
        open={openModal}
        setOpen={setOpenModal}
        data={robotDetailStatus}
        robotCode={`${store}-${robot}`}
        robotStatusHistory={robotStatusHistory}
        getRobotStatusHistory={getRobotStatusHistory}
        isLoadingRobotStatusHistory={isLoadingRobotStatusHistory}
      />
      <Grid container>
        {/* title and See more and refresh */}
        <Grid xs={12} md={1.7} sx={{ pl: 1, borderRight: 1, borderColor: 'divider', alignContent: 'flex-end', textAlign: 'center', 'overflowX': 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end', gap: 0 }}>
              <Typography sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 0 }}>
                <Typography sx={{ fontSize: 15, fontWeight: 700, color: 'black', mr: 1 }}>{title}</Typography>
                {robotMainStatus === 'OK' ? (
                  <CheckCircleIcon
                    sx={{ fontSize: '20px', color: '#0E7F19' }}
                  />
                ) : robotMainStatus === 'Error' ? (
                  <CancelIcon color="error" sx={{ fontSize: '20px' }} />
                ) : robotMainStatus === 'Warning' ? (
                  <ErrorIcon color="warning" sx={{ fontSize: '20px' }} />
                ) : (
                  <CircularProgress size={14} />
                )}
              </Typography>
            </Box>
            <Box sx={{ height: 'min-content' }}>
              <IconButton
                size="small"
                onClick={() => setOpenModal(true)}
                sx={{ pt: 0.5 }}
              >
                <Tooltip title={t('overseer_app.widget.see_more', 'See More')}>
                  <Typography>
                    <IconComponent
                      iconName={'open-outline'}
                      style={{ fontSize: "20px" }}
                    />
                  </Typography>
                </Tooltip>
              </IconButton>
            </Box>
          </Box>
          <LastRefresh lastUpdateTime={lastStatusTime} timeInterval={10000} />
        </Grid>
        {/* status summary */}
        <Grid xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0, justifyContent: 'center' }}>
          <RobotStatusWidget
            mqttNavInfo={mqttNavInfo}
            isHeaderInfo={true}
          />
        </Grid>
        {/* robot state */}
        <Grid xs={12} md={2.3} sx={{ display: 'flex', borderLeft: 1, borderColor: 'divider', alignItems: 'center', flexDirection: 'row', gap: 1, justifyContent: 'flex-end', 'overflowX': 'auto', pr: 1 }}>
          <Typography variant="overline">
            <Chip
              label={
                robotState
                  ? t(`overseer_app.general.${robotState}`, toTitleCase(robotState))
                  : t('No info')
              }
              disabled={false}
              size="small"
              variant="filled"
              style={handleColorRobotState(robotState)}
            />
          </Typography>
          <Typography variant="overline">
            <Chip
              label={connected ? t('Connected') : t('Disconnected')}
              disabled={false}
              size="small"
              variant="filled"
              style={
                connected
                  ? {
                    backgroundColor: '#0E7F19',
                    color: 'white',
                    fontSize: '0.7rem',
                  }
                  : {
                    backgroundColor: '#fe2d38',
                    color: 'white',
                    fontSize: '0.7rem',
                  }
              }
            />
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
