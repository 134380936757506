import {
  Badge,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { isEmptyOrUndefined } from 'core/utils/validators';
import { format } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CameraOnDemandWidget from '../../redux/containers/widgets/CameraOnDemandWidget';
import { a11yProps } from '../Utils';
import ConfirmDialog from '../robotView/ConfirmDialog';
import AlertBox from '../tools/AlertBox';
import RobotBlocking from '../tools/RobotBlocking';
import TabPanel from '../tools/TabPanel';
import { handleElapsedTimePair } from '../utils/commons';
import ExtendTimeModal from './Components/ExtendTimeModal';
import RelocateWidget from './RelocateWidget';

export const ButtonsWrapper = styled(Grid)({
  position: 'relative',
  backdropFilter: 'blur(10px)',
});

export default function ActionWidget(props) {
  const {
    topics,
    client,
    robotCode,
    robotsTakenList,
    getRobotBlocking,
    robotActionsList,
    isLoadingTakeRobot,
    isLoadingLeaveRobot,
    isLoadingRobotBlocking,
    store,
    robot,
    storeMapInfo,
    actionsHistory,
    isLoading = false,
    getActionsHistory,
    isSubscribedStatus,
    isStoreMapInfoLoading,
    isLoadingActionHistory,
    mqttNavInfo,
    frontalImage,
    postUserActions,
    mqttMessage,
    floorImage,
    productsList,
    isLoadingProductsList,
    getProductsByName,
    publishMessageCommand,
    iHaveRobotTaken,
    takeSelectedRobot,
    leaveSelectedRobot,
    controlledBy,
    getRobotsTaken,
    setOpenKick,
    takeRobot,
    featurePermission,
    checkFeaturePermission,
    postActionReason,
  } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [isRelocateOpen, setIsRelocateOpen] = useState(false);
  const [commandObject, setCommandObject] = useState({});
  const [iWantPublish, setIWantPublish] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const [openModalTime, setOpenModalTime] = useState(false);

  const { t } = useTranslation();

  // Hooks
  useEffect(() => {
    if (store && robot !== undefined) {
      getActionsHistory(store, robot);
    }
  }, [store, robot, getActionsHistory]);

  useEffect(() => {
    if (
      robotsTakenList &&
      robotsTakenList[robotCode] &&
      !isLoadingRobotBlocking
    ) {
      if (robotsTakenList[robotCode].user === null) setCanPublish(true);
      if (iWantPublish) {
        postUserActions(commandObject.name, `${store}-${robot}`);
        publishMessageCommand(commandObject);
        setIWantPublish(false);
      }
    } else {
      setCanPublish(false);
    }
  }, [robotsTakenList, iWantPublish, isLoadingRobotBlocking]);

  useEffect(() => {
    if (
      isSubscribedStatus &&
      storeMapInfo?.hash &&
      topics.map_topic &&
      client
    ) {
      // Getting map from mqtt
      if (storeMapInfo) {
        let msg_obj = { hash: storeMapInfo.hash };
        client.publish(topics.map_topic.publishTopic, JSON.stringify(msg_obj), {
          qos: topics.map_topic.publishQos,
        });
      }
    }
  }, [isSubscribedStatus, storeMapInfo, topics, client]);

  useEffect(() => { }, [isLoadingActionHistory]);

  // Handlers
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleExtraInfo = (actionHistory) => {
    const actionId = actionHistory.action_id;
    const extraInfo = actionHistory.extra_info
      ? JSON.parse(actionHistory.extra_info)
      : null;
    if (!extraInfo) {
      return '';
    } else if (extraInfo['distance']) {
      return `${t('overseer_app.widget.moved', 'Moved')} ${extraInfo['distance']
        } ${t('overseer_app.widget.meters', 'meters')}`;
    } else if (extraInfo['angle']) {
      return `${t('overseer_app.widget.moved', 'Moved')} ${extraInfo['angle']
        }°`;
    } else if (extraInfo['pose'] || extraInfo['new_pose']) {
      const { pose, new_pose } = extraInfo;
      const [poseParsed, newPoseParsed] = [
        pose ? pose.map((x) => x ? x.toFixed(2) : '-') : '-',
        new_pose ? new_pose.map((x) => x ? x.toFixed(2) : '-') : '-',
      ];
      return `${t('overseer_app.widget.from', 'From')} (${poseParsed}) ${t(
        'overseer_app.widget.to',
        'to'
      )} (${newPoseParsed})`;
    } else if (['replan-aisles', 'omit-aisles'].includes(actionId)) {
      return `${t('overseer_app.general.aisles', 'Aisles')}: ${extraInfo['aisles'] ? extraInfo['aisles'] : extraInfo
        }`;
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleCommands = (element) => {
    setCommandObject(element);
    getRobotBlocking(robotCode);
    if (element.danger || element.confirm) {
      setOpen(true);
    } else {
      setIWantPublish(true);
    }
  };

  const handleConfirmCommand = (modalForm) => {
    setIWantPublish(true);
    handleCloseDialog();
    if (!isEmptyOrUndefined(modalForm ?? {}, 'dict')) {
      postActionReason({
        store,
        robot: robotCode,
        ...modalForm,
      });
    }
  };

  const relocateRobot = () => {
    setIsRelocateOpen(true);
    client.publish(
      topics.autonomous_mapping.publishTopic,
      JSON.stringify({ cmd: 'list' }),
      { qos: topics.autonomous_mapping.publishQos }
    );
  };

  const checkRobotBlocking = () => {
    return (
      <>
        {!iHaveRobotTaken && (
          <RobotBlocking
            featurePermission={featurePermission}
            checkFeaturePermission={checkFeaturePermission}
            controlledBy={controlledBy}
            isLoadingTakeRobot={isLoadingTakeRobot}
            setOpenKick={setOpenKick}
            takeSelectedRobot={takeSelectedRobot}
            getRobotsTaken={getRobotsTaken}
            minutesLeft={robotsTakenList[robotCode]?.minutes_left}
            minutesKickLeft={robotsTakenList[robotCode]?.minutes_kick_left}
          />
        )}
      </>
    );
  };

  return isLoading ? (
    <Skeleton
      variant="rectangular"
      width={'auto'}
      sx={{ p: 3, ml: 2, minHeight: 430, height: '100%', maxHeight: '46.5em' }}
    />
  ) : (
    <Card sx={{ minHeight: 430, height: '100%' }}>
      <ExtendTimeModal
        open={openModalTime}
        setOpen={setOpenModalTime}
        robotCode={`${store}-${robot}`}
        takeRobot={takeRobot}
        isLoadingTakeRobot={isLoadingTakeRobot}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <>
                {t('overseer_app.widget.actions', 'Actions')}
                <Badge
                  badgeContent={
                    <Tooltip
                      title={`${t(
                        'overseer_app.widget.minutes_timeout',
                        'Minutes until timeout'
                      )}: ${robotsTakenList[robotCode]?.minutes_left}`}
                    >
                      <Typography style={{ fontSize: '0.6rem' }}>
                        {robotsTakenList[robotCode]?.minutes_left}
                      </Typography>
                    </Tooltip>
                  }
                  invisible={
                    !iHaveRobotTaken ||
                    robotsTakenList[robotCode]?.minutes_left === 0
                  }
                  color="primary"
                  style={{ right: -30, top: -24, padding: '0 4px' }}
                ></Badge>
              </>
            }
            {...a11yProps(0, { textTransform: 'initial' })}
          />
          <Tab
            wrapped
            label={t('overseer_app.widget.card_actions_history', 'History')}
            {...a11yProps(1, { textTransform: 'initial' })}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
        <ButtonsWrapper container spacing={1}>
          {checkRobotBlocking()}
          {iHaveRobotTaken && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  disabled={
                    !iHaveRobotTaken ||
                    isLoadingLeaveRobot ||
                    isLoadingRobotBlocking
                  }
                  fullWidth
                  onClick={leaveSelectedRobot}
                  startIcon={
                    <IconComponent
                      iconName="exit"
                      style={{ fontSize: '20px' }}
                    />
                  }
                >
                  <Tooltip
                    title={t('overseer_app.widget.leave_robot_tooltip')}
                    enterDelay={500}
                  >
                    <Typography
                      sx={{
                        fontSize: 'small',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {t('overseer_app.wigdet.leave_robot', 'Leave Robot')}
                    </Typography>
                  </Tooltip>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  disabled={
                    !iHaveRobotTaken ||
                    isLoadingLeaveRobot ||
                    isLoadingRobotBlocking ||
                    isLoadingTakeRobot
                  }
                  fullWidth
                  onClick={() => setOpenModalTime(true)}
                  startIcon={
                    <IconComponent
                      iconName="time"
                      style={{ fontSize: '20px' }}
                    />
                  }
                >
                  <Tooltip
                    title={t('overseer_app.widget.extend_time_tooltip')}
                    enterDelay={500}
                  >
                    <Typography
                      sx={{
                        fontSize: 'small',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {t('overseer_app.wigdet.extend_time', 'Extend time')}
                    </Typography>
                  </Tooltip>
                  {isLoadingTakeRobot && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Tooltip
              title={t(
                'overseer_app.widget.manually_relocate_tooltip',
                'Manually Relocate'
              )}
              enterDelay={500}
            >
              <Button
                variant="contained"
                disabled={
                  !iHaveRobotTaken ||
                  isLoadingLeaveRobot ||
                  isLoadingRobotBlocking
                }
                fullWidth
                onClick={relocateRobot}
                startIcon={
                  <IconComponent
                    iconName="compass"
                    style={{ fontSize: '20px' }}
                  />
                }
              >
                <Typography
                  sx={{
                    fontSize: 'small',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {t(
                    'overseer_app.wigdet.manually_relocate',
                    'Manually Relocate'
                  )}
                </Typography>
              </Button>
            </Tooltip>
          </Grid>
          <RelocateWidget
            client={client}
            topics={topics}
            robotCode={`${store}-${robot}`}
            storeMapInfo={storeMapInfo}
            isStoreMapInfoLoading={isStoreMapInfoLoading}
            mqttNavInfo={mqttNavInfo}
            frontalImage={frontalImage}
            postUserActions={postUserActions}
            publishMessageCommand={publishMessageCommand}
            canPublish={canPublish}
            iHaveRobotTaken={iHaveRobotTaken}
            mqttMessage={mqttMessage}
            floorImage={floorImage}
            productsList={productsList}
            isLoadingProductsList={isLoadingProductsList}
            getProductsByName={getProductsByName}
            isRelocateOpen={isRelocateOpen}
            setIsRelocateOpen={setIsRelocateOpen}
          />
          {robotActionsList.map((element, index) => {
            return (
              <Grid
                item
                xs={12}
                md={element.size}
                key={`action-widget-buttons-${index}`}
              >
                {element.is_modal ? (
                  <CameraOnDemandWidget
                    store={store}
                    robot={robot}
                    client={client}
                    topics={topics}
                    isDisabled={
                      !iHaveRobotTaken ||
                      isLoadingLeaveRobot ||
                      isLoadingRobotBlocking
                    }
                    sx={{
                      backgroundColor: iHaveRobotTaken ? element.danger : null,
                      backdropFilter: iHaveRobotTaken ? 'none' : 'blur(8px)',
                    }}
                    dialogName={element.name}
                    buttonTitle={element.title}
                    iconName={element.icon}
                  />
                ) : (
                  <Tooltip
                    title={t(
                      `overseer_app.widget.${element.name}_tooltip`,
                      element.name
                    )}
                    enterDelay={500}
                  >
                    <Button
                      variant="contained"
                      disabled={
                        !iHaveRobotTaken ||
                        isLoadingLeaveRobot ||
                        isLoadingRobotBlocking
                      }
                      fullWidth
                      onClick={() => handleCommands(element)}
                      style={{
                        backgroundColor: iHaveRobotTaken
                          ? element.danger
                            ? '#DA433B'
                            : null
                          : null,
                        backdropFilter: iHaveRobotTaken ? 'none' : 'blur(8px)',
                      }}
                      startIcon={
                        <IconComponent
                          iconName={element.icon}
                          style={{ fontSize: '20px' }}
                        />
                      }
                    >
                      <Typography
                        sx={{
                          fontSize: 'small',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {element.title}
                      </Typography>
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            );
          })}
        </ButtonsWrapper>
      </TabPanel>
      <TabPanel value={tabIndex} index={1} sx={{ p: 2 }}>
        {isLoadingActionHistory && (
          <LinearProgress
            sx={{ mb: 2, mx: 'auto', borderRadius: '15px' }}
            color="secondary"
          />
        )}
        <Grid container>
          <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: '25em' }}>
            {/* History list of actions */}
            {isLoadingActionHistory || actionsHistory?.length ? (
              <List dense={true}>
                {actionsHistory.map((actionHistory, index) => (
                  <Fragment key={`actionHistory-${index}`}>
                    <ListItem>
                      <ListItemText
                        primary={t(
                          `overseer_app.widget.${actionHistory.action_id}`,
                          actionHistory.action_id
                        )}
                        secondary={
                          <>
                            <Typography>
                              {handleExtraInfo(actionHistory)}
                            </Typography>
                            {/* row inline text */}
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Tooltip title={actionHistory.timestamp ? `${format(new Date(actionHistory.timestamp), 'dd/MM/yyyy HH:mm')} UTC` : '-'}>
                                <Typography>
                                  {handleElapsedTimePair(actionHistory?.timestamp, 'yyyy-MM-ddTHH:mm:ss', t)}
                                </Typography>
                              </Tooltip>
                              <Tooltip
                                title={
                                  actionHistory.username
                                    ? actionHistory.username
                                    : ''
                                }
                              >
                                <Typography
                                  sx={{
                                    fontSize: 'smaller',
                                    alignSelf: 'flex-end',
                                  }}
                                >
                                  {actionHistory.username
                                    ? actionHistory.username.split('@')[0]
                                    : ''}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider sx={{ mr: 1 }} />
                  </Fragment>
                ))}
              </List>
            ) : (
              <AlertBox
                severity="info"
                content={t(
                  'overseer_app.widget.no_action_history',
                  'No Actions History Available'
                )}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <ConfirmDialog
        open={open}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmCommand}
        elementObject={commandObject}
        isCommand={true}
      />
    </Card>
  );
}
