import { createContext, useState } from 'react';

export const RobotViewContext = createContext();

export function RobotViewProvider({ children }) {
  const [virtualBorders, setVirtualBorders] = useState([]);
  const [isLinesLoading, setIsLinesLoading] = useState(false);
  const [onDemandCamerasOptions, setOnDemandCamerasOptions] = useState({});
  const [rawRobotStatus, setRawRobotStatus] = useState(null);
  const [isSubscribedStatus, setIsSubscribedStatus] = useState(false);
  const [connectionStatusMsg, setConnectionStatusMsg] = useState({});

  return (
    <RobotViewContext.Provider
      value={{
        virtualBorders,
        setVirtualBorders,
        setIsLinesLoading,
        isLinesLoading,
        onDemandCamerasOptions,
        setOnDemandCamerasOptions,
        rawRobotStatus,
        setRawRobotStatus,
        isSubscribedStatus,
        setIsSubscribedStatus,
        connectionStatusMsg,
        setConnectionStatusMsg,
      }}
    >
      {children}
    </RobotViewContext.Provider>
  );
}
