import { useEffect, useState } from 'react';

import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import AlertBox from '../tools/AlertBox';
import { SHOWN_OBJECT_IDS } from './Constants';
import { MonitoringTableRow } from './monitoringTable/MonitoringTableRow';
import { NAVIGATION_STATUS, prettyName } from './Utils';

export default function PipelineMonitoringTable(props) {
  const {
    alerts,
    setAlertResume = () => {},
    getAlerts,
    getAllMetadata,
    isLoadingSignedBlob,
    loadingAlerts,
    objectNames,
    robot,
    signBlob,
    signedBlob,
    store,
    getSessionProgressTable,
    sessionProgressTableData,
    isSessionProgressTableLoading,
    filterText = '',
    selectedChains = [],
    showTestSessions = false,
    selectedDate = format(new Date(), 'yyyy-MM-dd'),
    canShowNavigationTitle,
    cardElevation = 3,
    sxCard = {},
  } = props;
  const { t } = useTranslation();
  const [navigationStatusRows, setNavigationStatusRows] = useState([]);
  const [updateInterval, setUpdateInterval] = useState(null);

  // Handlers
  const handleTableRows = () => {
    let filteredRows = [];
    if (sessionProgressTableData) {
      filteredRows = Object.entries(sessionProgressTableData).filter(
        ([session, sessionDict]) =>
          (session?.toLowerCase().includes(filterText.toLowerCase()) ||
            sessionDict?.store
              ?.toLowerCase()
              .includes(filterText.toLowerCase()) ||
            sessionDict?.chain
              ?.toLowerCase()
              .includes(filterText.toLowerCase())) &&
          (selectedChains?.includes(sessionDict['chain']) ||
            selectedChains?.length === 0)
      );

      const sessionsAlert =
        alerts?.reduce(
          (beforeObj, alertInfo) => ({
            ...beforeObj,
            [alertInfo?.incident.metric.labels?.session_id]: true,
          }),
          {}
        ) || {};

      filteredRows = filteredRows.sort((session) =>
        sessionsAlert[session[0]] ? -1 : 1
      );

      if (store) {
        filteredRows =
          filteredRows?.length > 0
            ? [
                [
                  filteredRows[0][1]?.['navigationStatus'],
                  [
                    {
                      session: filteredRows[0][0],
                      sessionDict: filteredRows[0][1],
                    },
                  ],
                ],
              ]
            : [];
      } else {
        filteredRows = filteredRows.reduce(
          (beforeObj, [session, sessionDict]) => {
            if (beforeObj[sessionDict['navigationStatus']]) {
              beforeObj[sessionDict['navigationStatus']].push({
                session,
                sessionDict,
              });
              return beforeObj;
            } else {
              return {
                ...beforeObj,
                [sessionDict['navigationStatus']]: [{ session, sessionDict }],
              };
            }
          },
          {}
        );
        filteredRows = Object.entries(NAVIGATION_STATUS).map(
          ([_, navigationStatusName]) => {
            return filteredRows[navigationStatusName]
              ? [navigationStatusName, filteredRows[navigationStatusName]]
              : [navigationStatusName, []];
          }
        );
      }
    }

    setNavigationStatusRows(filteredRows);
  };
  const handleGetProgressInfo = () => {
    if (!isSessionProgressTableLoading) {
      getAlerts({ store, robot, metric_type: 'batch_latency' });
      getSessionProgressTable({ store, robot, date: selectedDate });
    }
  };
  const handleSessionsLen = (tableRows) => {
    const tableRowsLen = showTestSessions
      ? tableRows.length
      : tableRows.filter((row) => !row?.sessionDict?.test).length;
    return tableRowsLen;
  };

  // Hooks
  useEffect(() => {
    if (alerts && alerts.length) {
      setAlertResume(alerts);
    }
  }, [alerts]);

  useEffect(() => {
    getAllMetadata({ chain_code: null });
  }, [getAllMetadata]);

  useEffect(() => {
    if (!isSessionProgressTableLoading && sessionProgressTableData) {
      handleTableRows();
    }
  }, [
    isSessionProgressTableLoading,
    sessionProgressTableData,
    selectedChains,
    filterText,
  ]);

  useEffect(() => {
    if (selectedDate >= format(new Date(), 'yyyy-MM-dd')) {
      handleGetProgressInfo();
      setNavigationStatusRows([]);
      clearInterval(updateInterval);
      const interval = setInterval(() => {
        handleGetProgressInfo();
      }, 60000);
      setUpdateInterval(interval);
    } else {
      handleGetProgressInfo();
      setNavigationStatusRows([]);
      if (updateInterval) {
        clearInterval(updateInterval);
      }
    }
    return () => clearInterval(updateInterval);
  }, [selectedDate, store, robot]);

  return (isSessionProgressTableLoading || loadingAlerts) &&
    navigationStatusRows.length === 0 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <CircularProgress sx={{ mt: 2 }} />
      <Typography>Loading Sessions...</Typography>
    </Box>
  ) : (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      {navigationStatusRows?.length ? (
        navigationStatusRows.map(([navigationStatus, tableRows], index) => (
          <Card
            key={`${navigationStatus}-${index}-nav-status-box`}
            elevation={cardElevation}
            sx={{
              mt: 2,
              mb: 2,
              borderRadius: 1,
              background: '#FFF',
              ...sxCard,
            }}
          >
            {canShowNavigationTitle && (
              <Typography
                sx={{
                  fontWeight: 'bold',
                  marginLeft: 1,
                  marginY: 1,
                  fontSize: 16,
                }}
              >
                {`${navigationStatus} (${handleSessionsLen(
                  tableRows
                )} sessions)`}
              </Typography>
            )}
            {tableRows?.length > 0 ? (
              <TableContainer sx={{ maxHeight: '90vh' }}>
                <Table stickyHeader sx={{ minWidth: 700 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key={'chain-head'}
                        align="center"
                        className="table-cell"
                      >
                        Chain
                      </TableCell>
                      <TableCell
                        key={'store-head'}
                        align="center"
                        className="table-cell"
                      >
                        Store
                      </TableCell>
                      <TableCell
                        key={'session-head'}
                        align="center"
                        className="table-cell"
                      >
                        Session
                      </TableCell>
                      <TableCell
                        key={'planned-head'}
                        align="center"
                        sx={{ minWidth: 120 }}
                      >
                        Planned Time
                      </TableCell>
                      <TableCell
                        key={'navigation-head'}
                        align="center"
                        sx={{ minWidth: 120 }}
                      >
                        Navigation
                      </TableCell>
                      {SHOWN_OBJECT_IDS.map((objectId, index) => (
                        <TableCell
                          key={`${objectId}-${index}-table-cell`}
                          align="center"
                          sx={{ minWidth: 120 }}
                        >
                          {prettyName(objectNames[objectId])}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows.map((rowInfo, index) => (
                      <MonitoringTableRow
                        key={`${rowInfo.session}-${index}-monitoring-table-row`}
                        chain={rowInfo.sessionDict?.chain}
                        store={rowInfo.sessionDict?.store}
                        storeName={rowInfo.sessionDict?.storeName}
                        session={rowInfo.session}
                        objectData={[
                          rowInfo.sessionDict?.navigationData,
                        ].concat(rowInfo.sessionDict?.objectData)}
                        selectedDate={selectedDate}
                        test={rowInfo.sessionDict?.test}
                        showTestSessions={showTestSessions}
                        alertInfo={alerts?.find((alertInfo) =>
                          alertInfo.incident.metric.labels !== {}
                            ? alertInfo?.incident.metric.labels?.session_id ===
                              rowInfo.session
                            : null
                        )}
                        navigationReportSentTimestamp={
                          rowInfo.sessionDict?.navigation_report_sent_timestamp
                        }
                        plannedFor={rowInfo.sessionDict?.plannedFor}
                        signBlob={signBlob}
                        signedblob={signedBlob}
                        isLoadingSignedBlob={isLoadingSignedBlob}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : store != null ? (
              <AlertBox
                severity="warning"
                content={t(
                  'overseer_app.robot_view.no_sessions',
                  'No sessions'
                )}
              />
            ) : (
              <Box sx={{ height: '2vh' }}></Box>
            )}
          </Card>
        ))
      ) : (
        <AlertBox
          severity="warning"
          content={t('overseer_app.robot_view.no_sessions', 'No sessions')}
        />
      )}
    </Box>
  );
}
