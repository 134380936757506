import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress, Divider, InputAdornment, ListItemText, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import AlertBox from '../tools/AlertBox';

export default function RobotParametersWidget(props) {
  const {
    robot_uuid,
    getRobotParameters,
    robotParameters,
    isLoadingRobotParameters
  } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [filteredRobotParams, setFilteredRobotParams] = useState(robotParameters?.[0]?.parameters);

  useEffect(() => {
    getRobotParameters([robot_uuid]);
  }, [getRobotParameters, robot_uuid])

  useEffect(() => {
    if (!isEmptyOrUndefined(searchValue, 'id')) {
      const newParams = robotParameters[0].parameters.filter(param => param.code.toLowerCase().includes(searchValue.toLowerCase()))
      setFilteredRobotParams(newParams)
    } else {
      setFilteredRobotParams(robotParameters?.[0]?.parameters);
    }
  }, [searchValue, robotParameters])

  return (
    <Stack component={Paper} sx={{ height: '100%' }}>
      {isLoadingRobotParameters ?
        <Stack component={Grid} justifyContent='center' alignItems='center' sx={{ p: 2, height: '100%' }}>
          <CircularProgress />
          <Typography>{t('overseer_app.widget.robot_parameters_loading', 'Loading Robot Parameters...')}</Typography>
        </Stack>
        :
        <>
          <Stack direction='row' justifyContent='space-between' sx={{ p: 1.2 }}>
            <Stack direction='row' alignItems='center'>
              <Typography>{t('overseer_app.parameters.Parameters', 'Parameters')}</Typography>
              {isEmptyOrUndefined(robotParameters, 'array') || isEmptyOrUndefined(robotParameters[0]?.parameters, 'array') ?
                null
                :
                robotParameters[0].robot_ack ?
                  <Tooltip title={t('Gerenal.active', 'Active')}>
                    <Box sx={{ height: '1.5em', width: '1.5em' }}>
                      <IconComponent
                        iconName={'validation-r2'}
                      />
                    </Box>
                  </Tooltip>
                  :
                  <Tooltip title={t('overseer_app.parameters.Waiting_ack', 'Waiting ACK')}>
                    <Box sx={{ height: '1.5em', width: '1.5em' }}>
                      <IconComponent
                        iconName={'robot-scheduling-r2'}
                      />
                    </Box>
                  </Tooltip>
              }
            </Stack>
            {!isEmptyOrUndefined(robotParameters, 'array') && !isEmptyOrUndefined(robotParameters[0]?.parameters, 'array') &&
              <TextField
                id="searchValue-bar"
                variant="standard"
                size="small"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconComponent
                        iconName={'search'}
                        style={{ fontSize: "20px" }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: '40%' }}
              />
            }
          </Stack>
          <Divider orientation="horizontal" />
          {isEmptyOrUndefined(robotParameters, 'array') || isEmptyOrUndefined(robotParameters[0]?.parameters, 'array') ?
            <AlertBox
              severity="info"
              content={t(
                'overseer_app.widget.no_robot_parameters_found',
                'No Robot Parameters found'
              )}
              sx={{ pb: 2 }}
            />
            :
            <Stack sx={{ p: 2, maxHeight: '42em', overflowY: 'auto' }} divider={<Divider orientation="horizontal" flexItem />}>
              {filteredRobotParams?.map(parameter => {
                // TODO: delete when the data_type list is removed
                // Returning when data_type = list
                if (parameter.data_type === 'list') {
                  const listItems = parameter.value.join(', ')
                  return (
                    <Stack key={parameter.code}>
                      <ListItemText
                        disableTypography
                        primary={<Typography variant='body2'>{parameter.code}</Typography>}
                        secondary={<Typography variant='body2' color='GrayText'>{listItems}</Typography>}
                      />
                    </Stack>
                  )
                }
                // Returning when data_type = secret
                if (parameter.data_type === 'secret') {
                  return (
                    <Stack key={parameter.code}>
                      <ListItemText
                        disableTypography
                        primary={<Typography variant='body2'>{parameter.code}</Typography>}
                        secondary={
                          <>
                            <Typography variant='body2' color='GrayText'>project_id: {parameter.value.project_id}</Typography>
                            <Typography variant='body2' color='GrayText'>secret_id: {parameter.value.secret_id}</Typography>
                            <Typography variant='body2' color='GrayText'>version_id: {parameter.value.version_id}</Typography>
                          </>
                        }
                      />
                    </Stack>
                  )
                }
                // Normal data_type
                return (
                  <ListItemText
                    disableTypography
                    key={parameter.code}
                    primary={<Typography variant='body2'>{parameter.code}</Typography>}
                    secondary={<Typography variant='body2' color='GrayText'>{String(parameter.value)}</Typography>}
                  />
                )
              })}
            </Stack>
          }
        </>
      }
    </Stack>
  )
}
