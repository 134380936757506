import { handleAvailable, handleRequest, handleUnavailable } from '../../reducerHandlers';
import {
  PARAMETERS_AVAILABLE,
  PARAMETERS_REQUEST,
  PARAMETERS_UNAVAILABLE,
  SNACK_SET,
} from '../constants.js';
import { createReducer } from '../utils';

const defaultState = {
  parameters: [],
  isLoadingParameters: false,
  robotParameters: [],
  isLoadingRobotParameters: false,
  groupedRobotParameters: [],
  isLoadingGroupedRobotParameters: false,
  robotParamsPostResponse: {},
  isLoadingRobotParamsPost: false,
};

export const reducer = createReducer(defaultState, {
  [PARAMETERS_REQUEST]: handleRequest,
  [PARAMETERS_AVAILABLE]: handleAvailable,
  [PARAMETERS_UNAVAILABLE]: handleUnavailable,
});

// Parameters

export function getParameters() {
  return async (dispatch, _, { services: { baldurSource } }) => {
    const keyState = 'parameters'
    const loadingName = 'isLoadingParameters'
    dispatch({ type: PARAMETERS_REQUEST, payload: { loadingName: loadingName } });
    try {
      let response = await baldurSource.getParameters();
      response = response?.data ?? [];
      dispatch({
        type: PARAMETERS_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PARAMETERS_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the parameters.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

// Robot Parameters

export function getRobotParameters(robot_uuids, only_latest = true) {
  return async (dispatch, _, { services: { baldurSource } }) => {
    const keyState = 'robotParameters'
    const loadingName = 'isLoadingRobotParameters'
    dispatch({ type: PARAMETERS_REQUEST, payload: { loadingName: loadingName } });
    try {
      const str_robot_uuids = JSON.stringify(robot_uuids)
      let response = await baldurSource.getRobotParameters(str_robot_uuids, only_latest);
      response = response?.data instanceof Array ? response.data : [];
      dispatch({
        type: PARAMETERS_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PARAMETERS_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the robot parameters.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postRobotParameters(parameters) {
  return async (dispatch, _, { services: { baldurSource } }) => {
    const keyState = 'robotParamsPostResponse'
    const loadingName = 'isLoadingRobotParamsPost'
    dispatch({ type: PARAMETERS_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await baldurSource.postRobotParameters(parameters);
      dispatch({
        type: PARAMETERS_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PARAMETERS_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error saving the robot parameters.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getGroupedRobotParameters(robot_uuids, only_latest = true) {
  return async (dispatch, _, { services: { baldurSource } }) => {
    const keyState = 'groupedRobotParameters'
    const loadingName = 'isLoadingGroupedRobotParameters'
    dispatch({ type: PARAMETERS_REQUEST, payload: { loadingName: loadingName } });
    try {
      const str_robot_uuids = JSON.stringify(robot_uuids)
      let response = await baldurSource.getGroupedRobotParameters(str_robot_uuids, only_latest);
      response = response?.data instanceof Array ? response.data : [];
      dispatch({
        type: PARAMETERS_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PARAMETERS_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the robot parameters grouped by parameter.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}