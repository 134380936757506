import {
  FLEET_CONTROL_AVAILABLE,
  FLEET_CONTROL_REQUEST,
  FLEET_CONTROL_UNAVAILABLE,
  SNACK_SET,
} from './constants';
import { createReducer } from './utils';

const defaultState = {
  robotListBaldur: [],
  isLoadingRobotListBaldur: false,
  loadingUpdateSessions: false,
};

export const reducer = createReducer(defaultState, {
  [FLEET_CONTROL_REQUEST]: handleCustomRequest,
  [FLEET_CONTROL_AVAILABLE]: handleCustomAvailable,
  [FLEET_CONTROL_UNAVAILABLE]: handleCustomUnavailable,
});

function handleCustomRequest(state, { payload: { loadingName } }) {
  return {
    ...state,
    [loadingName]: true,
  };
}

function handleCustomAvailable(
  state,
  { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }
) {
  const x = {
    ...state,
    [loadingName]: false,
    [keyState]: requestInfo,
    ...aditionalStates,
  };
  return x;
}

function handleCustomUnavailable(
  state,
  { payload: { keyState, loadingName } }
) {
  return {
    ...state,
    [keyState]: [],
    [loadingName]: false,
  };
}

export function getRobotsBaldur({ chains, robotCode, robot_status }) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobotListBaldur';
    const keyState = 'robotListBaldur';
    dispatch({ type: FLEET_CONTROL_REQUEST, payload: { loadingName } });
    try {
      const robotsResponse = await dataSource.getRobotsBaldur({ chains: chains, robotCode, robot_status });
      const robotsList = robotsResponse ? robotsResponse : [];
      dispatch({
        type: FLEET_CONTROL_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: robotsList },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: FLEET_CONTROL_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}

export function postSessionUpdates(
  session_ids,
  oh_mode,
  local_start_time,
  local_start_date,
  aisles,
  week_days
) {
  let aisleList = aisles ? JSON.stringify(aisles) : aisles;
  let weekDays = week_days ? JSON.stringify(week_days) : week_days;
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingUpdateSessions';
    const keyState = 'updateSessionMessageResponse';
    let snack;
    dispatch({ type: FLEET_CONTROL_REQUEST, payload: { loadingName } });
    try {
      const updateResponse = await dataSource.updateSession(
        JSON.stringify(session_ids),
        oh_mode,
        local_start_time,
        local_start_date,
        aisleList,
        weekDays
      );
      const update = updateResponse ? updateResponse : {};
      dispatch({
        type: FLEET_CONTROL_AVAILABLE,
        payload: { requestInfo: update, keyState, loadingName },
      });
      snack = {
        open: true,
        message: 'Session(s) was updated',
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: FLEET_CONTROL_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'There was a problem updating the session',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}
