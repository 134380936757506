import { isLoadingRobotParameters, robotParameters } from 'core/selectors';
import { getRobotParameters } from 'core/slices/baldur/parameters';
import { connect } from 'react-redux';

import RobotParametersWidget from '../../../components/widgets/RobotParametersWidget';

export default connect(
  (state) => ({
    robotParameters: robotParameters(state),
    isLoadingRobotParameters: isLoadingRobotParameters(state),
  }),
  {
    getRobotParameters,
  }
)(RobotParametersWidget);
