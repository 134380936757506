import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Counter from '../metrics/Counter';
import { DataGrid } from '@zippeditoolsjs/table';
import { DataBar, Title } from '@zippeditoolsjs/dashboards';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';
import { convertToUserTimezone, getUserTimezone } from '../tools/utils';

import CustomIcon from '../tools/CustomIcon';
import PageLink from '../tools/PageLink';
import { getTierColor } from '../utils/commons';
import AlertBox from '../tools/AlertBox';

const CARD_CONTENT_HEIGHT = '16em';

export default function AlertsEventTable(props) {
  const { realTimeData, eventsStats } = props;
  const { t } = useTranslation();

  const [selectedRowId, setSelectedRowId] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [eventsInfo, setEventsInfo] = useState([]);
  const [generalStats, setGeneralStats] = useState({});

  useEffect(() => {
    if (eventsStats?.events_info) {
      setEventsInfo(eventsStats.events_info);
      setGeneralStats(eventsStats.stats);
    }
  }, [eventsStats]);

  const displayDashWhenNull = (variable) => {
    if (variable === null || variable === undefined) return '-';
    return variable;
  };

  const goToRobot = (params) => {
    let robot_id = params.row?.robot_id;
    const url = `${window.location.origin}/robot/${robot_id
      .split('-')
      .join('/')}`;
    window.open(url, '_blank');
  };

  const goToTimeline = (params) => {
    let robot_id = params.row?.robot_id;
    const tabIndex = 2;
    const url = `${window.location.origin}/robot/${robot_id
      .split('-')
      .join('/')}?tab=${tabIndex}`;
    window.open(url, '_blank');
  };

  const columns = (t) => {
    const columns = [
      {
        field: 'robot_id',
        headerName: t('overseer_app.metrics.robot_id', 'Robot ID'),
        flex: 1,
        renderCell: (params) => {
          return (
            <PageLink
              isRedirect
              onlyText
              text={params.row.robot_id}
              currentState={{
                tabIndex: 2,
              }}
              view={
                params.row.robot_id
                  ? `/robot/${params.row.store_code}/${
                      params.row.robot_id.split('-')[1]
                    }`
                  : ''
              }
              firstTooltipTitle={t(
                'overseer_app.metrics.Robots_timeline',
                "Go to the robot's timeline"
              )}
              secondTooltipTitle=""
            />
          );
        },
      },
      {
        field: 'store_name',
        headerName: t('overseer_app.metrics.has_event', 'Store Name'),
        flex: 2,
      },
      {
        field: 'has_event',
        headerName: t('overseer_app.metrics.has_event', 'Has Event'),
        flex: 1,
        valueGetter: ({ row }) => (row.robot_event_id ? 'Yes' : 'No'),
      },
      {
        field: 'tier_name',
        headerName: t('overseer_app.metrics.assigned_tier', 'Assigned Team'),
        flex: 1.5,
        renderCell: (params) => {
          const teams = params.row.assigned_teams;
          if (teams) {
            return (
              <Stack direction="row" spacing={1}>
                {teams.map((item) => (
                  <Chip
                    sx={{
                      '& .MuiChip-label': {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      },
                    }}
                    label={t(
                      `overseer_app.general.${item.tier_name}`,
                      item.tier_name
                    )}
                    params
                    Size="small"
                    color={getTierColor(item.tier_id)}
                  />
                ))}
              </Stack>
            );
          }
        },
      },
      {
        field: 'assigned_monitor_username',
        headerName: t('overseer_app.metrics.assigned_user', 'Assigned User'),
        flex: 2,
        valueFormatter: (params) => displayDashWhenNull(params?.value),
      },
      {
        field: 'alerts',
        headerName: t('overseer_app.metrics.alert', 'Alerts'),
        flex: 1,
        valueGetter: ({ row }) => row.alerts.length,
      },
      {
        field: 'warnings',
        headerName: t('overseer_app.metrics.warnings', 'Warnings'),
        flex: 1,
        valueGetter: ({ row }) => row.warnings.length,
      },
      {
        field: 'hw_issues',
        headerName: t('overseer_app.metrics.hw_issues', 'HW Issues'),
        flex: 1,
        valueGetter: ({ row }) => row.hw_issues.length,
      },
      {
        field: 'tickets',
        headerName: t('overseer_app.metrics.tickets', 'Tickets'),
        flex: 1,
        valueGetter: ({ row }) => row.tickets.length,
      },
      {
        field: 'actions',
        headerName: t('overseer_app.metrics.actions', 'Actions'),
        flex: 1,
        valueGetter: ({ row }) => row.navigation?.linear_issues_count,
        renderCell: (params) => (
          <>
            <Tooltip
              title={t('overseer_app.events.go_to_robot', 'Go to robot')}
            >
              <IconButton
                aria-label="go-to-robot"
                onClick={(e) => goToRobot(params)}
              >
                <IconComponent iconName={'arrow-forward-sharp'} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t('overseer_app.events.go_to_timeline', 'Go to timeline')}
            >
              <IconButton
                aria-label="go-to-timeline"
                onClick={(e) => goToTimeline(params)}
              >
                <IconComponent iconName={'time'} />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    ];
    return columns;
  };

  return (
    <Grid container item spacing={2}>
      <Grid container item spacing={2} xs={12}>
        {/* Summary */}
        <Grid item xs={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                {/* Title */}
                <Stack>
                  <Stack alignItems="center">
                    <Typography variant="h6">
                      {t('overseer_app.metrics.Summary', 'Summary')}
                    </Typography>
                  </Stack>
                  <Divider />
                </Stack>
                {/* Content */}
                <Grid container height="100%">
                  <Grid
                    container
                    item
                    direction="column"
                    xs={10}
                    justifyContent="space-between"
                  >
                    <Typography variant="h7">
                      {t(
                        'overseer_app.metrics.robot_with_alerts',
                        'Robots with alerts'
                      )}
                    </Typography>
                    <Typography variant="h7">
                      {t(
                        'overseer_app.metrics.robot_with_warn',
                        'Robot with warnings'
                      )}
                    </Typography>
                    <Typography variant="h7">
                      {t(
                        'overseer_app.metrics.robot_with_hw_issues',
                        'Robot with hardware issues'
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    direction="column"
                    xs={2}
                    justifyContent="space-between"
                    alignItems={'end'}
                  >
                    <Grid item>
                      <Chip
                        label={generalStats?.alerts?.count}
                        color="error"
                        size="small"
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label={generalStats?.warnings?.count}
                        color="warning"
                        size="small"
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label={generalStats?.hw_issues?.count}
                        color="info"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container height="100%">
                  <Grid
                    container
                    item
                    direction="column"
                    xs={10}
                    justifyContent="space-between"
                  >
                    <Typography variant="h7">
                      {t(
                        'overseer_app.metrics.unattended_robots',
                        'Unattended robots'
                      )}
                    </Typography>
                    <Typography variant="h7">
                      {t(
                        'overseer_app.metrics.active_monitors',
                        'Active monitors'
                      )}
                    </Typography>
                    <Typography variant="h7">
                      {t(
                        'overseer_app.metrics.assigned_monitors',
                        'Assigned monitors'
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    direction="column"
                    xs={2}
                    justifyContent="space-between"
                    alignItems={'end'}
                  >
                    <Grid item>{realTimeData?.unattended_robots?.length}</Grid>
                    <Grid item>{realTimeData?.active_monitors?.length}</Grid>
                    <Grid item>{realTimeData?.assigned_monitors?.length}</Grid>
                  </Grid>
                </Grid>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        {/* Alerts (monitoring events and CS assignments) */}
        <Grid item xs={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                {isEmptyOrUndefined(selectedRow, 'object') ? (
                  // General stats
                  <>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems="center">
                        <Typography variant="h6">
                          {t(
                            'overseer_app.metrics.robot_with_alerts',
                            'Robot With Alerts'
                          )}
                        </Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {generalStats?.alerts && (
                      <>
                        <Stack
                          direction="row"
                          height="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h4">
                            {generalStats?.alerts?.count}
                          </Typography>
                        </Stack>
                        <DataBar
                          addLabel
                          type="progressCircle"
                          value={generalStats?.alerts?.percent}
                        />
                      </>
                    )}
                  </>
                ) : (
                  // Individual stats
                  <>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems="center">
                        <Typography variant="h6">
                          {t('overseer_app.metrics.alerts', 'Alerts')}
                        </Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow.alerts, 'object') ? (
                      <AlertBox
                        severity="success"
                        content={t(
                          'overseer_app.widget.no_alerts',
                          'No Alerts'
                        )}
                        sx={{ padding: 0 }}
                      />
                    ) : (
                      <Stack divider={<Divider />} sx={{ overflow: 'auto' }}>
                        {selectedRow.alerts?.map((alert, index) => (
                          <Stack
                            key={`stack-${alert.alert_id}-${index}-alert`}
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            paddingBottom={1}
                          >
                            <CustomIcon icon={alert.icon} />
                            <Stack>
                              <Typography>{alert.title_name}</Typography>
                              <Typography variant="caption">
                                {convertToUserTimezone(
                                  alert.created_at,
                                  getUserTimezone()
                                )}
                              </Typography>
                              {alert?.url && (
                                <Link href={alert?.url} target="_blank">
                                  <Typography variant="caption">
                                    Ticket: {alert?.ticket_id}
                                  </Typography>
                                </Link>
                              )}
                            </Stack>
                          </Stack>
                        ))}
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        {/* Warnings (monitor assignation false and monitor tier) */}
        <Grid item xs={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                {isEmptyOrUndefined(selectedRow, 'object') ? (
                  // General stats
                  <>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems="center">
                        <Typography variant="h6">
                          {t(
                            'overseer_app.metrics.robot_warnings',
                            'Robot With Warnings'
                          )}
                        </Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {generalStats?.warnings && (
                      <>
                        <Stack
                          direction="row"
                          height="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h4">
                            {generalStats?.warnings?.count}
                          </Typography>
                        </Stack>
                        <DataBar
                          addLabel
                          type="progressCircle"
                          value={generalStats?.warnings?.percent}
                        />
                      </>
                    )}
                  </>
                ) : (
                  // Individual stats
                  <>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems="center">
                        <Typography variant="h6">
                          {t('overseer_app.metrics.warnings', 'Warnings')}
                        </Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow.warnings, 'object') ? (
                      <AlertBox
                        severity="success"
                        content={t(
                          'overseer_app.widget.no_warnings',
                          'No Warnings'
                        )}
                        sx={{ padding: 0 }}
                      />
                    ) : (
                      <Stack divider={<Divider />} sx={{ overflow: 'auto' }}>
                        {selectedRow.warnings?.map((warn) => (
                          <Stack
                            key={warn.alert_id}
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            paddingBottom={1}
                          >
                            <CustomIcon icon={warn.icon} />
                            <Stack>
                              <Typography>{warn.title_name}</Typography>
                              <Typography variant="caption">
                                {convertToUserTimezone(
                                  warn.created_at,
                                  getUserTimezone()
                                )}
                              </Typography>
                              <Typography variant="caption">
                                {warn?.metadata?.hardware}
                              </Typography>
                            </Stack>
                          </Stack>
                        ))}
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        {/* HW Issues */}
        <Grid item xs={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                {isEmptyOrUndefined(selectedRow, 'object') ? (
                  // General stats
                  <>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems="center">
                        <Typography variant="h6">
                          {t(
                            'overseer_app.metrics.robot_hw_issues',
                            'Robot With HW Issues'
                          )}
                        </Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {generalStats?.hw_issues && (
                      <>
                        <Stack
                          direction="row"
                          height="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h4">
                            {generalStats?.hw_issues?.count}
                          </Typography>
                        </Stack>
                        <DataBar
                          addLabel
                          type="progressCircle"
                          value={generalStats?.hw_issues?.percent}
                        />
                      </>
                    )}
                  </>
                ) : (
                  // Individual stats
                  <>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems="center">
                        <Typography variant="h6">
                          {t(
                            'overseer_app.metrics.hw_issues',
                            'Hardware Issues'
                          )}
                        </Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow.hw_issues, 'object') ? (
                      <AlertBox
                        severity="success"
                        content={t(
                          'overseer_app.widget.no_hw_issues',
                          'No HW Issues'
                        )}
                        sx={{ padding: 0 }}
                      />
                    ) : (
                      <Stack divider={<Divider />} sx={{ overflow: 'auto' }}>
                        {selectedRow.hw_issues?.map((issue, index) => (
                          <Stack
                            key={`${issue.id}`}
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            paddingBottom={1}
                          >
                            <Stack>
                              <Typography>
                                {issue.title_name}{' '}
                                {issue?.metadata?.hardware &&
                                  `(${issue.metadata.hardware})`}
                              </Typography>
                              <Link href={issue?.url} target="_blank">
                                <Typography variant="caption">
                                  Ticket: {issue?.ticket_id}
                                </Typography>
                              </Link>
                            </Stack>
                          </Stack>
                        ))}
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {eventsInfo && eventsInfo?.length > 0 ? (
        <Grid container item>
          <DataGrid
            rows={eventsInfo}
            columns={columns(t)}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              sorting: {
                sortModel: [{ field: 'has_event', sort: 'desc' }],
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            getRowId={(row) => row.robot_id}
            onRowSelectionModelChange={(newRowId) => {
              if (selectedRowId[0] === newRowId[0]) {
                setSelectedRowId([]);
                setSelectedRow({});
              } else {
                setSelectedRowId(newRowId);
                if (!isEmptyOrUndefined(newRowId, 'array')) {
                  const robot_id = newRowId[0];
                  let newSelectedRow = eventsInfo.filter(
                    (row) => row.robot_id === robot_id
                  )[0];
                  setSelectedRow(newSelectedRow);
                } else {
                  setSelectedRow({});
                }
              }
            }}
            rowSelectionModel={selectedRowId}
          />
        </Grid>
      ) : (
        <Grid container item justifyContent="center">
          <Typography>
            {t('overseer_app.metrics.No_data', 'No data.')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
